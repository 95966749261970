import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./TermsOfService.module.css";
import React from "react";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const TermsOfServicePage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Terms of Service | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap
          &amp; Instant Delivery
        </title>
        <meta property="og:image" content={brandLogo}></meta>
        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body>
          <div className={styles.container}>
            <p className={styles.text}>
              <span className={styles.title}>Introduction</span> <br />
              GGItems operates the website in question, and references to “we”,
              “us”, and “our” throughout the site pertain to GGItems. We offer
              this website, complete with all its information, tools, and
              services, to you, the user, conditioned upon your acceptance of
              all terms, conditions, policies, and notices mentioned here.
              <br /> By accessing our site and/or making a purchase, you engage
              with our “Service” and consent to be bound by the “Terms of
              Service” or “Terms”, which include additional terms, conditions,
              and policies referenced herein and/or available through hyperlink.
              These Terms of Service are applicable to all site users,
              including, but not limited to, browsers, vendors, customers,
              merchants, and content contributors. <br />
              All items listed for sale on https://ggitems.com are the property
              of the respective gaming companies (or studios) and are subject to
              those companies' Terms of Service and usage restrictions. <br />
              When you make a purchase on http://ggitems.com, you are paying for
              the service which encompasses the time spent acquiring the item.
              The images on our site are for illustrative purposes only and
              remain the intellectual property of their respective creators and
              owners. Our company is independent and not affiliated with any
              gaming studios. <br />
              Our pricing reflects a combination of a finder's fee and a
              transfer fee. Please be aware that GGItems assumes no
              responsibility for any loss or change to items once they have been
              transferred to you, for reasons including but not limited to:
              hacks, scams, theft, rollbacks, patches, bugs, accidents,
              character/account deletion, or expiration.
              <br /> Please understand that GGItems cannot replace any items
              once lost for any reason. It is important to recognize that your
              payment is for the finder's and transfer fees only. After the
              transfer, the management and safety of the items are beyond our
              control. <br />
              In the event of item loss, you should direct inquiries about
              recovery possibilities to the gaming company (or studio). GGItems
              is not responsible for any loss or modification to your items
              following their transfer.
              <br /> We urge you to read these Terms of Service attentively
              before using our website. Accessing or using any part of the site
              signifies your agreement to these Terms of Service. If you
              disagree with any part of these terms and conditions, you should
              not access the website or utilize the services. These Terms of
              Service are to be regarded as an offer, accepted expressly under
              these terms. <br />
              New features or tools added to the current store will also fall
              under the Terms of Service. The latest version of the Terms of
              Service can always be reviewed on this page. We reserve the right
              to update, change, or replace parts of these Terms of Service by
              posting updates and changes to our website. It is your duty to
              check this page periodically for changes. Your continued use of or
              access to the website after the posting of any changes indicates
              acceptance of those changes.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>
                § 1 - TERMS OF USE FOR OUR ONLINE STORE{" "}
              </span>
              <br />
              By accepting these Terms of Service, you confirm that you are of
              legal age in your place of residence, or if you are the legal age
              in your state or province of residence, you have granted
              permission for any of your minor dependents to utilize this site.
              Our products must not be used for any unlawful activities nor
              should you violate any laws within your jurisdiction through your
              use of the Service, including copyright laws. It is prohibited to
              distribute malware or any harmful code. Any violation of these
              Terms may result in the immediate cessation of your Services.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 2 - SERVICE CONDITIONS</span>
              <br />
              We hold the right to decline service to anyone at any moment
              without justification. Your content, excluding credit card
              details, may be sent without encryption and might undergo (a)
              broadcasts across various networks; and (b) adjustments to meet
              the technical criteria of connecting networks or devices. Credit
              card information is encrypted during transmission over networks.
              Without our explicit written consent, you may not replicate,
              duplicate, copy, sell, resell, or exploit any part of the Service
              or access to the Service or any contact on the website through
              which the service is delivered. The titles used in this agreement
              are for convenience only and do not limit or affect these Terms.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 3 - INFORMATION ACCURACY </span>
              <br />
              We do not guarantee the accuracy, completeness, or timeliness of
              information presented on this site. The site's content is intended
              for general information purposes only and should not be the sole
              basis for making decisions without consulting more precise,
              complete, or current information sources. Any reliance on the
              site's content is at your own risk. The site may include
              historical data which is provided for reference only. We may
              change the site's contents at any time without obligation to
              update any information, and you agree to monitor changes to our
              site.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>
                § 4 - CHANGES IN SERVICE AND PRICING
              </span>
              <br /> Product prices are subject to change without prior notice.
              We also reserve the right to modify or discontinue the Service (or
              any part thereof) without prior notice at any time. We will not be
              liable to you or any third party for any modifications, price
              changes, suspension, or discontinuation of the Service.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 5 - PRODUCTS OR SERVICES</span>
              <br /> Some products or services may be available exclusively
              online via the website. These may have limited quantities and are
              subject to our Return Policy. We strive to display our products as
              accurately as possible, but we cannot guarantee your monitor's
              display will be accurate. We reserve the right to limit sales of
              our products or Services to any person, geographic region, or
              jurisdiction and may exercise this right on a case-by-case basis.
              We also reserve the right to limit the quantities of any products
              or services we offer. Product descriptions and pricing are subject
              to change at any time without notice, at our discretion. We may
              discontinue any product at any time. Any product or service
              offered on this site is void where prohibited. We do not guarantee
              that the quality of any products, services, or information
              purchased by you will meet your expectations, or that any errors
              in the Service will be corrected.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>
                § 6 - BILLING AND ACCOUNT INFORMATION ACCURACY
              </span>
              <br />
              We reserve the right to refuse any order you place with us and
              may, in our sole discretion, limit or cancel quantities purchased
              per person, per household, or per order. These restrictions may
              include orders by the same account, credit card, and/or orders
              that use the same billing and/or shipping address. Should we
              change or cancel an order, we will attempt to notify you by
              contacting the e‑mail and/or billing address/phone number
              provided. We reserve the right to refuse orders from dealers,
              resellers, or distributors. You agree to provide accurate,
              complete, and current purchase and account information for all
              purchases made at our store. You also agree to promptly update
              your account and other information, including your email and
              credit card numbers and expiration dates, so we can complete your
              transactions and contact you as needed. For more details, please
              see our Returns Policy.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>
                § 7 - ACCESS TO THIRD-PARTY TOOLS
              </span>
              <br />
              We may grant you access to third-party tools, over which we do not
              have oversight, control, or input. You acknowledge and accept that
              we offer access to such tools on an “as is” and “as available”
              basis, without any guarantees, representations, or conditions of
              any sort, and without endorsement. We bear no liability for any
              issues arising from your use of these optional third-party tools.
              Your decision to use these tools offered via our site is at your
              own risk and discretion. You are advised to familiarize yourself
              with and accept the terms under which these tools are provided by
              their respective third-party suppliers. In the future, we may
              introduce new services and/or features on the website (including,
              the launch of new tools and resources). These new offerings will
              also be governed by these Terms of Service.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>
                § 8 - LINKS TO THIRD-PARTY WEBSITES
              </span>
              <br />
              Our Service may include materials from third-parties. Links on our
              site may direct you to third-party websites not affiliated with
              us, for which we do not review or endorse the content or accuracy.
              We disclaim all liability related to the content, accuracy, or
              reliability of third-party materials, websites, or services. We
              are not accountable for any harm or damages associated with the
              purchase or use of goods, services, resources, or any transactions
              made with third-party websites. We encourage you to review the
              third-party's policies and practices thoroughly before engaging in
              any transaction. Direct any complaints or queries about
              third-party products to the third-party.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>
                § 9 - FEEDBACK AND USER SUBMISSIONS{" "}
              </span>
              <br />
              Should you, at our request, send specific submissions (e.g.,
              contest entries) or, without our request, send creative ideas,
              suggestions, proposals, plans, or other materials, either online,
              by email, by postal mail, or otherwise ('comments'), you grant us
              permission to use such comments in any manner without restriction,
              including editing, copying, publishing, and distributing them. We
              are not obligated to (1) keep any comments confidential; (2)
              compensate for any comments; or (3) respond to any comments. We
              reserve the right, but are not obligated, to monitor, edit, or
              remove content we determine to be unlawful, offensive,
              threatening, libelous, defamatory, pornographic, obscene, or
              otherwise objectionable or that violates any intellectual property
              or these Terms of Service. You warrant that your comments will not
              infringe on any rights of third parties and will not contain
              unlawful, abusive, or obscene material, nor any malware that could
              impact the operation of the Service or related websites. You are
              solely responsible for the comments you make and their accuracy.
              We assume no responsibility for any comments posted by you or any
              third party.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 10 - PRIVACY POLICY</span>
              <br /> The submission of personal information through our store is
              governed by our Privacy Policy, which can be reviewed on our
              website.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>
                § 11 - INACCURACIES AND OMISSIONS
              </span>
              <br />
              Occasionally, information on our site or Service may contain
              errors or inaccuracies related to product descriptions, pricing,
              promotions, offers, and availability. We reserve the right to
              correct any errors, inaccuracies, or omissions, and to change or
              update information or cancel orders if any information is
              inaccurate at any time without prior notice. We do not commit to
              updating information in the Service or on any related website,
              including pricing information, except as required by law.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 12 - RESTRICTED USES</span>
              <br /> You are prohibited from using the site or its content: (a)
              for illegal purposes; (b) to solicit others to perform illegal
              acts; (c) to violate any regulations, laws, or ordinances; (d) to
              infringe upon our or others' intellectual property rights; (e) to
              harass or discriminate; (f) to submit false information; (g) to
              introduce malware; (h) to collect personal data without consent;
              (i) to spam; (j) for obscene purposes; or (k) to disrupt security
              features of the Service. Violation of these prohibitions may
              result in termination of your Service use.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>
                § 13 - WARRANTY DISCLAIMER AND LIABILITY LIMITATION
              </span>
              <br /> We do not assure uninterrupted or error-free service; nor
              do we guarantee the accuracy of service results. Your use of the
              service is at your own risk. Except as explicitly stated by us,
              services and products provided through the service are offered "as
              is" and "as available" without any warranty. We, along with our
              directors, officers, employees, and affiliates, will not be liable
              for any damages arising from your use of the service or any
              products. Our liability is limited to the maximum extent permitted
              by law in jurisdictions that do not allow the exclusion of certain
              warranties.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 14 - INDEMNIFICATION</span>
              <br /> You agree to indemnify and hold GGItems and its affiliates
              harmless from any claims, demands, or damages, including
              reasonable attorneys’ fees, arising from your breach of these
              Terms of Service or your violation of law or third-party rights.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 15 - DIVISIBILITY</span>
              <br /> Should any portion of these Terms of Service be deemed
              unlawful, void, or unenforceable for any reason, that portion
              shall still be enforceable to the maximum extent allowed by
              applicable law, and such unenforceability shall not affect the
              validity and enforceability of the remaining provisions.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 16 - TERMINATION</span>
              <br /> All obligations and liabilities incurred by either party
              before the termination date will survive the end of this agreement
              for all intents and purposes. These Terms of Service remain in
              effect until terminated by either you or us. You can terminate
              these Terms at any time by informing us that you no longer wish to
              use our Services, or by ceasing to use our site. If, in our sole
              judgment, you are deemed to have failed, or we suspect you have
              failed, to comply with any term or provision of these Terms of
              Service, we may terminate this agreement at any time without
              notice. You will then be responsible for all amounts due up to and
              including the termination date, and we may deny you access to our
              Services (or any part thereof).
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 17 - WHOLE AGREEMENT</span>
              <br />
              Our failure to assert any right or provision under these Terms of
              Service shall not constitute a waiver of such right or provision.
              The entirety of the agreement and understanding between you and
              us, governing your use of the Service, is contained within these
              Terms of Service and any policies or operational rules posted by
              us on this site or in respect to the Service. This supersedes all
              prior or contemporary agreements, communications, and proposals,
              whether oral or written, between you and us (including, but not
              limited to, any previous versions of the Terms of Service). Any
              ambiguities in the interpretation of these Terms of Service will
              not be held against the party who drafted them.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 18 - APPLICABLE LAW</span>
              <br />
              These Terms of Service and any separate agreements through which
              we provide you Services shall be governed and interpreted in
              accordance with the laws of Denmark and the EU.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>
                § 19 - MODIFICATIONS TO TERMS OF SERVICE
              </span>
              <br />
              The most current version of the Terms of Service can always be
              reviewed on this page. We reserve the right, at our sole
              discretion, to update, modify, or replace any part of these Terms
              of Service by posting updates and changes to our website. It is
              your responsibility to periodically check our website for changes.
              Your continued use of or access to our website or the Service
              following any changes to these Terms of Service constitutes
              acceptance of those changes.
            </p>
            <p className={styles.text}>
              <span className={styles.title}>§ 20 - HOW TO CONTACT US</span>
              <br />
              For questions regarding the Terms of Service, please contact us at
              support@ggitems.com.
            </p>
          </div>
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default TermsOfServicePage;
