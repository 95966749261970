import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./DetailsPage.module.css";
import { useEffect } from "react";
import settings from "../settings.json";
const serverAddress = settings.serverAddress;
const RecreateCheckoutSessionPage = () => {
  useEffect(() => {
    (async function () {
      const searchParams = new URLSearchParams(window.location.search);
      const orderId = searchParams.get("orderId");
      const response = await fetch(
        `${serverAddress}/api/re-create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            orderId: orderId,
          }),
        }
      );
      if (!response.ok) {
        // error
        const errorMessage = await response.json();

        return;
      }
      const data = await response.json();

      if (!data) return;
      const { url } = data;
      window.open(url, "_blank");
    })();
  });
  return (
    <section className={styles.background}>
      <Header></Header>
      <Body selectBar={false} searchBar={false} cart={false}>
        <h1>We are creating a checkout session for you.</h1>
      </Body>
      <Footer></Footer>
    </section>
  );
};

export default RecreateCheckoutSessionPage;
