import React from "react";
import styles from "./CartImage.module.css";
const CartImage = (props) => {
  const isRuneword = props.image.length > 1;
  const isDefault = props.image.length === 0;
  return (
    <React.Fragment>
      {isDefault ? (
        <div className={styles.imgContainer}>
          <img
            src={require(`../../graphics/placeholder/placeholder_400x400.png`)}
            alt={props.title}
            className={styles.image}
          ></img>
        </div>
      ) : !isRuneword ? (
        <div className={styles.imgContainer}>
          <img
            src={require(`../../graphics${props.image[0]}`)}
            alt={props.title}
            className={styles.image}
          ></img>
        </div>
      ) : (
        <div className={styles.container}>
          <div
            className={styles.backImg}
            style={{
              backgroundImage: `url(${require(`../../graphics${props.image[1]}`)})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              objectFit: "fill",
              maxHeight: "196px",
            }}
          >
            <img
              src={require(`../../graphics${props.image[0]}`)}
              alt={props.title}
              className={styles.runeImage}
            ></img>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CartImage;
