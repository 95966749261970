import { useNavigate } from "react-router-dom";
import styles from "./WishlistTitle.module.css";
const WishlistTitle = (props) => {
  const navigate = useNavigate();
  const redirect = () => {
    navigate(`./${props.title}`);
  };
  return (
    <div className={styles.container} onClick={redirect}>
      <h2 className={styles.title}>{props.title}</h2>
    </div>
  );
};

export default WishlistTitle;
