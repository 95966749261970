import React from "react";
import styles from "./Copyrights.module.css";
const Copyrights = () => {
  return (
    <div className={styles.container}>
      <label className={styles.copyrights}>
        Copyright {new Date().getFullYear()} All Rights Reserved © GGItems.com
      </label>
      <p className={styles.paragraph}>
        GGItems is not officially endorsed by or affiliated with Activision
        Blizzard and does not represent the opinions or views of any official
        entities associated with the production or management of any other
        trademarks mentioned on this site. All trademarks and logos are the
        property of their respective owners. The copyright of all submitted art
        content belongs to its original copyright holders.
      </p>
    </div>
  );
};

export default Copyrights;
