import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./ContactPage.module.css";
import settings from "../settings.json";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import cursor from "../graphics/Cursor/cursor_hover.png";
import defaultCursor from "../graphics/Cursor/cursor.png";
import brandLogo from "../graphics/ggitems_logo.png";
import Alert from "../Components/Alert/Alert";
import React, { useState, useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
const ContactPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [alert, setAlert] = useState({ visible: false, message: "" });
  const [token, setToken] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [source, setSource] = useState("");

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const subjectHandler = (e) => {
    setSubject(e.target.value);
  };

  const messageHandler = (e) => {
    setMessage(e.target.value);
  };
  const sourceHandler = (e) => {
    setSource(e.target.value);
  };

  const getToken = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const result = await executeRecaptcha("dynamicAction");
    setToken(result);
  }, [executeRecaptcha]);

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha("dynamicAction");
      setToken(token);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const formSubmit = async (e) => {
    e.preventDefault();
    await getToken();

    if (
      name === "" ||
      email === "" ||
      subject === "" ||
      message === "" ||
      source === ""
    ) {
      setAlert({
        visible: true,
        message: "All fiels are required.",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);

      return;
    }
    const fromObject = {
      name: name,
      email: email,
      subject: subject,
      message: message,
      source: source,
    };
    const serverAddress = settings.serverAddress;
    const response = await fetch(`${serverAddress}/api/contact`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        message: fromObject,
        token: token,
      }),
    });
    const data = await response.text();
    setAlert({
      visible: true,
      message: data,
    });
    setTimeout(() => {
      setAlert({
        visible: false,
        message: "",
      });
    }, 3000);
    setName("");
    setSubject("");
    setEmail("");
    setMessage("");
    setSource("");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Contact Us | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={brandLogo}></meta>
        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        {alert.visible ? <Alert message={alert.message} /> : null}
        <Header></Header>
        <Body cart={true}>
          <div className={styles.container}>
            <Row>
              <h1
                style={{
                  color: "#a58546",
                  cursor: `url(${defaultCursor}),auto !important`,
                  paddingBottom: "1em",
                }}
              >
                Contact us!
              </h1>
            </Row>
            <Row>
              <Col md={6} className={styles.textContainer}>
                We’d love to hear from you.
                <br /> We are here to answer any questions you may have about
                our products or services. Feel free to contact us.
                <br /> We are available 20 hours a day, 7 days a week. Even if
                there is something you have always wanted and can’t find it
                here, let us know and we promise we’ll do our best to provide
                you with a custom package tailored to your needs.
              </Col>
              <Col md={6}>
                <form onSubmit={formSubmit} className={styles.form}>
                  <input
                    type="text"
                    placeholder="name"
                    value={name}
                    onChange={nameHandler}
                    className={styles.inp}
                  ></input>
                  <br />
                  <input
                    type="email"
                    placeholder="email"
                    value={email}
                    onChange={emailHandler}
                    className={styles.inp}
                  ></input>
                  <br />
                  <input
                    type="text"
                    placeholder="subject"
                    value={subject}
                    onChange={subjectHandler}
                    className={styles.inp}
                  ></input>
                  <br />
                  <textarea
                    placeholder="message"
                    value={message}
                    onChange={messageHandler}
                    className={styles.textarea}
                  ></textarea>
                  <br />
                  <div className={styles.sourceContainer}>
                    <label>how did you find us?</label>
                    <br />
                    <input
                      type="radio"
                      name="source"
                      value={"in-game"}
                      onChange={sourceHandler}
                      style={{
                        cursor: `url(${cursor}),auto`,
                      }}
                    ></input>{" "}
                    in-game
                    <br />
                    <input
                      type="radio"
                      name="source"
                      value={"facebook"}
                      onChange={sourceHandler}
                      style={{
                        cursor: `url(${cursor}),auto`,
                      }}
                    ></input>{" "}
                    facebook
                    <br />
                    <input
                      type="radio"
                      name="source"
                      value={"google"}
                      onChange={sourceHandler}
                      style={{
                        cursor: `url(${cursor}),auto`,
                      }}
                    ></input>{" "}
                    google
                    <br />
                    <input
                      type="radio"
                      name="source"
                      value={"other"}
                      onChange={sourceHandler}
                      style={{
                        cursor: `url(${cursor}),auto`,
                      }}
                    ></input>{" "}
                    other
                    <br />
                  </div>
                  <button
                    type="submit"
                    className={styles.btn}
                    style={{
                      cursor: `url(${cursor}),auto`,
                    }}
                  >
                    Send
                  </button>
                </form>
              </Col>
            </Row>
          </div>
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default ContactPage;
