import styles from "./CartIcon.module.css";
import { useContext } from "react";
import CartContext from "../context/cart-context";
import icon from "../../graphics/cart.png";
import cursor from "../../graphics/Cursor/cursor_hover.png";
const CartIcon = () => {
  const cartCtx = useContext(CartContext);

  return (
    <div
      className={styles.cartIcon}
      onClick={cartCtx.toggle}
      style={{ cursor: `url(${cursor}), auto !important` }}
    >
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path d="M15.25 14a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"></path>
        <path d="M1.75 3h20.5c.966 0 1.75.784 1.75 1.75v14.5A1.75 1.75 0 0122.25 21H1.75A1.75 1.75 0 010 19.25V4.75C0 3.784.784 3 1.75 3zm-.25 7v9.25c0 .138.112.25.25.25h20.5a.25.25 0 00.25-.25V10zm0-5.25V8.5h21V4.75a.25.25 0 00-.25-.25H1.75a.25.25 0 00-.25.25z"></path>
      </svg> */}
      <img
        src={icon}
        alt="icon"
        className={styles.icon}
        style={{ cursor: `url(${cursor}), auto !important` }}
      ></img>
    </div>
  );
};

export default CartIcon;
