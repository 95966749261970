import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth-context";
import styles from "./UserMenu.module.css";
const UserMenu = () => {
  const authCtx = useContext(AuthContext);
  return (
    <div className={styles.container}>
      <Row>
        <Row className={styles.row}>
          <Col md={4}>
            <Link to="/user/dashboard" className={styles.link}>
              <div className={styles.wrappedLink}>Dashboard</div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/user/orders" className={styles.link}>
              <div className={styles.wrappedLink}>Orders</div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/user/wishlist" className={styles.link}>
              <div className={styles.wrappedLink}>Wishlist</div>
            </Link>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md={4}>
            <Link to="/user/account-details" className={styles.link}>
              <div className={styles.wrappedLink}>Account Details</div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/user/newsletter" className={styles.link}>
              <div className={styles.wrappedLink}>Newsletter</div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/" className={styles.link} onClick={authCtx.removeUser}>
              <div className={styles.wrappedLink}>Logout</div>
            </Link>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default UserMenu;
