import { TextField } from "@mui/material";
import styles from "./ProductQuantity.module.css";
import "./MUITextField.css";
const ProductQuantity = (props) => {
  return (
    <TextField
      id="outlined-input"
      label={"quantity\u200B\u200B\u200B\u200B\u200B"}
      defaultValue={1}
      onChange={props.onChangeHandler}
      type="number"
      min="1"
      step="1"
      max="99"
      className={styles.inp}
      value={props.value}
    />
  );
};

export default ProductQuantity;
