import { useReducer } from "react";
import SeoContext from "./seo-context";
const defaultSeoState = {
  title: "GGItems",
  description:
    "Diablo 2 Items Store, Cheap D2 items, Buy D2 items, D2 Webshop, Diablo Items, Diablo 2 Items, Diablo 2 Shop, Diablo store",
  image: "",
};

const seoReducer = (state, action) => {
  if (action.type === "SET") {
    return {
      title: action.title,
      description: state.description,
      image: state.image,
    };
  }
};

const SeoProvider = (props) => {
  const [seoState, dispatchAuthAction] = useReducer(
    seoReducer,
    defaultSeoState
  );

  const setSeoHandler = (title, description, image) => {
    dispatchAuthAction({
      type: "SET",
      title: title,
      description: description,
      image: image,
    });
  };

  const seoContext = {
    title: seoState.title,
    description: seoState.description,
    image: seoState.image,
    setSeo: setSeoHandler,
  };

  return (
    <SeoContext.Provider value={seoContext}>
      {props.children}
    </SeoContext.Provider>
  );
};

export default SeoProvider;
