import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./DeliveryPage.module.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import React from "react";
import brandLogo from "../graphics/ggitems_logo.png";

const DeliveryPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Delivery | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={brandLogo}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body cart={true}>
          <div className={styles.container}>
            <span className={styles.header}>Delivery</span> <br />
            <span className={styles.title}>
              How will the items/currencies be delivered to me?
            </span>
            <br />
            <span className={styles.text}>
              <span className={styles.red}>GGItems</span> will create a mule
              account to hold the item(s) you ordered. The login and password of
              this account will be e-mailed to the e-mail address that you’ve
              provided at checkout.
            </span>
            <br />
            <span className={styles.text}>
              After you have received the email with the mule account login, you
              can either transfer the items on your own, or our LiveChat
              representatives are available to help transfer the items to your
              character.
            </span>
            <br />
            <span className={styles.title}>
              How fast are the D2 items delivered?
            </span>
            <br />
            <span className={styles.text}>
              We take pride in our delivery speed. Most orders will be delivered
              fast within 5 mins, in rare cases it may take up to 2 hours of the
              time they are placed.
            </span>
            <br />
            <span className={styles.text}>
              Be sure to also check your{" "}
              <span className={styles.red}>spam/trash/promotion</span>
              folders for our delivery email which contains the mule account
              login information.
            </span>
            <br />
            <span className={styles.text}>
              If we need extra information from you, we will ask.
            </span>{" "}
            <br />
            <span className={styles.text}>
              In some cases (Perfect Items) it can take more then 24 hours to
              deliver.
            </span>
            <br />
            <span className={styles.text}>
              If you haven't heard from us within this timeframe, please{" "}
              <Link to="/contact" className={styles.link}>
                contact us
              </Link>{" "}
              as soon as possible because a problem may occurred during
              payment/emailing.
            </span>
            <br />
            <span className={styles.title}>
              How fast are the D2 Resurrected items delivered?
            </span>
            <br />
            <span className={styles.text}>
              We’re currently able fill order in approx. 14-36 hours, depending
              on the items, quantity and the market situation our suppliers
              face.
            </span>
            <br />
            <span className={styles.text}>
              It’s usually faster, but depending on the backlog of orders and
              market situation it might take us longer to source specific items.
            </span>
            <br />
            <span className={styles.text}>
              D2R is new and the game economy is totally different from the
              “old” D2. There is no entity hoarding thousands of items, hence we
              are proud to have the best network of suppliers to ensure we can
              get our hands on almost every item, and most in a reasonable time!
            </span>
            <br />
            <span className={styles.text}>
              Conatct Max directly if you’re waiting longer than 36 hours and
              he’ll personally assist you.
            </span>
          </div>
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default DeliveryPage;
