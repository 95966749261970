import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./MissingPage.module.css";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import desert from "../graphics/404/desert_1.png";
import defaultCursor from "../graphics/Cursor/cursor.png";
import category from "../graphics/Categories/category.img.png";
import categoryHovered from "../graphics/Categories/category.img.hover.png";
import { Link } from "react-router-dom";
import brandLogo from "../graphics/ggitems_logo.png";

const MissingPage = () => {
  const [isHovering, setIsHovering] = useState();
  return (
    <React.Fragment>
      <Helmet>
        <title>
          GGItems | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>

      <section className={styles.background}>
        <Header></Header>
        <Body cart={true}>
          <div className={styles.container}>
            <h1
              style={{
                curosr: `url(${defaultCursor}), auto !important`,
                color: "#a58546",
                fontSize: "8em",
              }}
            >
              404
            </h1>
            <h1
              style={{
                curosr: `url(${defaultCursor}), auto !important`,
                color: "#ba8",
                zIndex: "15",
                position: "relative",
              }}
            >
              Oops, How'd you get here?
            </h1>
            <img src={desert} alt="desert" className={styles.img}></img>
            <Link
              to="/"
              className={styles.link}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              style={{
                backgroundImage: !isHovering
                  ? `url(${category})`
                  : `url(${categoryHovered})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
              }}
            >
              Return home
            </Link>
          </div>
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default MissingPage;
