import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./ThankYouPage.module.css";
import { Col, Container, Row } from "react-bootstrap";
import AuthContext from "../Components/context/auth-context";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import settings from "../settings.json";
import defaultCursor from "../graphics/Cursor/cursor.png";
import brandLogo from "../graphics/ggitems_logo.png";

const ThankYouPage = () => {
  const authCtx = useContext(AuthContext);
  const [order, setOrder] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [totalQuant, setTotalQuant] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const searchParams = new URLSearchParams(window.location.search);
    const orderId = searchParams.get("orderId");
    (async function () {
      const serverAddress = settings.serverAddress;
      const response = await fetch(`${serverAddress}/api/getUserOrder`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          orderId: orderId,
        }),
      });
      const data = await response.text();

      if (data === "") {
        // Order ID does not exist
      } else {
        const orderDetails = JSON.parse(data);

        setOrder(orderDetails);
        calculateTotal(orderDetails);
      }
    })();
  }, []);

  const calculateTotal = (orderDetails) => {
    let totalPrice = 0;
    let totalItems = 0;
    orderDetails.items.map((item) => {
      totalPrice += Number(item.priceInCents) * Number(item.quantity);
      totalItems += Number(item.quantity);
    });
    totalPrice = (totalPrice / 100).toFixed(2);
    setSubTotal(totalPrice);
    setTotalQuant(totalItems);
    setIsLoading(false);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Thank you | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body selectBar={false} searchBar={false} cart={false}>
          {!isLoading && order !== "" ? (
            <Container className={styles.container}>
              <Row>
                <h1
                  className={styles.header}
                  style={{ cursor: `url(${defaultCursor}),auto !important` }}
                >
                  Order Recieved!
                </h1>
              </Row>
              <Row>
                <h2
                  className={styles.header}
                  style={{ cursor: `url(${defaultCursor}),auto !important` }}
                >
                  Customer information
                </h2>
              </Row>
              <div className={styles.orderDetails}>
                <Row className={styles.row}>
                  <Row className={styles.tableRow}>
                    <Col
                      xs={3}
                      style={{ textAlign: "left", overflow: "hidden" }}
                    >
                      order id
                    </Col>
                    <Col xs={9} className={styles.text}>
                      {order.id}
                    </Col>
                  </Row>
                  <Row className={styles.tableRow}>
                    <Col
                      xs={3}
                      style={{ textAlign: "left", overflow: "hidden" }}
                    >
                      date
                    </Col>
                    <Col xs={9} className={styles.text}>
                      {order.date}
                    </Col>
                  </Row>
                  <Row className={styles.tableRow}>
                    <Col
                      xs={3}
                      style={{ textAlign: "left", overflow: "hidden" }}
                    >
                      name
                    </Col>
                    <Col xs={9} className={styles.text}>
                      {order.customer.firstName} {order.customer.lastName}
                    </Col>
                  </Row>
                  <Row className={styles.tableRow}>
                    <Col
                      xs={3}
                      style={{ textAlign: "left", overflow: "hidden" }}
                    >
                      email
                    </Col>
                    <Col xs={9} className={styles.text}>
                      {order.customer.email}
                    </Col>
                  </Row>
                  <Row className={styles.tableRow}>
                    <Col
                      xs={3}
                      style={{ textAlign: "left", overflow: "hidden" }}
                    >
                      battletag
                    </Col>
                    <Col xs={9} className={styles.text}>
                      {order.customer.battlenet}
                    </Col>
                  </Row>
                </Row>
              </div>

              <Row>
                <h2
                  className={styles.header}
                  style={{ cursor: `url(${defaultCursor}),auto !important` }}
                >
                  Order Details
                </h2>
              </Row>
              <div className={styles.orderDetails}>
                <Row className={styles.row}>
                  <Row className={styles.tableRow}>
                    <Col xs={6}>Products</Col>
                    <Col
                      xs={3}
                      style={{ textAlign: "center", overflow: "hidden" }}
                    >
                      Quantity
                    </Col>
                    <Col xs={3}>Total</Col>{" "}
                  </Row>
                </Row>

                <span className={styles.separator}></span>

                <Row className={styles.row}>
                  {order.items.map((item) => {
                    return (
                      <Row className={styles.tableRow}>
                        <Col xs={6}>{item.name}</Col>
                        <Col
                          xs={3}
                          style={{ textAlign: "center", color: "#ba8" }}
                        >
                          {item.quantity}
                        </Col>
                        <Col xs={3} className={styles.text}>
                          {((item.quantity * item.priceInCents) / 100).toFixed(
                            2
                          )}
                          $
                        </Col>
                      </Row>
                    );
                  })}
                </Row>

                <span className={styles.separator}></span>

                <Row className={styles.row}>
                  <Row className={styles.tableRow}>
                    <Col xs={6}>total items</Col>
                    <Col xs={3} style={{ textAlign: "center", color: "#ba8" }}>
                      {totalQuant}
                    </Col>
                    <Col xs={3} className={styles.text}></Col>
                  </Row>
                  <Row className={styles.tableRow}>
                    <Col xs={6}>sub total</Col>
                    <Col
                      xs={3}
                      style={{ textAlign: "center", color: "#ba8" }}
                    ></Col>
                    <Col xs={3} className={styles.text}>
                      {subTotal}$
                    </Col>
                  </Row>
                  <Row className={styles.tableRow}>
                    <Col xs={6}>Tax</Col>
                    <Col xs={3}></Col>
                    <Col xs={3} className={styles.text}>
                      {order.VAT}$
                    </Col>
                  </Row>
                  <Row className={styles.tableRow}>
                    <Col xs={6}>Low order fee</Col>
                    <Col xs={3}></Col>
                    <Col xs={3} className={styles.text}>
                      {order.lowOrderFee > 0
                        ? `+${order.lowOrderFee.toFixed(2)}$`
                        : "-"}
                    </Col>
                  </Row>
                  <Row className={styles.tableRow}>
                    <Col xs={6}>Coupon discount</Col>
                    <Col xs={3}></Col>
                    <Col xs={3} className={styles.text}>
                      {order.couponDiscount
                        ? `-${order.couponDiscount.toFixed(2)}$`
                        : "-"}
                    </Col>
                  </Row>
                  <Row className={styles.tableRow}>
                    <Col xs={6}>Bonus discount</Col>
                    <Col xs={3}></Col>
                    <Col xs={3} className={styles.text}>
                      {order.giftDiscount
                        ? `-${order.giftDiscount.toFixed(2)}`
                        : "0.00"}
                      $
                    </Col>
                  </Row>
                </Row>
                <span className={styles.separator}></span>

                <Row className={styles.row}>
                  <Row className={styles.tableRow}>
                    <Col xs={6}>Total</Col>
                    <Col xs={3}></Col>
                    <Col xs={3} className={styles.text}>
                      {order.totalAmount.toFixed(2)}$
                    </Col>
                  </Row>
                </Row>
              </div>
            </Container>
          ) : null}
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default ThankYouPage;
