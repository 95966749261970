import React, { useContext, useState } from "react";
import styles from "./SingleSelect.module.css";
import { Link } from "react-router-dom";
import arrow from "../../graphics/FAQArrows/faq_open.png";
import category from "../../graphics/Categories/category.img.png";
import categoryHovered from "../../graphics/Categories/category.img.hover.png";
import Option from "./Option";
import ScrollContext from "../context/scroll-context";

const SingleSelect = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const scrollCtx = useContext(ScrollContext);

  const onClickHandler = () => {
    setIsVisible((previousState) => (previousState = !previousState));
    scrollCtx.scroll();
  };

  const selectHandler = () => {
    setIsVisible((previousState) => (previousState = !previousState));
  };

  return (
    <React.Fragment>
      {props.options.length === 0 ? (
        <div
          onClick={onClickHandler}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <Link to={`/products/${props.collection}`} className={styles.link}>
            <div
              className={styles.select}
              style={{
                backgroundImage: !isHovering
                  ? `url(${category})`
                  : `url(${categoryHovered})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
              }}
            >
              {props.title}
            </div>
          </Link>
        </div>
      ) : (
        <div
          onClick={onClickHandler}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <div
            className={styles.select}
            style={{
              backgroundImage: !isHovering
                ? `url(${category})`
                : `url(${categoryHovered})`,
            }}
          >
            {props.title}{" "}
            <img src={arrow} alt="arrow" className={styles.arrow} />
          </div>
          {isVisible ? (
            <React.Fragment>
              {props.options.map((elem, i) => {
                return (
                  <Option
                    key={i}
                    elem={elem}
                    selectHandler={selectHandler}
                    onClickHandler={onClickHandler}
                    isVisible={isVisible}
                  />
                );
              })}
            </React.Fragment>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default SingleSelect;
