import React from "react";
import Question from "./Question";
import styles from "./Questions.module.css";

const Questions = (props) => {
  const questSection = props.question;

  return (
    <div className={styles.container}>
      <label className={styles.title}>{questSection.section}</label>
      <div>
        {questSection.faq.map((q) => {
          return (
            <Question
              key={q.question}
              question={q.question}
              answer={q.answer}
            ></Question>
          );
        })}
      </div>
    </div>
  );
};

export default Questions;
