import React from "react";
import styles from "./GiftCheckbox.module.css";
const GiftCheckbox = ({ isChecked }) => {
  return (
    <React.Fragment>
      <input
        type="checkbox"
        checked={isChecked}
        className={styles.checkbox}
        readOnly
      />
    </React.Fragment>
  );
};

export default GiftCheckbox;
