import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth-context";
import styles from "./Dashboard.module.css";
const Dashboard = () => {
  const authCtx = useContext(AuthContext);
  return (
    <Col className={styles.container}>
      Hello {authCtx.user.email} (not {authCtx.user.email}?{" "}
      <Link to="/" className={styles.link} onClick={authCtx.removeUser}>
        Log out
      </Link>
      ) From your account{" "}
      <Link to="/user/dashboard" className={styles.link}>
        dashboard
      </Link>{" "}
      you can view your{" "}
      <Link to="/user/orders" className={styles.link}>
        recent orders
      </Link>
      , manage your{" "}
      <Link to="/user/address" className={styles.link}>
        billing address
      </Link>
      , and{" "}
      <Link to="/user/account-details" className={styles.link}>
        edit your password and account details
      </Link>
      .
    </Col>
  );
};

export default Dashboard;
