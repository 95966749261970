import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import styles from "./FooterNewsletter.module.css";
import cursor from "../../graphics/Cursor/cursor_hover.png";
import settings from "../../settings.json";
const FooterNewsletter = () => {
  const [email, setEmail] = useState();
  const subscribeNewsletter = async (e) => {
    e.preventDefault();

    const serverAddress = settings.serverAddress;
    const response = await fetch(`${serverAddress}/api/newsletter`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    const data = await response.text();
    setEmail("");
    // pop up will appear here
    alert(data);
  };

  return (
    <div className={styles.centered}>
      <Container className={styles.centeredContainer}>
        <h3 className={styles.title}>News & Discounts</h3>
        <p className={styles.paragraph}>
          Be the first to know about site updates, enjoy member-only discounts,
          access special deals, and receive exclusive promotions, vouchers, and
          giveaways!
        </p>
        <form onSubmit={subscribeNewsletter} style={{ width: "92%" }}>
          <input
            type="email"
            value={email}
            className={styles.inp}
            placeholder="email address"
            onChange={(e) => setEmail(e.target.value)}
            style={{ cursor: `url(${cursor}),auto` }}
          ></input>
          <button
            className={styles.btn}
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              cursor: `url(${cursor}),auto`,
            }}
          >
            Subscribe
          </button>
        </form>
      </Container>
    </div>
  );
};

export default FooterNewsletter;
