import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./TermsPage.module.css";
import React from "react";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const TermsPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Terms | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>

      <section className={styles.background}>
        <Header></Header>
        <Body cart={true}>
          <div className={styles.container}>
            <label className={styles.header}>Privacy</label> <br />
            <span className={styles.title}>INTRODUCTION</span>
            <br />
            <span className={styles.text}>
              GGItems is committed to protect our user’s privacy. This Privacy
              Policy is designed to help you understand what information we
              gather, how we use it, what we do to protect it, and to assist you
              in making decisions when using our services.
            </span>
            <br />
            <span className={styles.text}>
              For purposes of this Privacy Policy: “Services” refers to the
              ‘GGItems’ service located at website: www.GGItems.com. The terms
              ‘us’, ‘its’, ‘ours’ and/or ‘we’, as used herein, shall refer to
              us, “GGItems”. As used herein, the terms ‘you’, ‘your’, and/or
              ‘yourself’ refer to you, a user/visitor/browser of our websites,
              whether registered under an account or not.
            </span>
            <br />
            <span className={styles.text}>
              As used herein and elsewhere, the words “collect”, “use”,
              “process”, “treat”, “disclose” and analogous shall be related to
              personal identifiable information (the “PI”) and other data that
              we may collect from our users and/or visitors, including cookies
              inside a computer and mobile device IDs.
            </span>
            <br />
            <span className={styles.title}>CONSENT</span> <br />
            <span className={styles.text}>
              By accessing our Services & GGItems.com, you accepts our policy
              and terms, and you consent to our collection, storage, use and
              disclosure of your PI information as described in this Privacy
              Policy.
              <br />
            </span>
            <span className={styles.text}>
              Our principles: (i) we do not sell or lease your PI to third
              parties; (ii) we do not disclose your PI with other users or third
              parties without your consent.
            </span>
            <br /> <span className={styles.title}>HOW WE USE COOKIES</span>{" "}
            <br />
            <span className={styles.text}>
              A cookie is a small file which asks permission to be placed on
              your computer’s hard drive. The file is added and the cookie helps
              analyze web traffic or lets you know when you visit a particular
              site. Cookies allow web app to respond to you as an individual.
              The web app can tailor its operations to your need.
            </span>
            <br />
            <span className={styles.text}>
              We use traffic log cookies to identify which pages are being used.
              This helps us analyze data about web page traffic and improve our
              website in order to tailor it to user’s needs.
            </span>
            <br /> <span className={styles.title}>SECURITY</span> <br />
            <span className={styles.text}>
              We (GGItems) are committed to ensuring that your data is secure.
              In order to prevent unauthorized access or disclosure, we have put
              in place electronic and managerial procedures to safeguard and
              secure the data we collect.
            </span>
            <br />
            <span className={styles.title}>YOUR RIGHTS AS DATA SUBJECT</span>
            <br />
            <span className={styles.text}>
              The General Data Protection Regulation (GDPR) allows individuals
              in the EU greater control over their personal data and grants them
              a number of rights with regard to how that data is processed,
              stored, and accessed.
            </span>
            <br />
            <span className={styles.text}>
              You retain the right to access, amend or delete your PI at any
              time. For such request, please contact us via email:
              support@GGItems.com.
            </span>
            <br />
            <br />
            <ul>
              <li>
                <span className={styles.text}>
                  The right to be forgotten: You can request us to be
                  “forgotten”; to have your PI removed from our Service. When
                  requested, GGItems will remove any PI that we’ve collected
                  from you.
                </span>
              </li>
              <li>
                <span className={styles.text}>
                  Access: As a data subject, you can ask GGItems to detail where
                  and how your PI was captured, stored and processed.
                </span>
              </li>
              <li>
                <span className={styles.text}>
                  Rectification: you have the right to obtain PI from GGItems,
                  the rectification of inaccurate PI.
                </span>
              </li>
            </ul>
            <span className={styles.text}>
              Privacy request must include: (i) your full name, and e-mail
              address in order for us to respond to your PI request; (ii)
              attached documents (ID Card / Passport) establishing your
              identity; and (iii) description of the PI with regard to which you
              seek to enforce any of your privacy rights.
            </span>
            <br />
            <span className={styles.text}>
              After receiving your privacy request, GGItems will perform review,
              we may then amend, and/or delete your PI from our Services within
              thirty (30) days.
            </span>
            <br /> <br />
            <span className={styles.text}>
              This Privacy Policy was last updated on: 01/08/2021
            </span>
          </div>
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default TermsPage;
