import { Col, Row } from "react-bootstrap";
import styles from "./SingleOrder.module.css";
import LOGO from "../../graphics/ggitems_logo.png";
import { Link } from "react-router-dom";
const SingleOrder = (props) => {
  const order = props.order;

  return (
    <Link to={`./${order.id}`} state={order} className={styles.link}>
      <div className={styles.container}>
        <Row className={styles.row}>
          {window.innerWidth > 768 ? (
            <Col xs={2}>
              <img src={LOGO} alt="GGItems logo" className={styles.logo} />
            </Col>
          ) : null}

          <Col xs={window.innerWidth > 768 ? 10 : 12}>
            <Row>
              <Col md={4}>
                payment:{" "}
                {order.payment === "paid" ? (
                  <span className={styles.green}>{order.payment} </span>
                ) : (
                  <span className={styles.red}>{order.payment} </span>
                )}
              </Col>
              <Col md={4}>
                status:{" "}
                {order.status === "completed" ? (
                  <span className={styles.green}>{order.status} </span>
                ) : null}
                {order.status === "collecting..." ? (
                  <span className={styles.blue}>{order.status} </span>
                ) : null}
                {order.status === "transaction rejected" ? (
                  <span className={styles.red}>{order.status} </span>
                ) : null}
              </Col>
              <Col md={4}>{order.date}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Link>
  );
};

export default SingleOrder;
