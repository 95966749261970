import React from "react";
import styles from "./Description.module.css";

const Description = () => {
  return (
    <React.Fragment>
      <div className={styles.text}>
        <h5 className={styles.title}>Buying D2items is a sensitive topic. </h5>
        Rest assured, you’ve just found the best partner. We’ve been in this
        game for more than 10 years and we know everything about Diablo 2. It
        doesn’t matter if you joined after the latest Ladder Reset, playing
        Classic or Diablo 2 LOD Expansion Set, or collecting as many items as
        possible in Non-Ladder. Trust us, we got you covered. We even have the
        biggest selection of Hardcore items, readily stocked, waiting to be
        delivered to your mule account.
      </div>

      <div className={styles.text}>
        <h5 className={styles.title}>
          The only D2 Items Store you’ll ever need.
        </h5>
        What you see here is the result of many years of development, offering
        you the most advanced shopping experience for d2 gear online. We’ve
        tried our best to organize the absolute madness of items variations into
        categories that make sense. If an item exists in multiple
        skins/graphics, you can see what we have and we let you choose the skin
        you prefer in the cart, free of charge. To top it all off, we developed
        our unique Mule Helper Tool, which allows you to drop your new d2 gear
        into a game, so you can just pick it off the ground and enjoy the
        instant benefits your character has now!
      </div>

      <div className={styles.text}>
        <h5 className={styles.title}>
          Diablo 2 Items, all variations, ready-to-go.
        </h5>
        The big difference between us and our competitors is that all items you
        see listed here are already stocked in our possession, waiting to be
        delivered to you. It’s simple: if you can add it to your cart, we can
        deliver it within minutes. We might also list items that are currently
        out of stock, which you can show your interest through our unique
        “request” feature, so we can notify you once they are back in stock.
      </div>

      <div className={styles.text}>
        <h5 className={styles.title}>Looking to buy Diablo 2 Runes?</h5>
        Check out the runes section, where you will find high runes like Jah and
        Ber, but also all others, like Ist or Lem. You can buy them either as a
        single rune, or opt for any pack of 5 to 88 (which will get you a better
        price). In case you want to build a runeword, you can find the matching
        pack of runes in our runeword rune packs section.
      </div>

      <div className={styles.text}>
        <h5 className={styles.title}>
          Filter through tons of Rare and Crafted Items.
        </h5>
        If you’re looking for something special, check out our rares and crafts
        section, where you can apply sophisticated filters to find the exact
        item that you need. For example you can add filters for the number of
        sockets, or find rare jewels or amulets with specific stats.
      </div>
      <div className={styles.text}>
        <h5 className={styles.title}>
          Ready made D2 Runewords and DIY Runeword Builder.
        </h5>
        We have tons of runewords stocked, like Enigma, Hoto or CTA, in various
        base items. You can find the random versions and for some runewords even
        the most perfect roll possible. If you want even more selection you can
        try our unique Runeword Builder tool, which allows you to combine many
        different socket base items with the runeword runes. This way you can
        socket the runes into the base item yourself and if you’re lucky, get
        the perfect roll! Have a look at our guide on Diablo 2 Resurrected
        Builds to get some inspiration!
      </div>
    </React.Fragment>
  );
};

export default Description;
