import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./RequestPopUp.module.css";
import AuthContext from "../context/auth-context";
import settings from "../../settings.json";
import CartContext from "../context/cart-context";
import Alert from "../Alert/Alert";
const serverAddress = settings.serverAddress;
const RequestPopUp = (props) => {
  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(CartContext);
  const [alert, setAlert] = useState({ visible: false, message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (authCtx.isLoggedIn) setEmail(authCtx.user.email);
  }, [authCtx]);

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const requestProduct = async () => {
    if (cartCtx.realm === "Select Game") {
      setAlert({
        visible: true,
        message: "Select game before requesting product",
      });

      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
      return;
    }

    const requestedProduct = {
      user: authCtx.user,
      item: props.title,
      stats: props.stats,
      realm: cartCtx.realm,
      SKU: props.sku,
      productId: props.productId,
      collection: props.collection,
    };
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/place-request`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        requestedProduct: requestedProduct,
      }),
    });
    const data = await response.json();
    if (data) {
      props.onClose(true);
    } else {
      props.onClose(false);
    }
    setIsLoading(false);
  };
  return (
    <React.Fragment>
      {alert.visible ? <Alert message={alert.message} /> : null}
      <div className={styles.blur}></div>
      <div className={styles.container}>
        <Container>
          <Row>
            <Col xs={11}></Col>
            <Col xs={1} onClick={props.onClose}>
              x
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 style={{ color: "#a58546" }}>Request Product</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>
                {props.title}: {props.stats}
              </h3>
            </Col>
          </Row>
          <div className={styles.formContainer}>
            <Row>
              <label>email address</label>
              <input
                className={styles.inp}
                value={email}
                onChange={emailHandler}
              ></input>
            </Row>
            <Row>
              <button className={styles.btn} onClick={requestProduct}>
                Request
              </button>
            </Row>
            <Row>
              <Col>
                <span style={{ fontSize: "85%" }}>
                  You will get an email with a price
                </span>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default RequestPopUp;
