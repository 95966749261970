import React from "react";
import styles from "./NewsletterCheckbox.module.css";
const NewsletterCheckbox = ({ isNewsletterSubscribed, newsletterHandler }) => {
  return (
    <React.Fragment>
      <input
        type="checkbox"
        checked={isNewsletterSubscribed}
        onChange={newsletterHandler}
        className={styles.checkbox}
      />{" "}
      Newsletter
    </React.Fragment>
  );
};

export default NewsletterCheckbox;
