import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./CheckoutPage.module.css";
import React, { useContext } from "react";
import AuthContext from "../Components/context/auth-context";
import Payment from "../Components/Payments/Payment";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const CheckoutPage = () => {
  const authCtx = useContext(AuthContext);
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Checkout | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={brandLogo}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body>
          {authCtx.isLoggedIn ? (
            <Payment></Payment>
          ) : (
            <h1>Log in first to place order</h1>
          )}
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default CheckoutPage;
