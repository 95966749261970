import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./DetailsPage.module.css";
import { useEffect } from "react";
import settings from "../settings.json";
import { Col, Container, Row } from "react-bootstrap";
const serverAddress = settings.serverAddress;
const ConfirmRequestPage = () => {
  // const navigate = useNavigate();
  useEffect(() => {
    (async function () {
      const searchParams = new URLSearchParams(window.location.search);
      const requestId = searchParams.get("id");
      await fetch(`${serverAddress}/api/request-price/confirm`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: requestId,
        }),
      });
    })();
  }, []);
  return (
    <section className={styles.background}>
      <Header></Header>
      <Body selectBar={false} searchBar={false} cart={false}>
        <Container>
          <Row>
            <Col>
              <h1>We have recieved your request!</h1>
            </Col>
          </Row>
        </Container>
      </Body>
      <Footer></Footer>
    </section>
  );
};

export default ConfirmRequestPage;
