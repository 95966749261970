import styles from "./TypeButton.module.css";
import cursor from "../../graphics/Cursor/cursor_hover.png";

const TypeButton = (props) => {
  return (
    <button
      className={styles.btn}
      style={{
        cursor: `url(${cursor}),auto`,
      }}
    >
      {props.text}
    </button>
  );
};

export default TypeButton;
