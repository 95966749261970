import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../context/auth-context";
import styles from "./Orders.module.css";
import WishlistTitle from "./WishlistTitle";
const Wishlist = () => {
  const authCtx = useContext(AuthContext);
  const wishlists = authCtx.user.wishlists;

  return (
    <div className={styles.container}>
      <Row className={styles.row}>
        <h1 className={styles.title}>Wishlists</h1>
      </Row>
      {wishlists.length > 0 ? (
        <React.Fragment>
          {wishlists.map((wishlist) => {
            return (
              <Row className={styles.row} key={wishlist.id}>
                <Col>
                  <WishlistTitle title={wishlist.title} />
                </Col>
              </Row>
            );
          })}
        </React.Fragment>
      ) : (
        <Row className={styles.row}>
          <Col>
            <p>You have not made a single wishlist yet. Browse products!</p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Wishlist;
