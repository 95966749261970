import React from "react";
const CartContext = React.createContext({
  isVisible: false,
  realm: "Select Game",
  realms: [],
  items: [],
  totalAmount: 0,
  bonusDiscount: 0,
  totalItems: 0,
  VAT: 0,
  orderFee: 0,
  couponDiscount: 0,
  coupon: {},
  toggle: () => {},
  setRealm: () => {},
  setRealms: (realms) => {},
  addItem: (item) => {},
  removeItem: (id) => {},
  increase: (id) => {},
  decrease: (id) => {},
  loadWishlist: (items) => {},
  replaceWishlist: (items) => {},
  setCoupon: (coupon) => {},
  setBonusDiscount: (discount) => {},
  setTotalItems: (quantity) => {},
  setVAT: (amount) => {},
  setOrderFee: (fee) => {},
});

export default CartContext;
