import React, { useContext } from "react";
import styles from "./ServiceOptions.module.css";
import { Row, Col } from "react-bootstrap";
import AuthContext from "../context/auth-context";
const ServiceOptions = (props) => {
  const pricing = props.pricing.pricing;
  const authCtx = useContext(AuthContext);
  return (
    <div className={styles.container}>
      <Row className={styles.odd}>
        <Col xs={1}>
          <input
            type="checkbox"
            className={styles.checkbox}
            name="single"
            value={pricing[0].itemStats}
            onChange={props.onChangeHandler}
            checked={props.checked.includes(pricing[0].itemStats)}
          ></input>
        </Col>
        <Col xs={7}>
          <span className={styles.description}>{pricing[0].itemStats}</span>
        </Col>
        <Col xs={3}>
          <span className={styles.price}>
            +{Number(pricing[0].itemPriceUSD).toFixed(2)}$
          </span>
        </Col>
      </Row>
      <Row className={styles.even}>
        <Col xs={1}>
          <input
            type="checkbox"
            className={styles.checkbox}
            value={pricing[1].itemStats}
            onChange={props.onChangeHandler}
            checked={props.checked.includes(pricing[1].itemStats)}
          ></input>
        </Col>
        <Col xs={7}>
          <span className={styles.description}> {pricing[1].itemStats}</span>
        </Col>
        <Col xs={3}>
          <span className={styles.price}>
            {" "}
            +{Number(pricing[1].itemPriceUSD).toFixed(2)}$
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={1}>
          <input
            type="checkbox"
            className={styles.checkbox}
            value={pricing[2].itemStats}
            onChange={props.onChangeHandler}
            checked={props.checked.includes(pricing[2].itemStats)}
          ></input>
        </Col>
        <Col xs={7}>
          <span className={styles.description}> {pricing[2].itemStats}</span>
        </Col>
        <Col xs={3}>
          <span className={styles.price}>
            {" "}
            +{Number(pricing[2].itemPriceUSD).toFixed(2)}$
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={1}>
          <input
            type="checkbox"
            className={styles.checkbox}
            value={pricing[3].itemStats}
            onChange={props.onChangeHandler}
            checked={props.checked.includes(pricing[3].itemStats)}
          ></input>
        </Col>
        <Col xs={7}>
          <span className={styles.description}> {pricing[3].itemStats}</span>
        </Col>
        <Col xs={3}>
          <span className={styles.price}>
            {" "}
            +{Number(pricing[3].itemPriceUSD).toFixed(2)}$
          </span>
        </Col>
        {/* <Col xs={1}></Col> */}
      </Row>
    </div>
  );
};

export default ServiceOptions;
