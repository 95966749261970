import { useState } from "react";
import styles from "./BuildButton.module.css";
import category from "../graphics/Categories/category.img.png";
import categoryHovered from "../graphics/Categories/category.img.hover.png";
import cursor from "../graphics/Cursor/cursor_hover.png";

const BuildButton = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const onClickHandler = () => {
    props.onClick(props.text);
  };
  return (
    <button
      className={styles.btn}
      onClick={onClickHandler}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        backgroundImage: !isHovering
          ? `url(${category})`
          : `url(${categoryHovered})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        cursor: `url(${cursor}),auto`,
      }}
    >
      {props.text}
    </button>
  );
};

export default BuildButton;
