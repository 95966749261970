import React from "react";
import styles from "./Selects.module.css";
import SingleSelect from "./SingleSelect";
import selectElements from "./Selects.json";

const Selects = () => {
  return (
    <React.Fragment>
      <div className={styles.selectList}>
        {selectElements.map((elem) => {
          return (
            <SingleSelect
              className={styles.select}
              title={elem.title}
              options={elem.options}
              collection={elem.collection}
              key={elem.key}
            ></SingleSelect>
          );
        })}
      </div>
    </React.Fragment>
  );
};
export default Selects;
