import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./CartPage.module.css";
import React, { useContext, useEffect, useState } from "react";
import CartContext from "../Components/context/cart-context";
import CartProduct from "../Components/Cart/CartProduct";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Components/context/auth-context";
import settings from "../settings.json";
import GiftSection from "../Components/Gifts/GiftSection";
import { Helmet } from "react-helmet";
import defaultCursor from "../graphics/Cursor/cursor.png";
import brandLogo from "../graphics/ggitems_logo.png";

const serverAddress = settings.serverAddress;

const CartPage = () => {
  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [coupon, setCoupon] = useState({
    coupon: {},
    amountOff: 0,
  });
  const [totalItems, setTotalItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const navigate = useNavigate();
  const proceedPaymentHandler = () => {
    navigate("/cart/checkout", { state: { coupon: coupon } });
  };

  useEffect(() => {
    // prevent infinite loop
    if (+cartCtx.totalAmount !== totalPrice)
      if (cartCtx.items.length === 0) {
        navigate("/");
      } else {
        setIsLoading(true);

        const total = (
          +cartCtx.totalAmount -
          +cartCtx.bonusDiscount -
          +coupon.amountOff +
          +cartCtx.orderFee
        ).toFixed(2);
        setTotalPrice(total);
        calculateTotalItems();
        calculateTAX();
      }
  }, [
    cartCtx.totalAmount,
    cartCtx.bonusDiscount,
    cartCtx.orderFee,
    cartCtx.totalItems,
    coupon,
  ]);

  const verifyCoupon = async () => {
    const response = await fetch(`${serverAddress}/api/verifyCoupon`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        user: authCtx.user,
        couponCode: code,
        cart: cartCtx.items,
      }),
    });
    const data = await response.json();

    setMessage(data.message);
    if (data.ok) {
      const coupon = data.coupon;
      cartCtx.setCoupon(coupon);
      calculateCoupon(coupon, data.discount);
    }
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };
  const codeHandler = (e) => {
    setCode(e.target.value);
  };

  const calculateCoupon = (coupon, discount) => {
    setCoupon({ coupon: coupon, amountOff: discount.toFixed(2) });
  };
  const calculateTotalItems = () => {
    const totalQuantity = cartCtx.items.reduce((a, b) => a + b.quantity, 0);
    setTotalItems(totalQuantity);
    cartCtx.setTotalItems(totalQuantity);
  };

  const calculateTAX = async () => {
    const response = await fetch(`${serverAddress}/api/fees`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        country: authCtx.user.country,
      }),
    });
    const { VATRate, lowOrderFee } = await response.json();
    let addedAmount = 0;
    for (const feeRate of lowOrderFee) {
      // USD customer
      if (+cartCtx.totalAmount < feeRate.breakpointUSD) {
        addedAmount = feeRate.amountUSD;
      }
    }

    cartCtx.setOrderFee(addedAmount);
    if (VATRate === -1) {
      cartCtx.setVAT(0);
    }
    const totalVAT = (
      (+(cartCtx.totalAmount + addedAmount) * VATRate) /
      100
    ).toFixed(2);
    cartCtx.setVAT(totalVAT);

    setIsLoading(false);
  };

  const handleBonusDiscount = (discount) => {
    cartCtx.setBonusDiscount(discount);
  };

  const windowWidth = window.innerWidth;
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Cart | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={brandLogo}></meta>
        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body cart={false}>
          {cartCtx.items.length === 0 ? (
            <div>You cart is empty</div>
          ) : (
            <div className={styles.cart}>
              <h1
                className={styles.header}
                style={{ cursor: `url(${defaultCursor}),auto !important` }}
              >
                Your cart listed below:
              </h1>
              {cartCtx.items.map((item) => {
                return <CartProduct item={item} key={item.product.id} />;
              })}
              <Row style={{ padding: "2em" }}>
                <Col>
                  <h1
                    className={styles.header}
                    style={{ cursor: `url(${defaultCursor}),auto !important` }}
                  >
                    Gift Table:
                  </h1>
                  <GiftSection handleDiscount={handleBonusDiscount} />
                </Col>
              </Row>
              {windowWidth > 768 ? (
                <Row>
                  <Col xs={4}>
                    <div className={styles.discountCont}>
                      <label>Do you have discount code?</label> <br />
                      <input
                        type="text"
                        placeholder="discount code"
                        value={code}
                        onChange={codeHandler}
                      ></input>
                      <svg
                        width="40px"
                        height="40px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        onClick={verifyCoupon}
                        className={styles.svg}
                      >
                        <g
                          fill="none"
                          fillRule="evenodd"
                          id="页面-1"
                          stroke="none"
                          strokeLinecap="round"
                          strokeWidth="1"
                        >
                          <g
                            id="导航图标"
                            stroke="#212121"
                            strokeWidth="1.5"
                            transform="translate(-103.000000, -334.000000)"
                          >
                            <g
                              id="申请"
                              transform="translate(103.000000, 334.000000)"
                            >
                              <g
                                id="路径"
                                transform="translate(4.000000, 2.000000)"
                              >
                                <path
                                  d="M16,14.5 L16,19 C16,19.5523 15.5523,20 15,20 L11.75,20"
                                  strokeLinejoin="round"
                                />

                                <path
                                  d="M16,6 L16,1 C16,0.447715 15.5523,0 15,0 L1,0 C0.447715,0 0,0.447715 0,1 L0,19 C0,19.5523 0.447715,20 1,20 L4,20"
                                  strokeLinejoin="round"
                                />

                                <line x1="4" x2="11" y1="6" y2="6" />

                                <line x1="7.5" x2="16" y1="20" y2="9.5" />

                                <line x1="4" x2="8" y1="10" y2="10" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <div>{message}</div>
                    </div>
                  </Col>
                  <Col xs={1}></Col>
                  <Col xs={6}>
                    <div className={styles.summaryCont}>
                      <table className={styles.table}>
                        <tr className={styles.tableRow}>
                          <td>
                            <span className={styles.header}>Total Items:</span>
                          </td>
                          <td className={styles.rightCell}>
                            {isLoading ? <>Calculating...</> : totalItems}
                          </td>
                        </tr>
                        <tr
                          className={`${styles.tableRow} ${styles.tableSeparator}`}
                        >
                          <td>
                            {" "}
                            <span className={styles.header}>Sub total:</span>
                          </td>
                          <td className={styles.rightCell}>
                            {" "}
                            {isLoading ? (
                              <>Calculating...</>
                            ) : (
                              `${(+cartCtx.totalAmount).toFixed(2)}$`
                            )}
                            {}
                          </td>
                        </tr>
                        <tr className={styles.tableRow}>
                          <td>
                            <span className={styles.header}>VAT:</span>
                          </td>
                          <td className={styles.rightCell}>
                            {isLoading ? (
                              <>Calculating...</>
                            ) : (
                              `${+cartCtx.VAT}$`
                            )}
                            {}
                          </td>
                        </tr>
                        <tr className={styles.tableRow}>
                          <td>
                            <span className={styles.header}>
                              Low order fee:
                            </span>
                          </td>
                          <td className={styles.rightCell}>
                            {isLoading ? (
                              <>Calculating...</>
                            ) : +cartCtx.orderFee > 0 ? (
                              `+${cartCtx.orderFee.toFixed(2)}$`
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr className={styles.tableRow}>
                          <td>
                            <span className={styles.header}>discount:</span>
                          </td>
                          <td className={styles.rightCell}>
                            {isLoading ? (
                              <>Calculating...</>
                            ) : +coupon.amountOff > 0 ? (
                              `-${+coupon.amountOff}$`
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr
                          className={`${styles.tableRow} ${styles.tableSeparator}`}
                        >
                          <td>
                            <span className={styles.header}>bonus:</span>
                          </td>
                          <td className={styles.rightCell}>
                            {" "}
                            {isLoading ? (
                              <>Calculating...</>
                            ) : +cartCtx.bonusDiscount > 0 ? (
                              `-${+cartCtx.bonusDiscount}$`
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr className={styles.tableRow}>
                          <td>
                            {" "}
                            <span className={styles.header}>Total:</span>
                          </td>
                          <td className={styles.rightCell}>
                            {isLoading ? (
                              <>Calculating...</>
                            ) : (
                              `${(+totalPrice).toFixed(2)}$`
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Row>
                    <Col>
                      <div className={styles.discountCont}>
                        <label>Do you have discount code?</label> <br />
                        <input
                          type="text"
                          placeholder="discount code"
                          value={code}
                          onChange={codeHandler}
                        ></input>
                        <br />
                        <button
                          className={styles.btn}
                          onClick={proceedPaymentHandler}
                        >
                          apply Coupon{" "}
                        </button>{" "}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={styles.summaryCont}>
                        <table className={styles.table}>
                          <tr className={styles.tableRow}>
                            <td>
                              <span className={styles.header}>
                                Total Items:
                              </span>
                            </td>
                            <td className={styles.rightCell}>
                              {isLoading ? <>Calculating...</> : totalItems}
                            </td>
                          </tr>
                          <tr
                            className={`${styles.tableRow} ${styles.tableSeparator}`}
                          >
                            <td>
                              {" "}
                              <span className={styles.header}>Sub total:</span>
                            </td>
                            <td className={styles.rightCell}>
                              {" "}
                              {isLoading ? (
                                <>Calculating...</>
                              ) : (
                                `${
                                  +cartCtx.totalAmount > 0
                                    ? Number.parseFloat(
                                        cartCtx.totalAmount
                                      ).toFixed(2)
                                    : null
                                }$`
                              )}
                              {}
                            </td>
                          </tr>
                          <tr className={styles.tableRow}>
                            <td>
                              <span className={styles.header}>VAT:</span>
                            </td>
                            <td className={styles.rightCell}>
                              {isLoading ? (
                                <>Calculating...</>
                              ) : (
                                `${+cartCtx.VAT.toFixed(2)}$`
                              )}
                              {}
                            </td>
                          </tr>
                          <tr className={styles.tableRow}>
                            <td>
                              <span className={styles.header}>
                                Low order fee:
                              </span>
                            </td>
                            <td className={styles.rightCell}>
                              {isLoading ? (
                                <>Calculating...</>
                              ) : +cartCtx.orderFee > 0 ? (
                                `+${cartCtx.orderFee.toFixed(2)}$`
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                          <tr className={styles.tableRow}>
                            <td>
                              <span className={styles.header}>discount:</span>
                            </td>
                            <td className={styles.rightCell}>
                              {isLoading ? (
                                <>Calculating...</>
                              ) : +coupon.amountOff > 0 ? (
                                `-${+coupon.amountOff}$`
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                          <tr
                            className={`${styles.tableRow} ${styles.tableSeparator}`}
                          >
                            <td>
                              <span className={styles.header}>bonus:</span>
                            </td>
                            <td className={styles.rightCell}>
                              {" "}
                              {isLoading ? (
                                <>Calculating...</>
                              ) : +cartCtx.bonusDiscount > 0 ? (
                                `-${(+cartCtx.bonusDiscount).toFixed(2)}$`
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                          <tr className={styles.tableRow}>
                            <td>
                              {" "}
                              <span className={styles.header}>Total:</span>
                            </td>
                            <td className={styles.rightCell}>
                              {isLoading ? (
                                <>Calculating...</>
                              ) : (
                                `${(+totalPrice).toFixed(2)}$`
                              )}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </Row>
              )}

              <button className={styles.btn} onClick={proceedPaymentHandler}>
                Proced to payment!
              </button>
            </div>
          )}
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default CartPage;
