import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../context/auth-context";
import styles from "./Address.module.css";
import settings from "../../settings.json";
import Alert from "../Alert/Alert";
const Address = () => {
  const authCtx = useContext(AuthContext);
  const [name, setName] = useState(authCtx.user.firstName);
  const [lastName, setLastName] = useState(authCtx.user.lastName);
  const [alert, setAlert] = useState({ visible: false, message: "" });

  const nameHandler = (e) => {
    setName(e.target.value);
  };
  const lastNameHandler = (e) => {
    setLastName(e.target.value);
  };

  const formHandler = async (e) => {
    e.preventDefault();
    if (name.length > 0 && lastName.length > 0) {
      const serverAddress = settings.serverAddress;
      const response = await fetch(`${serverAddress}/api/user/setBilling`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          user: authCtx.user,
          name: name,
          lastName: lastName,
        }),
      });
      const user = await response.json();
      authCtx.setUser(user);
      setAlert({
        visible: true,
        message: `You have successfully changed your billing address`,
      });

      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    } else {
      setAlert({
        visible: true,
        message: `Make sure you filled all fields!`,
      });

      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };
  return (
    <div className={styles.container}>
      {alert.visible ? <Alert message={alert.message} /> : null}

      <Row className={styles.row}>
        <h1>Billing Address</h1>
      </Row>
      <form onSubmit={formHandler}>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>First name*</label>
            <input
              type="text"
              className={styles.input}
              onChange={nameHandler}
              value={name}
            ></input>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>Last name*</label>
            <input
              type="text"
              onChange={lastNameHandler}
              value={lastName}
              className={styles.input}
            ></input>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>Email address*</label>
            <input
              type="email"
              value={authCtx.user.email}
              disabled
              className={styles.input}
            ></input>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <button>Save address</button>
          </Col>
        </Row>
      </form>
    </div>
  );
};
export default Address;
