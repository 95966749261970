import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../context/auth-context";
import styles from "./Address.module.css";
import settings from "../../settings.json";
import Alert from "../Alert/Alert";

const AccountDetails = () => {
  const authCtx = useContext(AuthContext);
  const [alert, setAlert] = useState({ visible: false, message: "" });

  const [firstName, setFirstName] = useState(authCtx.user.firstName);
  const [lastName, setLastName] = useState(authCtx.user.lastName);
  const [battleNetTag, setBattleNetTag] = useState(authCtx.user.battlenet);
  const [discord, setDiscord] = useState(authCtx.user.discord);

  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
  };
  const lastNameHandler = (e) => {
    setLastName(e.target.value);
  };

  const battleNetTagHandler = (e) => {
    setBattleNetTag(e.target.value);
  };

  const discordHandler = (e) => {
    setDiscord(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  const newPasswordHandler = (e) => {
    setNewPassword(e.target.value);
  };
  const confirmPasswordHandler = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const formHandler = async (e) => {
    e.preventDefault();
    const serverAddress = settings.serverAddress;

    if (password === "") {
      const response = await fetch(
        `${serverAddress}/api/user/setAccountDetails`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            user: authCtx.user,
            isChanging: false,
            firstName: firstName,
            lastName: lastName,
            discord: discord,
            battlenet: battleNetTag,
          }),
        }
      );
      const user = await response.json();
      authCtx.setUser(user);
      setAlert({
        visible: true,
        message: `You have successfully changed your account details without changing password`,
      });

      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    } else if (
      password === authCtx.user.password &&
      newPassword === confirmNewPassword &&
      newPassword.length > 0
    ) {
      const response = await fetch(
        `${serverAddress}/api/user/setAccountDetails`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            user: authCtx.user,
            isChanging: true,
            firstName: firstName,
            lastName: lastName,
            newPassword: newPassword,
            discord: discord,
            battlenet: battleNetTag,
          }),
        }
      );
      const user = await response.json();
      authCtx.setUser(user);
      setAlert({
        visible: true,
        message: `You have successfully changed your account details as well as password`,
      });

      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    } else {
      setAlert({
        visible: true,
        message: `There were some issues while changing your account details. Make sure you have typed everything correctly.`,
      });

      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };
  return (
    <div className={styles.container}>
      {alert.visible ? <Alert message={alert.message} /> : null}

      <Row className={styles.row}>
        <h1>Account Details</h1>
      </Row>
      <form onSubmit={formHandler}>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>First name*</label>
            <input
              type="text"
              onChange={firstNameHandler}
              value={firstName}
              className={styles.input}
            ></input>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>Last name*</label>
            <input
              type="text"
              onChange={lastNameHandler}
              value={lastName}
              className={styles.input}
            ></input>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>battle net tag*</label>
            <input
              type="text"
              value={battleNetTag}
              onChange={battleNetTagHandler}
              className={styles.input}
              placeholder={"ex: #00000"}
            />
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>discord*</label>
            <input
              type="text"
              value={discord}
              onChange={discordHandler}
              className={styles.input}
              placeholder={"GGItems#0001"}
            />
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>Email address*</label>
            <input
              type="email"
              value={authCtx.user.email}
              disabled
              className={styles.input}
            ></input>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>
              Current password (leave blank to leave unchanged)
            </label>
            <input
              type="password"
              onChange={passwordHandler}
              value={password}
              className={styles.input}
            ></input>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>
              New password (leave blank to leave unchanged)
            </label>
            <input
              type="password"
              onChange={newPasswordHandler}
              value={newPassword}
              className={styles.input}
            ></input>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <label className={styles.label}>Confirm New password</label>
            <input
              type="password"
              onChange={confirmPasswordHandler}
              value={confirmNewPassword}
              className={styles.input}
            ></input>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <button>Save Changes</button>
          </Col>
        </Row>
      </form>
    </div>
  );
};
export default AccountDetails;
