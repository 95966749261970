import { Link } from "react-router-dom";
import styles from "./Option.module.css";
import { useState } from "react";
import option from "../../graphics/Categories/subcategory.img.png";
import optionHovered from "../../graphics/Categories/subcategory.img.hover.png";
const Option = (props) => {
  const elem = props.elem;
  const [isOptionHovered, setIsOptionHovered] = useState(false);

  return (
    <Link to={`/products/${elem.collection}`} className={styles.link}>
      <div
        //
        className={styles.option}
        onClick={props.onClickHandler}
        key={elem.category}
        onMouseEnter={() => setIsOptionHovered(true)}
        onMouseLeave={() => setIsOptionHovered(false)}
        style={{
          visibility: props.isVisible ? "visible" : "hidden",
          height: props.isVisible ? "fit-content" : "0",
          padding: props.isVisible ? "5px" : "0",
          backgroundImage: !isOptionHovered
            ? `url(${option})`
            : `url(${optionHovered})`,
        }}
      >
        {elem.title}
      </div>
    </Link>
  );
};

export default Option;
