import styles from "./CookieButton.module.css";
const CookieButton = (props) => {
  return (
    <button
      className={styles.btn}
      onClick={props.onClick}
      dangerouslySetInnerHTML={{ __html: props.text }}
    ></button>
  );
};

export default CookieButton;
