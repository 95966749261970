import React, { useState } from "react";
import styles from "./Question.module.css";
import arrowDown from "../../graphics/FAQArrows/faq_open.png";
import arrowUp from "../../graphics/FAQArrows/faq_close.png";
import arrowDownHover from "../../graphics/FAQArrows/faq_open.hover.png";
import arrowUpHover from "../../graphics/FAQArrows/faq_close.hover.png";
const Question = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const question = props.question;
  const answer = props.answer;
  const clickHandler = () => {
    setIsVisible((previousState) => !previousState);
  };

  return (
    <div className={styles.container}>
      <div className={styles.labelContainer}>
        <label className={styles.question}>{question}</label>
        {isVisible ? (
          <img
            src={!isHovering ? arrowUp : arrowUpHover}
            alt={"arrdown"}
            onClick={clickHandler}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            className={styles.arrow}
          />
        ) : (
          <img
            src={!isHovering ? arrowDown : arrowDownHover}
            alt={"arrup"}
            onClick={clickHandler}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            className={styles.arrow}
          />
        )}
      </div>
      {isVisible ? (
        <div>
          {answer.map((line) => {
            return (
              <div key={line}>
                <span className={styles.answer}>{line}</span>
                <br />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Question;
