import styles from "./ProductButton.module.css";
import cursor from "../../graphics/Cursor/cursor_hover.png";

const ProductButton = (props) => {
  return (
    <button
      className={styles.btn}
      onClick={props.onClick}
      style={{
        cursor: `url(${cursor}),auto`,
      }}
      dangerouslySetInnerHTML={{ __html: props.text }}
    ></button>
  );
};

export default ProductButton;
