import React, { useContext, useEffect, useState } from "react";
import styles from "./GiftSection.module.css";
import settings from "../../settings.json";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../context/auth-context";
import GiftButton from "./GiftButton";
import CartContext from "../context/cart-context";
import GiftCheckbox from "./GiftCheckbox";
const GiftSection = (props) => {
  const [gifts, setGifts] = useState([]);
  const [selectedGift, setSelectedGift] = useState([]);
  const [closestGfit, setClosestGift] = useState({});

  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    (async function () {
      const serverAddress = settings.serverAddress;
      const response = await fetch(`${serverAddress}/api/gifts`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          mode: "Softcore",
        }),
      });
      const data = await response.json();
      setGifts(data);
      setSelectedGift(data[0]);
    })();
  }, []);

  useEffect(() => {
    const missingGifts = gifts.filter(
      (gift) => gift.breakpointUSD > cartCtx.totalAmount
    );
    const currentGifts = gifts.filter(
      (gift) => gift.breakpointUSD < cartCtx.totalAmount
    );
    if (missingGifts.length > 0) {
      const minGift = missingGifts.reduce((prev, curr) =>
        prev.breakpointUSD < curr.breakpointUSD ? prev : curr
      );
      setClosestGift(minGift);
    }
    if (currentGifts.length > 0) {
      const totalPercentage = currentGifts.map((g) => +g.discount);
      const totalDiscount = totalPercentage.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
      const discount = ((+cartCtx.totalAmount * totalDiscount) / 100).toFixed(
        2
      );

      props.handleDiscount(discount);
    } else props.handleDiscount(0);
  }, [cartCtx.totalAmount, gifts]);

  const giftHandler = (recievedGift) => {
    setSelectedGift(recievedGift);
  };

  return (
    <div className={styles.container}>
      <Row>
        {gifts.map((gift) => {
          return (
            <Col style={{ paddingRight: 0, paddingLeft: 0 }} key={gift.id}>
              <GiftButton
                onClick={giftHandler}
                text={`${Number(gift.breakpointUSD).toFixed(2)}$`}
                breakpointUSD={gift.breakpointUSD}
                gift={gift}
              ></GiftButton>
            </Col>
          );
        })}
      </Row>
      <div className={styles.giftContainer}>
        <Row>
          <Col>
            <React.Fragment>
              <span className={styles.discountText}>
                <GiftCheckbox
                  isChecked={
                    +selectedGift.breakpointUSD <= +cartCtx.totalAmount
                  }
                  readonly
                />{" "}
                Selected bonus {selectedGift.discount}% off your Cart!
                <span className={styles.separator}></span>
                <span className={styles.nextText}>
                  You are missing{" "}
                  {(closestGfit.breakpointUSD - cartCtx.totalAmount).toFixed(2)}
                  $ for bonus {closestGfit.discount}% off.{" "}
                </span>
              </span>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GiftSection;
