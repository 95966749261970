import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./AdminPopup.module.css";
import settings from "../../settings.json";
const AdminPopup = (props) => {
  const [isShowable, setIsShowable] = useState(false);
  const [popup, setPopup] = useState({});
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const serverAddress = settings.serverAddress;
    const response = await fetch(`${serverAddress}/api/popup/get`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    setPopup(data[0]);

    const canBeShown = checkLocalStorage(data[0].duration);

    if (canBeShown) {
      setIsShowable(true);
      console.log(new Date().getTime(), data[0].duration);
      setLocalStorage(new Date().getTime() + data[0].duration);
    }
  };

  const handleHide = async () => {
    setIsShowable(false);
    setLocalStorage(new Date().getTime() + popup.duration);
  };

  const checkLocalStorage = (cooldown) => {
    if (window.innerWidth < 768) {
      return false;
    }
    const timestamp = Number(localStorage.getItem("popup") ?? 0);
    if (!timestamp) {
      return true;
    }
    const currentTime = new Date().getTime();

    return timestamp < cooldown + currentTime;
  };

  const setLocalStorage = (timestamp) => {
    localStorage.setItem("popup", String(timestamp));
  };
  return (
    <React.Fragment>
      {isShowable ? (
        <React.Fragment>
          <div className={styles.blur}></div>
          <div className={styles.container}>
            <Container>
              <Row>
                <Col>
                  <h2 className={styles.title}>{popup.title}</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className={styles.paragraph}>{popup.description}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button onClick={handleHide} className={styles.btn}>
                    Close
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
export default AdminPopup;
