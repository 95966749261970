import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Footer.module.css";
import QuickMenu from "./QuickMenu";
import Copyrights from "./Copyrights";
import Socials from "./Socials";
import TechnicalSupport from "./TechnicalSupport";
import FooterNewsletter from "./FooterNewsletter";

const Footer = () => {
  return (
    <Container className={styles.container} fluid>
      <Row className={styles.rowCont}>
        <Col md={3}>
          <Socials />
        </Col>
        <Col md={3}>
          <TechnicalSupport />
        </Col>
        <Col md={3}>
          <QuickMenu />
        </Col>
        <Col md={3}>
          <FooterNewsletter />
        </Col>
      </Row>
      <Row className={styles.rowCont}>
        <Col sm={12}>
          <Copyrights />
        </Col>
      </Row>
    </Container>
  );
};
export default Footer;
