import { useParams } from "react-router-dom";
import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./ProductPage.module.css";
import React, { useContext, useEffect, useState } from "react";
import settings from "../settings.json";
import Products from "../Components/Products/Products";
import CartContext from "../Components/context/cart-context";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const ProductScreen = () => {
  const params = useParams();
  const { collection, category, type } = params;
  const [productsData, setProductsData] = useState([]);
  const [siteTitle, setSiteTitle] = useState(collection);
  const [isLoading, setIsLoading] = useState({
    state: true,
    message: "Loading...",
  });
  const cartCtx = useContext(CartContext);
  useEffect(() => {
    (async function () {
      setIsLoading({ state: true, message: "Loading..." });
      const realm = cartCtx.realm;
      let database;
      if (realm === "D2R Softcore Ladder") {
        database = "ProductsD2RLadder";
      } else if (realm === "D2R Softcore Non-Ladder") {
        database = "ProductsD2RNLadder";
      } else if (realm === "D2R Hardcore Ladder") {
        database = "ProductsD2RHCLadder";
      } else if (realm === "D2R Hardcore Non-Ladder") {
        database = "ProductsD2RHCNLadder";
      } else {
        setIsLoading({
          state: true,
          message: "Select realm before browsing products",
        });
        return;
      }
      const serverAddress = settings.serverAddress;
      const response = await fetch(`${serverAddress}/api/products`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          database: database,
          collection: collection,
          category: category,
          type: type,
        }),
      });
      const data = await response.json();

      formatTitle(collection);
      setProductsData(data);
      setIsLoading({
        state: false,
        message: "Loading...",
      });
    })();
  }, [collection, category, type, cartCtx.realm]);

  const formatTitle = (collection) => {
    let title = capitalizeFirstLetter(collection);
    if (title.split("-").length > 1) {
      title =
        title.split("-")[0] + " " + capitalizeFirstLetter(title.split("-")[1]);
    }
    setSiteTitle(title);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {siteTitle} | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body selectBar={true} searchBar={true} cart={true}>
          {!isLoading.state ? (
            <React.Fragment>
              <Products data={productsData} collection={collection} />
            </React.Fragment>
          ) : (
            <div className={styles.loading}>{isLoading.message}</div>
          )}
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default ProductScreen;
