import React from "react";
const ScrollContext = React.createContext({
  ref: null,
  component: null,
  setRef: () => {},
  setComponent: () => {},
  scroll: () => {},
});

export default ScrollContext;
