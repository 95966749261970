import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./TermsOfService.module.css";
import React from "react";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const RefundPolicyPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Terms of Service | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap
          &amp; Instant Delivery
        </title>
        <meta property="og:image" content={brandLogo}></meta>
        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body>
          <div className={styles.container}>
            <p className={styles.text}>
              Upon the transfer of a virtual item to you, GGItems assumes no
              liability for any subsequent loss or alteration, irrespective of
              the cause, be it hacking, scams, theft, rollbacks, patches, bugs,
              accidents, character/account deletion, or expiration. It is
              imperative to note that we do not entertain requests for
              replacement, repair, substitution, or refund of any lost virtual
              item under any circumstances.
            </p>
            <p className={styles.text}>
              We strongly advise you to take all requisite precautions to
              safeguard your virtual items post-transfer. Engaging in activities
              or services that contravene a game's Terms of Usage may lead to
              the closure of your game account. Please be aware that should your
              account be closed for any reason, we cannot refund any fees or
              costs associated with the virtual item transfer.
            </p>
            <p className={styles.text}>
              By availing our service, you consent to our refund policy and
              terms of service. Rest assured, we are dedicated to upholding your
              privacy and ensuring full compliance with applicable laws and
              regulations.
            </p>
            <p className={styles.text}>
              Should you have any inquiries or apprehensions regarding our
              refund policy, feel free to reach out to us. Our commitment lies
              in furnishing you with the finest service possible and
              guaranteeing your satisfaction with our offerings.
            </p>
          </div>
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default RefundPolicyPage;
