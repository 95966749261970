import { Col, Row } from "react-bootstrap";
import styles from "./CartProduct.module.css";
import BinIcon from "./BinIcon";
import CartImage from "./CartImage";
import { useContext, useState } from "react";
import CartContext from "../context/cart-context";
import AuthContext from "../context/auth-context";
const CartProduct = (props) => {
  const item = props.item.product;
  // const quantity = props.item.quantity;
  const realm = props.item.realm;
  const [quantity, setQuantity] = useState(props.item.quantity);
  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);
  const increaseHandler = () => {
    cartCtx.increase(item.id, realm);
    setQuantity((previousState) => (previousState += 1));
  };
  const windowWidth = window.innerWidth;

  const decreaseHandler = () => {
    cartCtx.decrease(item.id, realm);
    setQuantity((previousState) => (previousState -= 1));
  };

  return (
    <div className={styles.item}>
      <Row>
        {windowWidth > 768 ? (
          <Col xs={2}>
            <CartImage image={item.image} />
          </Col>
        ) : null}
        <Col xs={windowWidth > 768 ? 10 : 12}>
          <Row>
            <Col xs={windowWidth > 768 ? 10 : 8}>{item.title}</Col>
            <Col xs={2}>{Number(item.price).toFixed(2)}$</Col>
          </Row>
          <Row>
            <Col className={styles.lightgold} xs={windowWidth > 768 ? 10 : 8}>
              {item.stats}
            </Col>
            <Col xs={2}>
              <BinIcon
                id={item.id}
                realm={realm}
                stats={item.stats}
                cart={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={windowWidth > 768 ? 10 : 8}>{realm}</Col>
            <Col xs={2}>
              <span className={styles.pointer} onClick={decreaseHandler}>
                -
              </span>
              <span>{quantity}</span>
              <span className={styles.pointer} onClick={increaseHandler}>
                +
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CartProduct;
