import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./SingleProduct.module.css";
import ProductImage from "./ProductImage";
import ProductButton from "./ProductButton";
import AttributeSelect from "./AttributeSelect";
import { Link } from "react-router-dom";
import CartContext from "../context/cart-context";
import Alert from "../Alert/Alert";
import AuthContext from "../context/auth-context";
import ProductQuantity from "./ProductQuantity";
import ScrollContext from "../context/scroll-context";
import RequestPopUp from "../Request/RequestPopUp";
import QuantitySelect from "./QuantitySelect";
const SingleProduct = (props) => {
  const product = props.product;
  const userCtx = useContext(AuthContext);
  const scrollCtx = useContext(ScrollContext);
  const [price, setPrice] = useState(product.variables[0].itemPriceUSD);
  const [alert, setAlert] = useState({ visible: false, message: "" });
  const [inStock, setInStock] = useState(product.variables[0].inStock);
  const [currentStats, setCurrentStats] = useState(
    product.variables[0].itemStats
  );
  const [currentSKU, setCurrentSKU] = useState(product.variables[0].GGSKU);
  const [quantity, setQuantity] = useState(1);
  const [popup, setPopUp] = useState(false);

  const windowWidth = window.innerWidth;

  const cartCtx = useContext(CartContext);
  useEffect(() => {
    if (product.variables[0].itemPriceUSD.length >= 3)
      setPrice(product.variables[0].itemPriceUSD);
    else setPrice("request");
  }, [product, userCtx.isLoggedIn]);

  const quantityHandler = (val) => {
    setQuantity(val);
  };

  const hidePopup = (requested) => {
    setPopUp(false);
    if (requested === true) {
      setAlert({
        visible: true,
        message: "Product Requested!",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };
  const cartHandler = () => {
    let stats = product.variables.filter((p) => +p.itemPriceUSD === +price);
    let itemPrice = stats[0].itemPriceUSD;

    const item = {
      id: product.id,
      collection: props.collection,
      title: product.title,
      image: product.image,
      stats: stats[0].itemStats,
      price: itemPrice,
    };

    if (cartCtx.realm === "Select Game") {
      setAlert({
        visible: true,
        message: "Select game before adding products!",
      });
    } else {
      cartCtx.addItem(item, quantity);
      setAlert({
        visible: true,
        message: "Product added to cart!",
      });
    }
    setTimeout(() => {
      setAlert({
        visible: false,
        message: "",
      });
    }, 3000);
  };

  const detailsHandler = () => {
    scrollCtx.scroll();
  };
  const requestProduct = () => {
    if (userCtx.isLoggedIn) setPopUp(true);
    else {
      setAlert({
        visible: true,
        message: "Log in or create an account before requesting a product.",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };

  return (
    <React.Fragment>
      {alert.visible ? <Alert message={alert.message} /> : null}
      {popup ? (
        <RequestPopUp
          onClose={hidePopup}
          title={product.title}
          stats={currentStats}
          sku={currentSKU}
          productId={product.id}
          collection={props.collection}
        />
      ) : null}
      <Row>
        <Col className={styles.productContainer}>
          <Row>
            {windowWidth > 768 ? (
              <React.Fragment>
                <Col
                  xs={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ProductImage
                    title={product.title}
                    image={product.image}
                    className={styles.picture}
                  />
                </Col>

                <Col xs={windowWidth > 768 ? 9 : 12}>
                  <Row>
                    <Col xs={9} style={{ textAlign: "left" }}>
                      <Row>
                        <Col>
                          <h3>
                            <span
                              style={{
                                color:
                                  props.collection === "set-items"
                                    ? "#00FF00"
                                    : product.category === "unique" ||
                                      product.type === "unique" ||
                                      product.collection
                                    ? "#C7B377"
                                    : product.category === "magic" ||
                                      product.type === "magic"
                                    ? "#6969FF"
                                    : product.category === "socketed" ||
                                      product.type === "socketed"
                                    ? "#696969"
                                    : props.collection === "keys" ||
                                      props.collection === "essences" ||
                                      props.collection === "crafts" ||
                                      props.collection === "runes"
                                    ? "#FFA800"
                                    : props.collection === "rares"
                                    ? "FFFF64"
                                    : "#a58546",
                              }}
                            >
                              {product.title} {product.type}
                            </span>
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <AttributeSelect
                            variables={product.variables}
                            priceHandler={setPrice}
                            stockHandler={setInStock}
                            statsHandler={setCurrentStats}
                            skuHandler={setCurrentSKU}
                            height={"200%"}
                          />
                        </Col>
                      </Row>
                      {!inStock || price === "request" ? (
                        <React.Fragment>
                          <br />
                          <br />
                          <Row>
                            <Col>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="Layer_1"
                                data-name="Layer 1"
                                viewBox="0 0 122.88 73.14"
                                width={30}
                                height={20}
                              >
                                <path
                                  fill="#a58546"
                                  class="cls-1"
                                  d="M41.63,58.19a12.9,12.9,0,1,0,25.64,2,13.7,13.7,0,0,0-.16-2H89.28a14.22,14.22,0,0,0-.12,1.87,13.08,13.08,0,0,0,26.16,0,12.7,12.7,0,0,0-.21-2.33,8,8,0,0,0,7.77-7.93V24.14L106.61,6.64H84.32V45.51h-80A3.27,3.27,0,0,0,1,48.68V55A3.27,3.27,0,0,0,4.31,58.2H7.94a12.48,12.48,0,0,0-.16,2,12.9,12.9,0,1,0,25.79,0,13.7,13.7,0,0,0-.16-2h8.22ZM76.48,0H4.1A4.12,4.12,0,0,0,0,4.1V42.27H80.58V4.1A4.11,4.11,0,0,0,76.48,0ZM15.71,60.26c0,6.53,9.92,6.53,9.92,0,0-7-9.92-6.27-9.92,0Zm81.49-.21c0,6.61,10.06,6.61,10.06,0s-10.06-6.65-10.06,0Zm9.33-45.85L114.74,24v3.56H92.49V14.2ZM49.42,60.26c0,6.53,9.92,6.53,9.92,0s-9.92-6.52-9.92,0Z"
                                />
                              </svg>{" "}
                              <span className={styles.request}>on request</span>
                            </Col>
                          </Row>
                        </React.Fragment>
                      ) : null}
                    </Col>
                    <Col xs={3}>
                      <Row>
                        <Col>
                          <span className={styles.price}>
                            {userCtx.isLoggedIn ? (
                              <React.Fragment>
                                <span>
                                  {price}
                                  {price !== "request" ? "$" : null}
                                </span>
                              </React.Fragment>
                            ) : (
                              <span>
                                {price}
                                {price !== "request" ? "$" : null}
                              </span>
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <QuantitySelect
                            value={quantity}
                            onChangeHandler={quantityHandler}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Link
                            to={`/details/${props.collection}/${product.title}?id=${product.id}`}
                            onClick={detailsHandler}
                          >
                            <ProductButton text={"details"} />
                          </Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {price !== "request" ? (
                            <ProductButton
                              text={"Add to cart"}
                              onClick={cartHandler}
                            />
                          ) : (
                            <ProductButton
                              text={"Request"}
                              onClick={requestProduct}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Col
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ProductImage
                    title={product.title}
                    image={product.image}
                    className={styles.picture}
                  />
                  <br />
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} style={{ textAlign: "center" }}>
                      <Col>
                        <h3>
                          {product.title} {product.type}
                        </h3>
                      </Col>
                      <Row>
                        <Col>
                          <AttributeSelect
                            variables={product.variables}
                            priceHandler={setPrice}
                            stockHandler={setInStock}
                            statsHandler={setCurrentStats}
                            skuHandler={setCurrentSKU}
                            height={"200%"}
                          />
                        </Col>
                      </Row>
                      {!inStock || price === "request" ? (
                        <React.Fragment>
                          <br />
                          <br />
                          <Row>
                            <Col>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="Layer_1"
                                data-name="Layer 1"
                                viewBox="0 0 122.88 73.14"
                                width={30}
                                height={20}
                              >
                                <path
                                  fill="#a58546"
                                  class="cls-1"
                                  d="M41.63,58.19a12.9,12.9,0,1,0,25.64,2,13.7,13.7,0,0,0-.16-2H89.28a14.22,14.22,0,0,0-.12,1.87,13.08,13.08,0,0,0,26.16,0,12.7,12.7,0,0,0-.21-2.33,8,8,0,0,0,7.77-7.93V24.14L106.61,6.64H84.32V45.51h-80A3.27,3.27,0,0,0,1,48.68V55A3.27,3.27,0,0,0,4.31,58.2H7.94a12.48,12.48,0,0,0-.16,2,12.9,12.9,0,1,0,25.79,0,13.7,13.7,0,0,0-.16-2h8.22ZM76.48,0H4.1A4.12,4.12,0,0,0,0,4.1V42.27H80.58V4.1A4.11,4.11,0,0,0,76.48,0ZM15.71,60.26c0,6.53,9.92,6.53,9.92,0,0-7-9.92-6.27-9.92,0Zm81.49-.21c0,6.61,10.06,6.61,10.06,0s-10.06-6.65-10.06,0Zm9.33-45.85L114.74,24v3.56H92.49V14.2ZM49.42,60.26c0,6.53,9.92,6.53,9.92,0s-9.92-6.52-9.92,0Z"
                                />
                              </svg>{" "}
                              <span className={styles.request}>on request</span>
                            </Col>
                          </Row>
                        </React.Fragment>
                      ) : null}
                    </Col>
                  </Row>
                </Col>

                <Col xs={12}>
                  <span className={styles.price}>
                    {userCtx.isLoggedIn ? (
                      <React.Fragment>
                        <span>
                          {price}
                          {price !== "request" ? "$" : null}
                        </span>
                      </React.Fragment>
                    ) : (
                      <span>${price}</span>
                    )}
                  </span>
                </Col>
                <Col xs={12}>
                  <ProductQuantity
                    value={quantity}
                    onChangeHandler={quantityHandler}
                  />
                </Col>
                <Col xs={12}>
                  <Link
                    to={`/details/${props.collection}/${product.title}?id=${product.id}`}
                    onClick={detailsHandler}
                  >
                    <ProductButton text={"details"} />
                  </Link>
                </Col>
                <Col xs={12}>
                  {price !== "request" ? (
                    <ProductButton text={"Add to cart"} onClick={cartHandler} />
                  ) : (
                    <ProductButton text={"Request"} onClick={requestProduct} />
                  )}
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SingleProduct;
