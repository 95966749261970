import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./UserDashboardPage.module.css";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import AuthContext from "../Components/context/auth-context";
import UserMenu from "../Components/UserMenu/UserMenu";
import Address from "../Components/UserMenu/Address";
import Dashboard from "../Components/UserMenu/Dashboard";
import AccountDetails from "../Components/UserMenu/AccountDetails";
import Downloads from "../Components/UserMenu/Downloads";
import Orders from "../Components/UserMenu/Orders";
import Newsletter from "../Components/UserMenu/Newsletter";
import Wishlist from "../Components/UserMenu/Wishlist";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const UserDashboardPage = () => {
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const { dashboard } = params;
  const navigate = useNavigate();
  useEffect(() => {
    if (!authCtx.isLoggedIn) navigate("/user");
  }, [authCtx.isLoggedIn]);
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Dashboard | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>

      <section className={styles.background}>
        <Header></Header>
        <Body selectBar={false} cart={true}>
          {authCtx.isLoggedIn ? (
            <div>
              <UserMenu />
              {
                <React.Fragment>
                  {dashboard === "address" ? <Address></Address> : null}
                  {dashboard === "dashboard" ? <Dashboard></Dashboard> : null}
                  {dashboard === "account-details" ? (
                    <AccountDetails></AccountDetails>
                  ) : null}
                  {dashboard === "downloads" ? <Downloads></Downloads> : null}
                  {dashboard === "orders" ? <Orders></Orders> : null}
                  {dashboard === "newsletter" ? (
                    <Newsletter></Newsletter>
                  ) : null}
                  {dashboard === "wishlist" ? <Wishlist></Wishlist> : null}
                </React.Fragment>
              }
            </div>
          ) : (
            <div>log in first in order to access your account details.</div>
          )}
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};
export default UserDashboardPage;
