import React from "react";
const SeoContext = React.createContext({
  title: "GGItems",
  description:
    "Diablo 2 Items Store, Cheap D2 items, Buy D2 items, D2 Webshop, Diablo Items, Diablo 2 Items, Diablo 2 Shop, Diablo store",
  image: "",
  setSeo: () => {},
});

export default SeoContext;
