import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./DetailedProduct.module.css";
import AttributeSelect from "../Products/AttributeSelect";
import ProductButton from "../Products/ProductButton";
import ProductImage from "../Products/ProductImage";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import CartContext from "../context/cart-context";
import Alert from "../Alert/Alert";
import PopUp from "../Wishlist/PopUp";
import { Helmet } from "react-helmet";
import ProductQuantity from "../Products/ProductQuantity";
import AuthContext from "../context/auth-context";
import brandLogo from "../../graphics/ggitems_logo.png";
import settings from "../../settings.json";
import RequestPopUp from "../Request/RequestPopUp";
import QuantitySelect from "../Products/QuantitySelect";

const DetailedProduct = () => {
  const params = useParams();

  const { collection } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [currentStats, setCurrentStats] = useState(null);
  const [currentSKU, setCurrentSKU] = useState(null);
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [inStock, setInStock] = useState(true);
  const [alert, setAlert] = useState({
    message: "",
    visible: false,
  });
  const [popup, setPopUp] = useState(false);
  const [requestPopup, setRequestPopUp] = useState(false);

  const navigate = useNavigate();
  const cartCtx = useContext(CartContext);
  const userCtx = useContext(AuthContext);

  useEffect(() => {
    (async function () {
      const realm = cartCtx.realm;
      const searchParams = new URLSearchParams(window.location.search);
      const id = searchParams.get("id");
      let database;

      if (realm === "D2R Softcore Ladder") {
        database = "ProductsD2RLadder";
      } else if (realm === "D2R Softcore Non-Ladder") {
        database = "ProductsD2RNLadder";
      } else if (realm === "D2R Hardcore Ladder") {
        database = "ProductsD2RHCLadder";
      } else if (realm === "D2R Hardcore Non-Ladder") {
        database = "ProductsD2RHCNLadder";
      } else {
        setAlert({
          visible: true,
          message: "Select Game before viewing product.",
        });
        setTimeout(() => {
          setAlert({
            visible: false,
            message: "",
          });
        }, 5000);
        return;
      }

      const serverAddress = settings.serverAddress;
      const response = await fetch(`${serverAddress}/api/details/find`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          realm: database,
          collection: collection,
          id: id,
        }),
      });

      const data = await response.json();

      setProduct(data);

      setPrice(data.variables[0].itemPriceUSD);
      setCurrentStats(data.variables[0].itemStats);
      setCurrentSKU(data.variables[0].GGSKU);

      setIsLoading(false);
    })();
  }, [cartCtx.realm, params]);

  const wishlistHandler = () => {
    setPopUp(true);
  };

  const hideWishlist = (added) => {
    setPopUp(false);
    if (added === true) {
      setAlert({
        visible: true,
        message: "Product added to wishlist!",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };
  const cartHandler = () => {
    let stats = product.variables.filter((p) => +p.itemPriceUSD === +price);
    let itemPrice = stats[0].itemPriceUSD;

    const item = {
      id: product.id,
      collection: product.collection,
      title: product.title,
      image: product.image,
      stats: stats[0].itemStats,
      price: itemPrice,
    };
    if (cartCtx.realm === "Select Game") {
      setAlert({
        visible: true,
        message: "Select game before adding products!",
      });
    } else {
      cartCtx.addItem(item, quantity);
      setAlert({
        visible: true,
        message: "Product added to cart!",
      });
    }

    setTimeout(() => {
      setAlert({
        visible: false,
        message: "",
      });
    }, 3000);
  };

  const quantityHandler = (val) => setQuantity(val);

  const requestProduct = () => {
    if (userCtx.isLoggedIn) setRequestPopUp(true);
    else {
      setAlert({
        visible: true,
        message: "Log in or create an account before requesting a product.",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };

  const hidePopup = (requested) => {
    setRequestPopUp(false);
    if (requested === true) {
      setAlert({
        visible: true,
        message: "Product Requested!",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {product !== null ? product.title : "GGItems"} | Buy D2R Items, Diablo
          2 Resurrected - Legit, Cheap &amp; Instant Delivery
        </title>
        <meta property="og:image" content={brandLogo}></meta>
        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      {alert.visible ? <Alert message={alert.message} /> : null}
      {requestPopup ? (
        <RequestPopUp
          onClose={hidePopup}
          title={product.title}
          stats={currentStats}
          sku={currentSKU}
          productId={product.id}
          collection={collection}
        />
      ) : null}
      {popup ? <PopUp onClose={hideWishlist} /> : null}
      {!isLoading ? (
        <div className={styles.container}>
          {product === null ? null : (
            <React.Fragment>
              <Row className={styles.upperContainer}>
                <Col md={6}>
                  <div className={styles.imgContainer}>
                    <ProductImage
                      title={product.title}
                      image={product.image}
                    ></ProductImage>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={styles.detContainer}>
                    {product.description.map((line) => {
                      return (
                        <div key={line.content}>
                          <span style={{ color: line.color }}>
                            {line.content}
                          </span>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              <Row className={styles.containerBorder}>
                <Col>
                  <div className={styles.detContainer}>
                    <Row>
                      <label className={styles.title}>
                        Select stats from dropdown
                      </label>
                    </Row>
                    <Row>
                      <Col md={10}>
                        <AttributeSelect
                          variables={product.variables}
                          priceHandler={setPrice}
                          stockHandler={setInStock}
                          statsHandler={setCurrentStats}
                          skuHandler={setCurrentSKU}
                          height={"3em"}
                        ></AttributeSelect>
                      </Col>
                      <Col>
                        <QuantitySelect
                          onChangeHandler={quantityHandler}
                          value={quantity}
                        />
                        {/* <ProductQuantity
                          value={quantity}
                          onChangeHandler={quantityHandler}
                          height={"3em"}
                          margin={windowWidth > 768 ? "0" : "0.25em 0em"}
                          padding={"0"}
                          fontSize={"100%"}
                        /> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className={styles.price}>
                          {price !== "request" ? `${price}$` : null}
                        </span>
                      </Col>
                    </Row>
                    {price !== "request" ? (
                      <ProductButton
                        text={"Add to wishlist"}
                        onClick={wishlistHandler}
                      />
                    ) : (
                      <React.Fragment>
                        <br />
                        <Row>
                          <Col>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_1"
                              data-name="Layer 1"
                              viewBox="0 0 122.88 73.14"
                              width={30}
                              height={20}
                            >
                              <path
                                fill="#a58546"
                                class="cls-1"
                                d="M41.63,58.19a12.9,12.9,0,1,0,25.64,2,13.7,13.7,0,0,0-.16-2H89.28a14.22,14.22,0,0,0-.12,1.87,13.08,13.08,0,0,0,26.16,0,12.7,12.7,0,0,0-.21-2.33,8,8,0,0,0,7.77-7.93V24.14L106.61,6.64H84.32V45.51h-80A3.27,3.27,0,0,0,1,48.68V55A3.27,3.27,0,0,0,4.31,58.2H7.94a12.48,12.48,0,0,0-.16,2,12.9,12.9,0,1,0,25.79,0,13.7,13.7,0,0,0-.16-2h8.22ZM76.48,0H4.1A4.12,4.12,0,0,0,0,4.1V42.27H80.58V4.1A4.11,4.11,0,0,0,76.48,0ZM15.71,60.26c0,6.53,9.92,6.53,9.92,0,0-7-9.92-6.27-9.92,0Zm81.49-.21c0,6.61,10.06,6.61,10.06,0s-10.06-6.65-10.06,0Zm9.33-45.85L114.74,24v3.56H92.49V14.2ZM49.42,60.26c0,6.53,9.92,6.53,9.92,0s-9.92-6.52-9.92,0Z"
                              />
                            </svg>{" "}
                            <span className={styles.request}>on request</span>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                    {price !== "request" ? (
                      <ProductButton
                        text={"Add to cart"}
                        onClick={cartHandler}
                      />
                    ) : (
                      <ProductButton
                        text={"Request"}
                        onClick={requestProduct}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DetailedProduct;
