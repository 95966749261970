import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import image from "../../graphics/Footer/button.png";
import cursor from "../../graphics/Cursor/cursor.png";
import ScrollContext from "../context/scroll-context";
import FooterLink from "./FooterLink";
import styles from "./TechnicalSupport.module.css";
const QuickMenu = () => {
  const scrollCtx = useContext(ScrollContext);

  const handleScroll = () => {
    scrollCtx.scroll();
  };

  return (
    <div className={styles.centered}>
      <Container className={styles.centeredContainer}>
        <h3 className={styles.title}>Quick Menu</h3>
        <div>
          <FooterLink
            to="/faq"
            image={image}
            label="FAQ"
            handleScroll={handleScroll}
          />
          <FooterLink
            to="/sitemap"
            image={image}
            label="Sitemap"
            handleScroll={handleScroll}
          />
          <FooterLink
            to="/services"
            image={image}
            label="Services"
            handleScroll={handleScroll}
          />
          <FooterLink
            to="/about-us"
            image={image}
            label="About Us"
            handleScroll={handleScroll}
          />
          <FooterLink
            to="/disclaimer"
            image={image}
            label="Disclaimer"
            handleScroll={handleScroll}
          />
          <FooterLink
            to="/refund-policy"
            image={image}
            label="Refund Policy"
            handleScroll={handleScroll}
          />
          <FooterLink
            to="/cookie-policy"
            image={image}
            label="Cookie Policy"
            handleScroll={handleScroll}
          />
          <FooterLink
            to="/privacy-policy"
            image={image}
            label="Privacy Policy"
            handleScroll={handleScroll}
          />
          <FooterLink
            to="/terms-of-service"
            image={image}
            label="Terms of Service"
            handleScroll={handleScroll}
          />
        </div>
      </Container>

      <div></div>
    </div>
  );
};

export default QuickMenu;
