import React from "react";
const AuthContext = React.createContext({
  isLoggedIn: false,
  user: {},
  cookiesEnabled: false,
  setUser: () => {},
  removeUser: () => {},
  createWishlist: () => {},
  addItem: () => {},
  removeWishlsit: () => {},
  removeItemWishlist: (id) => {},
  increaseWishlist: (id) => {},
  decreaseWishlist: (id) => {},
  enableCookies: () => {},
});

export default AuthContext;
