import React from "react";
import SingleProduct from "./SingleProduct";
import styles from "./Products.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TypeButton from "./TypeButton.js";
import { Link } from "react-router-dom";
import Services from "../Services/Services";
const Products = (props) => {
  const action = props.data.action;
  const options = props.data.options;

  return (
    <React.Fragment>
      {action === "services" ? (
        <React.Fragment>
          <Services />
        </React.Fragment>
      ) : null}
      {action === "products" ? (
        <React.Fragment>
          {options.length > 0 ? (
            options.map((product) => {
              return (
                <SingleProduct
                  product={product}
                  key={product._title}
                  collection={props.collection}
                />
              );
            })
          ) : (
            <span className={styles.noitems}>
              Looks like there are no items in this category.
            </span>
          )}
        </React.Fragment>
      ) : null}
      {action === "extend" ? (
        <Container>
          <Row>
            {options.map((option) => {
              return (
                <Col key={option} md={4} className={styles.buttonContainer}>
                  <Link to={`./${option}`}>
                    <TypeButton text={option} />
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Container>
      ) : null}
    </React.Fragment>
  );
};

export default Products;
