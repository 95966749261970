import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./WikiPage.module.css";
import React, { useState } from "react";
import BuildButton from "../WikiContent/BuildButton";
import { Col, Row } from "react-bootstrap";
import amazon from "../WikiContent/amazon.json";
import assasin from "../WikiContent/assasin.json";
import barbarian from "../WikiContent/barbarian.json";
import druid from "../WikiContent/druid.json";
import paladin from "../WikiContent/paladin.json";
import necromancer from "../WikiContent/necromancer.json";
import sorceress from "../WikiContent/sorceress.json";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const WikiPage = () => {
  const [build, setBuild] = useState("");
  const setClicked = (clicked) => {
    switch (clicked) {
      case "druid":
        setBuild(druid);
        break;
      case "amazon":
        setBuild(amazon);
        break;
      case "assasin":
        setBuild(assasin);
        break;
      case "paladin":
        setBuild(paladin);
        break;
      case "necromancer":
        setBuild(necromancer);
        break;
      case "sorceress":
        setBuild(sorceress);
        break;
      case "barbarian":
        setBuild(barbarian);
        break;
      default:
        setBuild("");
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Wiki | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>

      <section className={styles.background}>
        <Header></Header>
        <Body cart={true}>
          <Row className={styles.customRow}>
            <Col xs={window.innerWidth > 768 ? "" : 12}>
              <BuildButton text={"amazon"} onClick={setClicked} />
            </Col>
            <Col xs={window.innerWidth > 768 ? "" : 12}>
              <BuildButton text={"assasin"} onClick={setClicked} />
            </Col>
            <Col xs={window.innerWidth > 768 ? "" : 12}>
              <BuildButton text={"barbarian"} onClick={setClicked} />
            </Col>
            <Col xs={window.innerWidth > 768 ? "" : 12}>
              <BuildButton text={"druid"} onClick={setClicked} />
            </Col>
            <Col xs={window.innerWidth > 768 ? "" : 12}>
              <BuildButton text={"necromancer"} onClick={setClicked} />
            </Col>
            <Col xs={window.innerWidth > 768 ? "" : 12}>
              <BuildButton text={"paladin"} onClick={setClicked} />
            </Col>
            <Col xs={window.innerWidth > 768 ? "" : 12}>
              <BuildButton text={"sorceress"} onClick={setClicked} />
            </Col>
          </Row>
          {build !== "" ? (
            <div className={styles.container}>
              <div className={styles.content}>{build.content}</div>
              <div className={styles.title}>{build.title}</div>
              <div>
                {build.builds.map((b) => {
                  return (
                    <div className={styles.contentCont}>
                      {/* <Link to={`./${b.url}`} className={styles.link}> */}
                      <span className={styles.prefix}>{b.prefix}</span>
                      {/* </Link> */}

                      <span className={styles.description}>
                        {b.description}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div>{build.summary}</div>
            </div>
          ) : (
            <div className={styles.container}>
              <span>ABOUT DIABLO II: LORD OF DESTRUCTION</span> <br />
              <span>
                <br />
                Diablo II Lord of Destruction is the expansion of the most
                popular / enjoyable action role playing game that as originally
                released in 2001!
              </span>
              <span>
                <br />
                Adds a number of new features to the core gameplay of Diablo II.
              </span>
              <span>
                <br />
                These include, 2 New Classes (The Mighty Assassin & Element
                Shifting Druid), A Whole new act to adventure through at your
                friends sides based around Mount Arreat in the northern
                Barbarian Highlands, that also means we have a new not seen
                before act boss, Baal!
              </span>
              <span>
                <br />
                Not to mention the 33 New Runes that each have benefits when
                socketed into items, or socketed in certain orders to produce
                very powerful Runeword gear, hundreds of Horadric cube recipes
                for you to test and play around with finding what works best for
                you.
              </span>
              <span>
                <br />
                The stash for storing your stuff is now double the size of the
                original stash meaning more fun finding / killing less time
                muling, Mercenaries now follow the player through any / all
                area’s.
              </span>
              <span>
                <br />
                They can be equipped with armours/weapon & helmets to help them
                gain there own experience, you have full control of being able
                to heal or resurrect from town if the worst happend.
              </span>
              <span>
                <br />
                Diablo 2 can be enjoyed easily through using hotkeys for weapon
                switching / shield / spells & Virtually everything else from
                inventory to chatting.
              </span>
              <span>
                <br />
                For those who like to jump right in and explore more wonderful /
                strong items to help give you that edge over the monsters of
                Diablo II that always find your weak spot we have Crafted ones,
              </span>
              <span>
                <br />
                Although cant be dropped through monsters or chests these are
                apart of Horadric Cube recipies when using the correct
                ingredients, They will have 3-4 Predetermined properties
                determined by which recipe & upto 4 additonal randomly selected
                from the same pool as rare item properties.
              </span>
              <span>
                <br />
                Lastly just to improve the gaming & give you more of a thrill D2
                can be started 800×600 resolution, previously up from 640×480.
              </span>
            </div>
          )}
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default WikiPage;
