import { Col, Container, Row } from "react-bootstrap";
import styles from "./Cookies.module.css";
import CookieButton from "./CookieButton";
import React, { useContext } from "react";
import AuthContext from "../context/auth-context";
import { useNavigate } from "react-router-dom";
const Cookies = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const acceptCookies = () => {
    authCtx.enableCookies();
  };

  const learnMore = () => {
    navigate("/terms");
  };
  return (
    <React.Fragment>
      {authCtx.cookiesEnabled ? null : (
        <Container fluid className={styles.container}>
          <Container>
            <Row>
              <Col>
                <span className={styles.description}>
                  This site uses cookies to help personalise content, tailor
                  your experience and to keep you logged in if you register.{" "}
                  <br />
                  By continuing to use this site, you are consenting to our use
                  of cookies.
                </span>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs={window.innerWidth > 768 ? 3 : 12}>
                <CookieButton text={"Accept"} onClick={acceptCookies} />
              </Col>
              <Col xs={window.innerWidth > 768 ? 3 : 12}>
                <CookieButton text={"Learn more..."} onClick={learnMore} />
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Container>
      )}
    </React.Fragment>
  );
};

export default Cookies;
