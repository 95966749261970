import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./DetailsPage.module.css";
import { useEffect, useState } from "react";
import settings from "../settings.json";
const serverAddress = settings.serverAddress;
const ActivateAccountPage = () => {
  const [isActivated, setIsActivated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const searchParams = new URLSearchParams(window.location.search);
      const userId = searchParams.get("userId");

      const response = await fetch(`${serverAddress}/api/user/activate`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userId,
        }),
      });
      const data = await response.json();
      setIsActivated(data);
      setIsLoading(false);
    })();
  }, []);
  return (
    <section className={styles.background}>
      <Header></Header>
      <Body selectBar={false} searchBar={false} cart={false}>
        {isLoading ? (
          <h1>"Activating..."</h1>
        ) : isActivated ? (
          <h1>Your account has been activated!</h1>
        ) : (
          <h1>
            There were troubles when activating your account, contact support
            for future help.
          </h1>
        )}
      </Body>
      <Footer></Footer>
    </section>
  );
};

export default ActivateAccountPage;
