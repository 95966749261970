import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./OrderDetailsPage.module.css";
import { useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../Components/context/auth-context";
import UserMenu from "../Components/UserMenu/UserMenu";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const OrderDetailsPage = () => {
  const authCtx = useContext(AuthContext);
  const location = useLocation();
  const [order, setOrder] = useState(location.state);

  useEffect(() => {
    // window.onload = document
    //   .querySelector(`.${styles.container}`)
    //   .scrollIntoView();
    if (location.state !== undefined) {
      setOrder(location.state);
    }
  }, [location]);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Order Details | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap
          &amp; Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body selectBar={false} cart={true}>
          {authCtx.isLoggedIn ? (
            <div>
              <UserMenu />
              <div className={styles.orderContainer}>
                <h3>
                  Order id: <span className={styles.default}>{order.id} </span>
                </h3>
                <div>
                  payment:{" "}
                  {order.payment === "paid" ? (
                    <span className={styles.green}>{order.payment} </span>
                  ) : (
                    <span className={styles.red}>{order.payment} </span>
                  )}
                </div>
                <div>
                  status:{" "}
                  {order.status === "completed" ? (
                    <span className={styles.green}>{order.status} </span>
                  ) : null}
                  {order.status === "collecting..." ? (
                    <span className={styles.blue}>{order.status} </span>
                  ) : null}
                  {order.status === "transaction rejected" ? (
                    <span className={styles.red}>{order.status} </span>
                  ) : null}
                </div>
                <div>
                  date: <span className={styles.default}> {order.date}</span>{" "}
                </div>
                <div>
                  email:{" "}
                  <span className={styles.default}>{order.customer.email}</span>{" "}
                </div>
                <div>
                  first name:{" "}
                  <span className={styles.default}>
                    {order.customer.firstName}
                  </span>{" "}
                </div>
                <div>
                  last name:{" "}
                  <span className={styles.default}>
                    {order.customer.lastName}
                  </span>{" "}
                </div>
                <div>
                  receipt:
                  <div className={styles.tableCont}>
                    <table>
                      <tbody>
                        {order.items.map((item) => {
                          return (
                            <tr className={styles.tableRow} key={item.name}>
                              <td className={styles.tableCell}>{item.name}</td>
                              <td className={styles.tableCell}>
                                x{item.quantity}
                              </td>
                              <td className={styles.tableCell}>
                                ${+item.priceInCents / 100}/ea
                              </td>
                            </tr>
                          );
                        })}
                        {/* <tr className={styles.tableRow}>
                        <td className={styles.tableCell} colSpan={2}></td>
                        <td className={styles.tableCell}>
                          {/* total: ${(+cartCtx.totalAmount).toFixed(2)} */}
                        {/* </td> */}
                        {/* </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>log in first in order to access your account details.</div>
          )}
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default OrderDetailsPage;
