import React, { useContext, useState } from "react";
import styles from "./UserForm.module.css";
import { Col, Row } from "react-bootstrap";
import settings from "../../settings.json";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/auth-context";
import Alert from "../Alert/Alert";
import NewsletterCheckbox from "../NewsletterCheckbox/NewsletterCheckbox";
import { useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const UserForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [token, setToken] = useState();
  const [method, setMethod] = useState("Log in");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [pwMatch, setPwMatch] = useState(true);
  const [alert, setAlert] = useState({ visible: false, message: "" });
  const [isNewsletterSubscribed, setIsNewsletterSubscribed] = useState(true);

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
  };
  const lastNameHandler = (e) => {
    setLastName(e.target.value);
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  const verifyPasswordHandler = (e) => {
    setVerifyPassword(e.target.value);
  };

  const validatePasswowrds = () => {
    if (password !== verifyPassword) setPwMatch(false);
    else setPwMatch(true);
  };
  const switchMethodHandler = () => {
    if (method === "Log in") setMethod("Register");
    else setMethod("Log in");
  };

  const newsletterHandler = () => {
    setIsNewsletterSubscribed(!isNewsletterSubscribed);
  };

  const getUserGeolocationDetails = async () => {
    const response = await fetch(`https://geolocation-db.com/json/`, {
      method: "GET",
    });
    const { IPv4, country_name } = await response.json();

    return { ip: IPv4, country: country_name };
  };

  const getToken = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const result = await executeRecaptcha("dynamicAction");
    setToken(result);
  }, [executeRecaptcha]);

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha("dynamicAction");
      setToken(token);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (method === "Log in") {
      const serverAddress = settings.serverAddress;
      const geolocation = await getUserGeolocationDetails();
      const response = await fetch(`${serverAddress}/api/login`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          pw: password,
          newsletter: isNewsletterSubscribed,
          geolocation: geolocation,
        }),
      });
      const data = await response.json();
      const status = data.status;
      const message = data.message;
      const user = data.user;

      setAlert({
        visible: true,
        message: message,
      });
      if (status === "sucess") {
        setEmail("");
        setPassword("");
        setFirstName("");
        setLastName("");
        setVerifyPassword("");
        authCtx.setUser(user);
        navigate("/user/dashboard");
      } else {
        setAlert({
          visible: true,
          message: "Password does not match",
        });
      }
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    } else if (pwMatch && firstName.length > 0 && lastName.length > 0) {
      await getToken();
      setAlert({
        visible: true,
        message: "Creating an account...",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 25000);
      const serverAddress = settings.serverAddress;
      const geolocation = await getUserGeolocationDetails();
      const response = await fetch(
        `${serverAddress}/api/${method.toLowerCase()}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            firstName: firstName,
            lastName: lastName,
            pw: password,
            newsletter: isNewsletterSubscribed,
            geolocation: geolocation,
            token: token,
          }),
        }
      );
      const data = await response.json();
      const status = data.status;
      const message = data.message;
      const user = data.user;

      setAlert({
        visible: true,
        message: message,
      });
      if (status === "sucess") {
        setEmail("");
        setPassword("");
        setFirstName("");
        setLastName("");
        setVerifyPassword("");
        authCtx.setUser(user);
        navigate("/user/dashboard");
      }
    } else {
      setAlert({
        visible: true,
        message: "Password does not match",
      });
    }
    setTimeout(() => {
      setAlert({
        visible: false,
        message: "",
      });
    }, 3000);
  };
  return (
    <React.Fragment>
      {alert.visible ? <Alert message={alert.message} /> : null}
      <div>
        <Row>
          <Col xs={window.innerWidth > 768 ? 4 : 0}></Col>
          <Col xs={window.innerWidth > 768 ? 4 : 12}>
            <div className={styles.formContainer}>
              <h1>{method}</h1>
              <form onSubmit={submitHandler} className={styles.form}>
                {method === "Log in" ? (
                  <React.Fragment>
                    <div className={styles.container}>
                      <label>email address</label>
                      <br />
                      <input
                        type="email"
                        className={styles.inp}
                        value={email}
                        onChange={emailHandler}
                      ></input>
                    </div>
                    <div className={styles.container}>
                      <label>password</label>
                      <br />
                      <input
                        type="password"
                        className={styles.inp}
                        value={password}
                        onChange={passwordHandler}
                      ></input>
                    </div>
                    <div className={styles.container}>
                      <Link
                        to={"/user"}
                        className={styles.link}
                        onClick={switchMethodHandler}
                      >
                        Create a free GGItems account
                      </Link>
                      <br />
                      <Link
                        to={"/user/password-recovery"}
                        className={styles.link}
                      >
                        Can't log in?
                      </Link>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className={styles.container}>
                      <label>email address</label> <br />
                      <input
                        type="email"
                        className={styles.inp}
                        value={email}
                        onChange={emailHandler}
                      ></input>
                    </div>
                    <div className={styles.container}>
                      <label>First Name</label> <br />
                      <input
                        type="text"
                        className={styles.inp}
                        value={firstName}
                        onChange={firstNameHandler}
                      ></input>
                    </div>
                    <div className={styles.container}>
                      <label>Last Name</label> <br />
                      <input
                        type="text"
                        className={styles.inp}
                        value={lastName}
                        onChange={lastNameHandler}
                      ></input>
                    </div>
                    <div className={styles.container}>
                      <label>password</label>
                      <br />
                      <input
                        type="password"
                        className={styles.inp}
                        value={password}
                        onChange={passwordHandler}
                        onBlur={validatePasswowrds}
                        // style={{
                        //   border: pwMatch
                        //     ? "1px solid transparent"
                        //     : "1px solid red",
                        // }}
                      ></input>
                    </div>
                    <div className={styles.container}>
                      <label>confirm password</label>
                      <br />
                      <input
                        type="password"
                        className={styles.inp}
                        value={verifyPassword}
                        onChange={verifyPasswordHandler}
                        onBlur={validatePasswowrds}
                        style={{
                          border: pwMatch
                            ? "1px solid #a58546"
                            : "1px solid red",
                        }}
                      ></input>
                    </div>
                    <div className={styles.container}>
                      <NewsletterCheckbox
                        isNewsletterSubscribed={isNewsletterSubscribed}
                        newsletterHandler={newsletterHandler}
                      />
                    </div>
                    <Link
                      to={"/user"}
                      className={styles.link}
                      onClick={switchMethodHandler}
                    >
                      Already have an account? Log in
                    </Link>
                    <br />
                  </React.Fragment>
                )}
                <button type="submit" className={styles.btn}>
                  {method}
                </button>
              </form>
            </div>
          </Col>
          <Col xs={window.innerWidth > 768 ? 4 : 0}></Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default UserForm;
