import React, { useEffect } from "react";

const DevToolsDetector = () => {
  useEffect(() => {
    const detectDevTools = () => {
      const devToolsOpened = /./;
      devToolsOpened.toString = function () {
        localStorage.setItem("devToolsDetected", "true");
      };

      console.log("%c", devToolsOpened);
    };

    if (localStorage.getItem("devToolsDetected")) {
      const style = "color: red; font-size: 24px; font-weight: bold;";
      console.log("%cWarning: Developer Tools detected!", style);
    }

    detectDevTools();
  }, []);

  return null;
};

export default DevToolsDetector;
