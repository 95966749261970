import { useEffect, useReducer } from "react";
import CartContext from "./cart-context";
import settings from "../../settings.json";
import { useContext } from "react";
import AuthContext from "./auth-context";

const defaultCartState = {
  isVisible: false,
  realm: "Select Game",
  realms: [],
  items: [],
  totalAmount: 0,
  bonusDiscount: 0,
  totalItems: 0,
  VAT: 0,
  orderFee: 0,
  couponDiscount: 0,
  coupon: {},
};
const cartReducer = (state, action) => {
  if (action.type === "TOGGLE") {
    return {
      isVisible: !state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: state.items,
      totalAmount: state.totalAmount,
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "ADD") {
    const isAdded = state.items.some(
      (item) =>
        item.product.id === action.item.id &&
        item.realm === state.realm &&
        item.product.stats === action.item.stats
    );

    if (!isAdded) {
      console.log("Adding brand new product");

      const updatedItems = state.items.concat({
        product: action.item,
        quantity: action.quantity,
        realm: state.realm,
      });
      // setCart(updatedItems, action.authCtx);
      return {
        isVisible: state.isVisible,
        realm: state.realm,
        realms: state.realms,
        items: updatedItems,
        totalAmount: (
          +state.totalAmount +
          +action.item.price * +action.quantity
        ).toFixed(2),
        totalItems: state.totalItems,
        VAT: state.VAT,
        orderFee: state.orderFee,
        coupon: state.coupon,
        bonusDiscount: state.bonusDiscount,
        couponDiscount: state.couponDiscount,
      };
    } else {
      console.log("Incresing quantity of an item");
      const index = state.items.findIndex(
        (item) =>
          item.product.id === action.item.id && item.realm === state.realm
      );
      const prevItem = state.items[index];
      const newItem = {
        ...prevItem,
        quantity: prevItem.quantity + action.quantity,
      };
      let updatedItems = [...state.items];
      updatedItems[index] = newItem;
      // setCart(updatedItems, action.authCtx);
      return {
        isVisible: state.isVisible,
        realm: state.realm,
        realms: state.realms,
        items: updatedItems,
        totalAmount: (
          +state.totalAmount +
          +action.item.price * +action.quantity
        ).toFixed(2),
        coupon: state.coupon,
        bonusDiscount: state.bonusDiscount,
        couponDiscount: state.couponDiscount,
      };
    }
  } else if (action.type === "REMOVE") {
    const item = state.items.find(
      (item) =>
        item.product.id === action.id &&
        item.realm === action.realm &&
        item.product.stats === action.stats
    );
    const updatedTotalAmount = (
      +state.totalAmount -
      +item.quantity * +item.product.price
    ).toFixed(2);
    const index = state.items.findIndex(
      (item) =>
        item.product.id === action.id &&
        item.realm === action.realm &&
        item.product.stats === action.stats
    );
    let updatedItems = [...state.items];
    updatedItems.splice(index, 1);
    setCart(updatedItems, action.authCtx);
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: updatedItems,
      totalAmount: updatedTotalAmount,
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "REALM") {
    return {
      isVisible: state.isVisible,
      realm: action.realm,
      realms: state.realms,
      items: state.items,
      totalAmount: state.totalAmount,
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "SET-REALMS") {
    return {
      isVisible: state.isVisible,
      realm: action.realm,
      realms: action.realms,
      items: state.items,
      totalAmount: state.totalAmount,
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "INCREASE") {
    // console.log(state.items);
    // console.log(action.realm, action.id);
    const item = state.items.find(
      (item) => item.product.id === action.id && item.realm === action.realm
    );
    // console.log(item);
    const updatedTotalAmount = (
      +state.totalAmount + +item.product.price
    ).toFixed(2);
    const index = state.items.findIndex(
      (item) => item.product.id === action.id && item.realm === action.realm
    );
    const prevItem = state.items[index];
    const expectedQuantity = prevItem.quantity + 1;
    const newItem = { ...prevItem, quantity: expectedQuantity };
    let updatedItems = [...state.items];
    updatedItems[index] = newItem;
    setCart(updatedItems, action.authCtx);
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: updatedItems,
      totalAmount: updatedTotalAmount,
      totalItems: state.totalItems++,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "DECREASE") {
    const item = state.items.find(
      (item) => item.product.id === action.id && item.realm === action.realm
    );
    const updatedTotalAmount = (
      +state.totalAmount - +item.product.price
    ).toFixed(2);
    const index = state.items.findIndex(
      (item) => item.product.id === action.id && item.realm === action.realm
    );
    const prevItem = state.items[index];
    const expectedQuantity = prevItem.quantity - 1;

    if (expectedQuantity === 0) {
      let updatedItems = [...state.items];
      updatedItems.splice(index, 1);
      setCart(updatedItems, action.authCtx);
      return {
        isVisible: state.isVisible,
        realm: state.realm,
        realms: state.realms,
        items: updatedItems,
        totalAmount: updatedTotalAmount,
        totalItems: state.totalItems--,
        VAT: state.VAT,
        orderFee: state.orderFee,
        coupon: state.coupon,
        couponDiscount: state.couponDiscount,
      };
    }
    const newItem = { ...prevItem, quantity: expectedQuantity };
    let updatedItems = [...state.items];
    updatedItems[index] = newItem;
    setCart(updatedItems, action.authCtx);
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: updatedItems,
      totalAmount: updatedTotalAmount,
      totalItems: state.totalItems--,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "SET") {
    let totalAmount = 0;
    action.cart.map(
      (item) => (totalAmount += item.product.price * item.quantity)
    );
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: action.cart,
      totalAmount: totalAmount,
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "LOAD") {
    // const items = [...state.items, action.items];
    let newItems = action.items.map((item) => {
      item.realm = state.realm;
      return item;
    });
    // console.log(newItems);
    // let items;
    // if (state.items.length > 0)
    //   items = state.items.map((prevItem) => {
    //     const doubleArr = newItems.map((newItem) => {
    //       console.log(0);
    //       if (newItem.product.id === prevItem.product.id) {
    //         prevItem.quantity += newItem.quantity;
    //         return prevItem;
    //       } else return newItem;
    //     });
    //     return doubleArr[0];
    //   });
    // else items = newItems;
    // console.log("171", items);
    let items = state.items.concat(newItems);
    let totalAmount = 0;
    items.map((item) => (totalAmount += +item.product.price * item.quantity));

    setCart(items, action.authCtx);
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: items,
      totalAmount: totalAmount.toFixed(2),
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "REPLACE") {
    let totalAmount = 0;
    action.items.map(
      (item) => (totalAmount += +item.product.price * item.quantity)
    );

    const items = action.items.map((item) => {
      item.realm = state.realm;
      return item;
    });
    setCart(items, action.authCtx);
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: items,
      totalAmount: totalAmount.toFixed(2),
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "COUPON") {
    const coupon = action.coupon;
    if (coupon.type === "percentage off") {
      const discountOff = (
        (state.totalAmount * coupon.amountOff) /
        100
      ).toFixed(2);
      return {
        isVisible: state.isVisible,
        realm: state.realm,
        realms: state.realms,
        items: state.items,
        totalAmount: state.totalAmount,
        totalItems: state.totalItems,
        VAT: state.VAT,
        orderFee: state.orderFee,
        coupon: coupon,
        bonusDiscount: state.bonusDiscount,
        couponDiscount: discountOff,
      };
    } else if (coupon.type === "amount off") {
      return {
        isVisible: state.isVisible,
        realm: state.realm,
        realms: state.realms,
        items: state.items,
        totalAmount: state.totalAmount,
        totalItems: state.totalItems,
        VAT: state.VAT,
        orderFee: state.orderFee,
        coupon: coupon,
        bonusDiscount: state.bonusDiscount,
        couponDiscount: coupon.amountOff,
      };
    }
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: state.items,
      totalAmount: state.totalAmount,
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "DISCOUNT") {
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: state.items,
      totalAmount: state.totalAmount,
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: state.coupon,
      bonusDiscount: action.discount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "TOTAL-ITEMS") {
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: state.items,
      totalAmount: state.totalAmount,
      totalItems: action.quantity,
      VAT: state.VAT,
      orderFee: state.orderFee,
      coupon: action.coupon,
      bonusDiscount: state.bonusDiscount,
    };
  } else if (action.type === "VAT") {
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: state.items,
      totalAmount: state.totalAmount,
      totalItems: state.totalItems,
      VAT: action.amount,
      orderFee: state.orderFee,
      coupon: action.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  } else if (action.type === "ORDER-FEE") {
    return {
      isVisible: state.isVisible,
      realm: state.realm,
      realms: state.realms,
      items: state.items,
      totalAmount: state.totalAmount,
      totalItems: state.totalItems,
      VAT: state.VAT,
      orderFee: action.fee,
      coupon: action.coupon,
      bonusDiscount: state.bonusDiscount,
      couponDiscount: state.couponDiscount,
    };
  }
};

const setCart = async (cart, authCtx) => {
  if (authCtx.isLoggedIn) {
    const serverAddress = settings.serverAddress;
    /*const response = */ await fetch(`${serverAddress}/api/cart/setCart`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        user: authCtx.user,
        cart: cart,
      }),
    });
    // const data = await response.text();
    // if (!response.ok) alert("there occured some problem!");
  }
};

const CartProvider = (props) => {
  const [cartState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultCartState
  );

  const authCtx = useContext(AuthContext);
  const serverAddress = settings.serverAddress;

  useEffect(() => {
    (async function () {
      if (authCtx.user.email !== null) {
        const response = await fetch(`${serverAddress}/api/cart/getCart`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            user: authCtx.user,
          }),
        });
        const data = await response.json();
        dispatchCartAction({ type: "SET", cart: data });
      }
    })();
  }, [authCtx, serverAddress]);

  const toggleHandler = () => {
    dispatchCartAction({ type: "TOGGLE" });
  };

  const toggleRealm = (realm) => {
    dispatchCartAction({ type: "REALM", realm: realm, authCtx: authCtx });
  };

  const addItemHandler = async (item, quantity = 1) => {
    // if (authCtx.isLoggedIn) {
    //   const response = await fetch(`${serverAddress}/api/cart/add`, {
    //     method: "POST",
    //     headers: {
    //       "Content-type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       user: authCtx.user,
    //       item: item,
    //     }),
    //   });
    //   const data = await response.text();
    //   console.log(data);
    //   dispatchCartAction({ type: "ADD", item: item });
    // } else
    dispatchCartAction({
      type: "ADD",
      item: item,
      quantity: quantity,
      authCtx: authCtx,
    });
  };

  const removeItemHandler = (id, realm, stats) => {
    dispatchCartAction({
      type: "REMOVE",
      id: id,
      realm: realm,
      stats: stats,
      authCtx: authCtx,
    });
  };

  const increaseHandler = (id, realm) => {
    dispatchCartAction({
      type: "INCREASE",
      id: id,
      realm: realm,
      authCtx: authCtx,
    });
  };
  const decreaseHandler = (id, realm) => {
    dispatchCartAction({
      type: "DECREASE",
      id: id,
      realm: realm,
      authCtx: authCtx,
    });
  };

  const loadWishlsitHandler = (items) => {
    dispatchCartAction({ type: "LOAD", items: items });
  };

  const replaceWishlistHandler = (items) => {
    dispatchCartAction({ type: "REPLACE", items: items });
  };
  const setCouponHandler = (coupon) => {
    dispatchCartAction({ type: "COUPON", coupon: coupon });
  };

  const setBonusDiscountHandler = (discount) => {
    dispatchCartAction({ type: "DISCOUNT", discount: discount });
  };

  const setTotalItemsHandler = (quantity) => {
    dispatchCartAction({ type: "TOTAL-ITEMS", quantity: quantity });
  };
  const setVATHandler = (amount) => {
    dispatchCartAction({ type: "VAT", amount: amount });
  };
  const setOrderFeeHandler = (fee) => {
    dispatchCartAction({ type: "ORDER-FEE", fee: fee });
  };
  const setRealmsHandler = (realms) => {
    dispatchCartAction({ type: "SET-REALMS", realms: realms });
  };

  const cartContext = {
    isVisible: cartState.isVisible,
    realm: cartState.realm,
    items: cartState.items,
    totalAmount: cartState.totalAmount,
    bonusDiscount: cartState.bonusDiscount,
    coupon: cartState.coupon,
    totalItems: cartState.totalItems,
    VAT: cartState.VAT,
    orderFee: cartState.orderFee,
    couponDiscount: cartState.couponDiscount,
    toggle: toggleHandler,
    setRealm: toggleRealm,
    setRealms: setRealmsHandler,
    addItem: addItemHandler,
    removeItem: removeItemHandler,
    increase: increaseHandler,
    decrease: decreaseHandler,
    loadWishlist: loadWishlsitHandler,
    replaceWishlist: replaceWishlistHandler,
    setCoupon: setCouponHandler,
    setBonusDiscount: setBonusDiscountHandler,
    setTotalItems: setTotalItemsHandler,
    setVAT: setVATHandler,
    setOrderFee: setOrderFeeHandler,
  };

  return (
    <CartContext.Provider value={cartContext}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;
