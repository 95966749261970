import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "./Header.module.css";
import Slider from "./Slider";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useParams } from "react-router-dom";
import React, { useContext } from "react";
import CartContext from "../context/cart-context";
import AuthContext from "../context/auth-context";
import accountIcon from "../../graphics/Account/myaccounticon.png";
import accountIconHover from "../../graphics/Account/myaccounticon_hover.png";
import { useState } from "react";
import { useEffect } from "react";
import { ButtonToolbar, DropdownButton, NavbarBrand } from "react-bootstrap";
import "./button.css";
import brand from "../../graphics/SliderLogo/ggitems_logo.png";
import dotSeparator from "../../graphics/separtors/dot.png";
import cursor from "../../graphics/Cursor/cursor_hover.png";
import texture from "../../graphics/Cookies/leather-texture_800.webp";
import ScrollContext from "../context/scroll-context";
import settings from "../../settings.json";
const Header = () => {
  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);
  const scrollCtx = useContext(ScrollContext);

  // const params = useParams();
  const [isHovering, setIsHovering] = useState(false);
  const [accountImage, setImage] = useState(accountIcon);
  const [currentSite, setCurrentSite] = useState("");
  const [activeRealms, setActiveRealms] = useState([]);

  useEffect(() => {
    if (isHovering) setImage(accountIconHover);
    else setImage(accountIcon);
  }, [isHovering]);

  const setRealm = (realm) => {
    cartCtx.setRealm(realm.displayName);
  };

  useEffect(() => {
    const slicedLink = window.location.href.split("/");
    const lastParam = slicedLink[slicedLink.length - 1];
    setCurrentSite(lastParam);
  }, []);
  useEffect(() => {
    (async function () {
      const serverAddress = settings.serverAddress;
      const response = await fetch(`${serverAddress}/api/get-realms`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      setActiveRealms(data);
      // cartCtx.setRealms(data);
    })();
  }, []);

  const scrollHandler = () => {
    scrollCtx.scroll();
  };

  const userPath = authCtx.isLoggedIn ? "/user/dashboard" : "/user";
  return (
    <Container className={styles.container} fluid>
      <Row>
        <Navbar expand="lg" fixed="top" className={styles.navbarContainer}>
          <Container
            fluid
            className={styles.container}
            style={{
              background: `linear-gradient(180deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0) 100%),linear-gradient(90deg,#000000 0%,rgba(0, 0, 0, 0) 24.58%,rgba(0, 0, 0, 0) 49.58%,rgba(0, 0, 0, 0) 76.15%,#000000 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),url(${texture})`,
              // backgroundImage: `url(${collapseBg})`,
              // borderBottom: "2px solid #a58256",
            }}
          >
            <NavbarBrand>
              <Link
                className="navbar-brand"
                style={{ color: "#a58546" }}
                to="/"
                onClick={scrollHandler}
              >
                <img src={brand} alt="logo" className={styles.brandLogo}></img>
              </Link>
            </NavbarBrand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              // style={{
              //   background: `linear-gradient(180deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0) 100%),linear-gradient(90deg,#000000 0%,rgba(0, 0, 0, 0) 24.58%,rgba(0, 0, 0, 0) 49.58%,rgba(0, 0, 0, 0) 76.15%,#000000 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),url(${texture})`,
              //   // backgroundImage: `url(${collapseBg})`,
              //   // borderBottom: "2px solid #a58256",
              // }}
            >
              <Nav className="me-auto">
                <span className="nav-link" style={{ minWidth: "fit-content" }}>
                  <Link
                    to={"/"}
                    className={styles.navbar}
                    style={currentSite === "" ? { color: "#ba8" } : null}
                    onClick={scrollHandler}
                  >
                    GGItems
                  </Link>
                </span>
                {window.innerWidth > 768 ? (
                  <img
                    src={dotSeparator}
                    alt="separator"
                    className={styles.verticalSeparator}
                  />
                ) : (
                  <span className={styles.separator}></span>
                )}
                <span className="nav-link" style={{ minWidth: "fit-content" }}>
                  <Link
                    to={"/wiki"}
                    className={styles.navbar}
                    style={currentSite === "wiki" ? { color: "#ba8" } : null}
                    onClick={scrollHandler}
                  >
                    Wiki
                  </Link>
                </span>
                {window.innerWidth > 768 ? (
                  <img
                    src={dotSeparator}
                    alt="separator"
                    className={styles.verticalSeparator}
                  />
                ) : (
                  <span className={styles.separator}></span>
                )}
                <span className="nav-link" style={{ minWidth: "fit-content" }}>
                  <Link
                    to={"/terms"}
                    className={styles.navbar}
                    style={currentSite === "terms" ? { color: "#ba8" } : null}
                    onClick={scrollHandler}
                  >
                    Terms
                  </Link>
                </span>
                {window.innerWidth > 768 ? (
                  <img
                    src={dotSeparator}
                    alt="separator"
                    className={styles.verticalSeparator}
                  />
                ) : (
                  <span className={styles.separator}></span>
                )}
                <span className="nav-link">
                  <Link
                    to={"/contact"}
                    className={styles.navbar}
                    style={currentSite === "contact" ? { color: "#ba8" } : null}
                    onClick={scrollHandler}
                  >
                    Contact Us
                  </Link>
                </span>
              </Nav>
              <Nav>
                <NavDropdown
                  title={
                    <span className={styles.gameText}>{cartCtx.realm}</span>
                  }
                  className={styles.gameSelect}
                  id="basic-nav-dropdown"
                >
                  {activeRealms.map((a, i) => {
                    return (
                      <React.Fragment key={i}>
                        <NavDropdown.Item
                          onClick={() => {
                            setRealm(a);
                          }}
                          className={styles.dropdownLink}
                        >
                          {a.displayName}
                        </NavDropdown.Item>
                        {i === activeRealms.length - 1 ? null : (
                          <span className={styles.separator}></span>
                        )}
                      </React.Fragment>
                    );
                  })}
                </NavDropdown>
              </Nav>
              <Nav
                style={
                  window.innerWidth > 768
                    ? { marginRight: "5rem", alignItems: "center" }
                    : { alignItems: "center" }
                }
              >
                <span
                  className="nav-link"
                  style={{
                    cursor: `url(${cursor}),auto`,
                  }}
                >
                  {authCtx.isLoggedIn ? (
                    <ButtonToolbar
                      className={styles.accountDropdown}
                      onMouseEnter={() => setIsHovering(true)}
                      onMouseLeave={() => setIsHovering(false)}
                    >
                      <DropdownButton
                        title={
                          <img
                            src={accountImage}
                            className={styles.accountIcon}
                            alt={"account"}
                          ></img>
                        }
                        className={styles.accountDropdown}
                      >
                        <Link
                          to={"/user/dashboard"}
                          className={styles.dropdownLink}
                        >
                          Profile
                        </Link>
                        <span className={styles.separator}></span>
                        <Link
                          to={"/user/orders"}
                          className={styles.dropdownLink}
                        >
                          Orders
                        </Link>{" "}
                        <span className={styles.separator}></span>
                        <Link
                          to={"/user/wishlist"}
                          className={styles.dropdownLink}
                        >
                          Wishlist
                        </Link>{" "}
                        <span className={styles.separator}></span>
                        <Link
                          to={"/user"}
                          className={styles.dropdownLink}
                          onClick={authCtx.removeUser}
                        >
                          Logout
                        </Link>{" "}
                        <br />
                      </DropdownButton>
                    </ButtonToolbar>
                  ) : (
                    <Link
                      to={userPath}
                      className={styles.navbar}
                      onMouseEnter={() => setIsHovering(true)}
                      onMouseLeave={() => setIsHovering(false)}
                      onClick={scrollHandler}
                    >
                      <img
                        src={accountImage}
                        className={styles.accountIcon}
                        alt={"account"}
                      ></img>
                    </Link>
                  )}
                </span>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
      <Slider />
    </Container>
  );
};

export default Header;
