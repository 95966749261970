import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./AboutUsPage.module.css";
import React from "react";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const AboutUsPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          About us | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={brandLogo}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body cart={true}>
          <div className={styles.container}>
            <span className={styles.text}>
              GGItems is your Diablo 2 Stop & Shop for all your Items & Leveling
              services. Selling Diablo 2 Items & Leveling services is what got
              us here today! Our main goal is to give you an excellent game time
              without the use of any illegal bots or third party software.
            </span>
            <br />

            <span className={styles.title}>WHO ARE WE?</span>
            <br />
            <span className={styles.text}>
              GGItems, we care about our customers and that’s why we’re
              constantly looking to improve our services.
            </span>
            <br />

            <span className={styles.text}>
              GGItems was established by a group of Old Gamers who wanted to
              serve the community by mainly selling Diablo 2 Items & Leveling
              services.
            </span>
            <br />

            <span className={styles.title}>WHY CHOOSE US?</span>
            <br />
            <span className={styles.text}>
              Customer satisfaction is our highest priority, and we never fail
              to exceed the customer’s expectations! What makes our offers
              unique and unmatched is how quick we are able to fulfill and
              complete each order ahead of time. GGItems, we attach great
              importance to being more than just a seller for our customers. We
              see ourselves as a long-term partner with the responsibility for
              the success of our customer’s business. We always go a step
              further so that you are satisfied with us.
            </span>
            <br />
          </div>
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default AboutUsPage;
