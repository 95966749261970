import { useContext, useEffect, useState } from "react";
import styles from "./QuantitySelect.module.css";
import AuthContext from "../context/auth-context";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import cursor from "../../graphics/Cursor/cursor_hover.png";
import "./MUISelect.css";
const availableQuantity = [
  { value: 1, id: 1 },
  { value: 2, id: 2 },
  { value: 3, id: 3 },
  { value: 4, id: 4 },
  { value: 5, id: 5 },
  { value: 6, id: 6 },
  { value: 7, id: 7 },
  { value: 8, id: 8 },
  { value: 9, id: 9 },
  { value: 10, id: 10 },
  { value: 11, id: 11 },
  { value: 12, id: 12 },
  { value: 13, id: 13 },
  { value: 14, id: 14 },
  { value: 15, id: 15 },
  { value: 16, id: 16 },
  { value: 17, id: 17 },
  { value: 18, id: 18 },
  { value: 19, id: 19 },
  { value: 20, id: 20 },
  { value: 21, id: 21 },
  { value: 22, id: 22 },
  { value: 23, id: 23 },
  { value: 24, id: 24 },
  { value: 25, id: 25 },
  { value: 26, id: 26 },
  { value: 27, id: 27 },
  { value: 28, id: 28 },
  { value: 29, id: 29 },
  { value: 30, id: 30 },
  { value: 31, id: 31 },
  { value: 32, id: 32 },
  { value: 33, id: 33 },
  { value: 34, id: 34 },
  { value: 35, id: 35 },
  { value: 36, id: 36 },
  { value: 37, id: 37 },
  { value: 38, id: 38 },
  { value: 39, id: 39 },
  { value: 40, id: 40 },
  { value: 41, id: 41 },
  { value: 42, id: 42 },
  { value: 43, id: 43 },
  { value: 44, id: 44 },
  { value: 45, id: 45 },
  { value: 46, id: 46 },
  { value: 47, id: 47 },
  { value: 48, id: 48 },
  { value: 49, id: 49 },
  { value: 50, id: 50 },
];

const QuantitySelect = (props) => {
  const [currentValue, setCurrentValue] = useState(props.value);

  //   useEffect(() => {
  //     setCurrentValue(props.variables[0].itemStats);
  //     setVariablesLength(props.variables.length);
  //   }, [props.variables]);

  //   const priceHandler = (e) => {
  //     const attribute = e.target.value;
  //     for (const variable of props.variables) {
  //       if (attribute === variable.itemStats) {
  //         console.log(variable);
  //         props.stockHandler(variable.inStock);
  //         props.priceHandler(variable.itemPriceUSD);
  //         props.statsHandler(attribute);
  //         setCurrentValue(attribute);
  //         props.skuHandler(variable.GGSKU);
  //       }
  //     }
  //   };
  const quantityHandler = (e) => {
    const selectedQuantity = e.target.value;

    setCurrentValue(selectedQuantity);
    props.onChangeHandler(selectedQuantity);
  };
  return (
    <FormControl fullWidth>
      <InputLabel style={{ color: "#ba8" }}>Quantity</InputLabel>
      <Select
        value={currentValue}
        label="Quantity as"
        onChange={quantityHandler}
        className={`${styles.select}`}
        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
      >
        {availableQuantity.map((variable, i) => {
          return (
            <MenuItem
              value={variable.value}
              key={variable.id}
              className={styles.option}
              style={{
                cursor: `url(${cursor}),auto`,
                textWrap: "balance",
                backgroundColor: "#333",
                color: "#ba8",
                marginTop: i === 0 ? "-8px" : 0,
                marginBottom: i === availableQuantity.length - 1 ? "-8px" : 0,
              }}
            >
              {variable.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default QuantitySelect;
