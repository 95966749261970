import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import styles from "./TechnicalSupport.module.css";
const TechnicalSupport = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.centered}>
      <Container className={styles.centeredContainer}>
        <h3 className={styles.title}>Technical Support</h3>
        <p className={styles.paragraph}>
          TECHNICAL SUPPORT Find a quick solution for your issues or just ask
          for help before your order. We are available at any time and handle
          your chat with the highest priority.
        </p>
        <button
          className={styles.btn}
          onClick={() => {
            navigate(`/faq`);
            window.scrollTo(0, 0);
          }}
        >
          HELP CENTER
        </button>
      </Container>
    </div>
  );
};

export default TechnicalSupport;
