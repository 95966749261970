import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./DetailsPage.module.css";
import { useEffect } from "react";
import settings from "../settings.json";
const serverAddress = settings.serverAddress;
const SubscribeNewsletterPage = () => {
  useEffect(() => {
    (async function () {
      const searchParams = new URLSearchParams(window.location.search);
      const email = searchParams.get("email");

      const response = await fetch(`${serverAddress}/api/user/subscribe`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      const data = await response.text();

      //TODO
      // add redirect to home page or display resetted password notification
    })();
  }, []);
  return (
    <section className={styles.background}>
      <Header></Header>
      <Body selectBar={true} searchBar={true} cart={true}></Body>
      <Footer></Footer>
    </section>
  );
};

export default SubscribeNewsletterPage;
