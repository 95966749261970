import styles from "./Slider.module.css";
import icon from "../../graphics/SliderLogo/ggitems_logo.png";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Slider = () => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate("/");
  };
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row>
        <div className={styles.image}>
          <img
            src={icon}
            alt="icon"
            className={styles.icon}
            onClick={onClickHandler}
          />
        </div>
      </Row>
    </Container>
  );
};

export default Slider;
