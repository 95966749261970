import React, { useEffect, useState } from "react";
import styles from "./ProductImage.module.css";
const ProductImage = (props) => {
  const [height, setHeight] = useState(0);
  const [frontImage, setFrontImage] = useState();
  const isRuneword = props.image.length > 1;

  useEffect(() => {
    (async function () {
      const img = new Image();
      const front = await (!isRuneword && props.image[0]
        ? require(`../../graphics${props.image[0]}`)
        : require("../../graphics/placeholder/placeholder_100x100.png"));
      img.src = front;

      setFrontImage(front);
      setHeight(img.height);
    })();
  }, []);

  return (
    <React.Fragment>
      {!isRuneword ? (
        <img
          src={frontImage}
          alt={props.title}
          className={styles.image}
          style={{
            maxHeight: height >= 286 ? "196px" : null,
            objectFit: height >= 286 ? "contain" : "none",
          }}
        ></img>
      ) : (
        <div className={styles.container}>
          <div
            className={styles.imgContainer}
            style={{
              backgroundImage: `url(${require(`../../graphics${props.image[1]}`)})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              objectFit: "fill",
              maxHeight: "196px",
            }}
          >
            <img
              src={require(`../../graphics${props.image[0]}`)}
              alt={props.title}
              className={styles.runeImage}
            ></img>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProductImage;
