import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../context/auth-context";
import styles from "./Orders.module.css";
import settings from "../../settings.json";
import SingleOrder from "./SingleOrder";
const Orders = () => {
  const authCtx = useContext(AuthContext);

  const [orders, setOrders] = useState(authCtx.user.orders);

  useEffect(() => {
    (async function () {
      const serverAddress = settings.serverAddress;
      const userId = authCtx.user.userId;
      const response = await fetch(`${serverAddress}/api/getUserData`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
        }),
      });
      const user = await response.json();
      setOrders(user.orders.reverse());
      localStorage.setItem("user", JSON.stringify(user));
    })();
  }, [authCtx.user.userId]);
  return (
    <div className={styles.container}>
      <Row className={styles.row}>
        <h1 className={styles.title}>Orders</h1>
      </Row>
      {orders.length > 0 ? (
        <React.Fragment>
          {orders.map((order) => {
            return <SingleOrder key={order.id} order={order} />;
          })}
        </React.Fragment>
      ) : (
        <Row className={styles.row}>
          <Col>
            <p>You have not placed a single order yet. Browse products!</p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Orders;
