import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../context/auth-context";
import ProductButton from "../Products/ProductButton";
import styles from "./Newsletter.module.css";
import settings from "../../settings.json";
import Alert from "../Alert/Alert";
const Newsletter = () => {
  const authCtx = useContext(AuthContext);
  const [newsletterValue, setNewsletterValue] = useState(
    authCtx.user.newsletter
  );
  const [alert, setAlert] = useState({ visible: false, message: "" });

  const toggleNewsletter = async () => {
    const serverAddress = settings.serverAddress;
    const response = await fetch(`${serverAddress}/api/user/toggleNewsletter`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        user: authCtx.user,
        value: !authCtx.user.newsletter,
      }),
    });
    setNewsletterValue((previousState) => !previousState);
    const user = await response.json();
    authCtx.setUser(user);
    setAlert({
      visible: true,
      message: `You have successfully ${
        newsletterValue ? "unsubscribed" : "subscribed"
      } to the newsletter`,
    });

    setTimeout(() => {
      setAlert({
        visible: false,
        message: "",
      });
    }, 3000);

    // localStorage.setItem('user', JSON.stringify(user))
  };

  return (
    <div className={styles.container}>
      {alert.visible ? <Alert message={alert.message} /> : null}
      <Row className={styles.row}>
        <h1 className={styles.title}>Newsletter</h1>
      </Row>
      <Row className={styles.row}>
        <Col>
          <label>
            Newsletter status:{" "}
            {newsletterValue ? (
              <span className={styles.subscribed}> SUBSCRIBED </span>
            ) : (
              <span className={styles.unsubscribed}> UNSUBSCRIBED</span>
            )}
          </label>
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col>
          <ProductButton
            text={
              newsletterValue
                ? "unsubscribe newsletter"
                : "subscribe newsletter"
            }
            onClick={toggleNewsletter}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Newsletter;
