import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./QuickMenu.module.css";
import { Link } from "react-router-dom";

const FooterLink = ({ to, image, label, handleScroll }) => (
  <Row>
    <Col>
      <Link to={to} className={styles.link} onClick={handleScroll}>
        <img src={image} className={styles.image} alt={label.toLowerCase()} /> -{" "}
        {label}
      </Link>
    </Col>
  </Row>
);

export default FooterLink;
