import { useContext } from "react";
import AuthContext from "../context/auth-context";
import styles from "./SingleWishlist.module.css";
const SingleWishlist = (props) => {
  const wishlist = props.wishlist;
  const authCtx = useContext(AuthContext);

  const addItemToWishlistHandler = () => {
    authCtx.addItem(wishlist, props.item, props.price);
    props.onClose(true);
  };

  return (
    <div className={styles.wishlist} onClick={addItemToWishlistHandler}>
      {wishlist.id}. {wishlist.title}
    </div>
  );
};

export default SingleWishlist;
