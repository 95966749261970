import React, { useContext, useEffect, useState } from "react";
import styles from "./SearchBar.module.css";
import settings from "../../settings.json";
import { Link } from "react-router-dom";
import CartContext from "../context/cart-context";
import { Col, Container, Row } from "react-bootstrap";

const SearchBar = () => {
  const [searchItem, setSearchItem] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);
  const searchHandler = (e) => {
    setSearchItem(e.target.value);
  };

  const cartCtx = useContext(CartContext);

  const clearInput = () => {
    setSearchItem("");
  };

  useEffect(() => {
    (async function () {
      const realm = cartCtx.realm;
      let database;
      if (realm === "D2R Softcore Ladder") {
        database = "ProductsD2RLadder";
      } else if (realm === "D2R Softcore Non-Ladder") {
        database = "ProductsD2RNLadder";
      } else if (realm === "D2R Hardcore Ladder") {
        database = "ProductsD2RHCLadder";
      } else if (realm === "D2R Hardcore Non-Ladder") {
        database = "ProductsD2RHCNLadder";
      } else return;

      const serverAddress = settings.serverAddress;
      const response = await fetch(`${serverAddress}/api/search`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          itemTitle: searchItem,
          database: database,
        }),
      });
      const data = await response.text();
      if (data === "") {
        setSearchedResults([]);
        return;
      }
      setSearchedResults(JSON.parse(data));
    })();
  }, [searchItem]);
  return (
    <div className={styles.container}>
      <input
        type="text"
        value={searchItem}
        className={styles.inp}
        onChange={searchHandler}
        placeholder={"search for items..."}
      ></input>
      <Container>
        <div className={styles.results}>
          {searchedResults.length > 50
            ? null
            : searchedResults.map((product) => {
                return (
                  <Row>
                    {/* {window.innerWidth > 768 ? <Col xs={3}></Col> : null} */}
                    <Col xs={window.innerWidth > 768 ? 12 : 12}>
                      <Link
                        to={`/details/${product.collection}/${product.title}?id=${product.id}`}
                        className={styles.link}
                        onClick={clearInput}
                      >
                        <div key={product.id} className={styles.result}>
                          {product.title}
                        </div>
                      </Link>
                    </Col>
                    {/* {window.innerWidth > 768 ? <Col xs={3}></Col> : null} */}
                  </Row>
                );
              })}
        </div>
      </Container>
    </div>
  );
};
export default SearchBar;
