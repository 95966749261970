import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Socials.module.css";
import LOGO from "../../graphics/ggitems_logo.png";
import FB from "../../graphics/Socials/facebook_icon.webp";
import IG from "../../graphics/Socials/instagram_icon.webp";
import X from "../../graphics/Socials/x_icon.webp";
import D from "../../graphics/Socials/discord_icon.webp";
import TI from "../../graphics/Socials/trustindex_icon.webp";
import { Link } from "react-router-dom";
const Socials = () => {
  return (
    <Container>
      <Row className={styles.centeredContainer}>
        <Col className={styles.centeredContainer}>
          <img src={LOGO} alt="GGItems logo" className={styles.logo} />
        </Col>
      </Row>
      <Row className={styles.centeredContainer}>
        <Col className={styles.centeredContainer}>
          <p className={styles.paragraph}>
            Since 2021, we've been your premier destination for in-game services
            and Diablo 2 items, offering unparalleled customer support.
          </p>
        </Col>
      </Row>
      <Row className={styles.socialContainer}>
        <Col sm={2} className={styles.socialContainer}>
          <a
            href="https://www.facebook.com/ggitem/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img src={FB} alt="Facebook logo" className={styles.socialLogo} />
          </a>
        </Col>
        <Col sm={2} className={styles.socialContainer}>
          <a
            href="https://www.instagram.com/ggitems_"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img src={IG} alt="Instagram logo" className={styles.socialLogo} />
          </a>
        </Col>
        <Col sm={2} className={styles.socialContainer}>
          <a
            href="https://twitter.com/ggitems_"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img src={X} alt="Twitter X logo" className={styles.socialLogo} />
          </a>
        </Col>
        <Col sm={2} className={styles.socialContainer}>
          <a
            href="https://discord.gg/ZjqJEVTS6X"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img src={D} alt="Discord logo" className={styles.socialLogo} />
          </a>
        </Col>
      </Row>
      <Row className={styles.socialContainer}>
        <Col sm={12} className={styles.socialContainer}>
          <a
            href="https://www.trustindex.io/reviews/ggitems.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img src={TI} alt="TrustIndex logo" className={styles.trustindex} />
          </a>
        </Col>{" "}
      </Row>
    </Container>
  );
};

export default Socials;
