import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./FaqPage.module.css";
import Questions from "../Components/FAQ/Questions";
import faq from "../Components/FAQ/faq.json";
import React from "react";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const FaqPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          FAQ | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp; Instant
          Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body>
          {faq.map((q) => {
            return (
              <Questions className={styles.qCont} question={q}></Questions>
            );
          })}
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default FaqPage;
