import React, { useState } from "react";
import styles from "./UserForm.module.css";
import { Col, Row } from "react-bootstrap";
import settings from "../../settings.json";
import Alert from "../Alert/Alert";
const PasswordRecovery = () => {
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState({ visible: false, message: "" });

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const serverAddress = settings.serverAddress;
    const response = await fetch(`${serverAddress}/api/user/passwordRecover`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    const data = await response.json();
    const { message } = data;

    setAlert({
      visible: true,
      message: message,
    });

    setTimeout(() => {
      setAlert({
        visible: false,
        message: "",
      });
    }, 3000);
  };
  return (
    <React.Fragment>
      {alert.visible ? <Alert message={alert.message} /> : null}
      <div>
        <Row>
          <Col xs={window.innerWidth > 768 ? 4 : 0}></Col>
          <Col xs={window.innerWidth > 768 ? 4 : 12}>
            <div className={styles.formContainer}>
              <h1>Recover Password</h1>
              <form onSubmit={submitHandler} className={styles.form}>
                <React.Fragment>
                  <div className={styles.container}>
                    <label>email address</label>
                    <br />
                    <input
                      type="email"
                      className={styles.inp}
                      value={email}
                      onChange={emailHandler}
                    ></input>
                  </div>
                </React.Fragment>
                <button type="submit" className={styles.btn}>
                  Reset Password
                </button>
              </form>
            </div>
          </Col>
          <Col xs={window.innerWidth > 768 ? 4 : 0}></Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default PasswordRecovery;
