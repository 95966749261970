import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./HomePage.module.css";
import Description from "../Components/Body/Description";
import Statistics from "../Components/SiteStatistics/Statistics";
import { Helmet } from "react-helmet";
import React from "react";
import Slider from "../Components/Header/Slider";
import brandLogo from "../graphics/ggitems_logo.png";
import AdminPopup from "../Components/AdminPopup/AdminPopup";
const HomePage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          GGItems | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>
        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <AdminPopup />
        <Body selectBar={true} searchBar={true} cart={true}>
          <Description />
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default HomePage;
