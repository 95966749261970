import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AuthContext from "../context/auth-context";
import TypeButton from "../Products/TypeButton";
import styles from "./PopUp.module.css";
import SingleWishlist from "./SingleWishlist";
const PopUp = (props) => {
  const authCtx = useContext(AuthContext);
  const item = props.item;
  const price = props.price;
  const [name, setName] = useState("");

  const inputHandler = (e) => {
    setName(e.target.value);
  };

  const createWishlistHandler = async () => {
    if (name.length > 0) {
      authCtx.createWishlist(name, item, price);
      setName("");
      props.onClose(true);
    }
  };

  return (
    <div className={styles.container}>
      <Container>
        <Row>
          <div className={styles.newWishlistCont}>
            <Col>
              <h1>add new wishlist </h1>
            </Col>
            {/* <Row> */}
            <Col>
              <input
                placeholder="write your wishlist name, make sure it is unique"
                value={name}
                onChange={inputHandler}
                className={styles.inp}
              />
            </Col>

            <Col onClick={createWishlistHandler}>
              <TypeButton
                text={"Create new wishlist and add item."}
              ></TypeButton>
            </Col>
            {/* </Row> */}
          </div>
          <div className={styles.wishlistsCont}>
            <h2>Existing Wishlists:</h2>
            {authCtx.user.wishlists.map((wishlist) => {
              return (
                <React.Fragment key={wishlist.id}>
                  <Row>
                    <Col>
                      <SingleWishlist
                        wishlist={wishlist}
                        item={item}
                        price={price}
                        onClose={props.onClose}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              );
            })}
          </div>
          <Col onClick={props.onClose}>
            <TypeButton text={"Close"}></TypeButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default PopUp;
