import { useContext, useEffect, useState } from "react";
import styles from "./AttributeSelect.module.css";
import AuthContext from "../context/auth-context";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import cursor from "../../graphics/Cursor/cursor_hover.png";
import "./MUISelect.css";

const AttributeSelect = (props) => {
  const userCtx = useContext(AuthContext);
  const [currentValue, setCurrentValue] = useState(
    props.variables[0].itemStats
  );
  const [variablesLength, setVariablesLength] = useState(
    props.variables.length
  );

  useEffect(() => {
    setCurrentValue(props.variables[0].itemStats);
    setVariablesLength(props.variables.length);
  }, [props.variables]);

  const priceHandler = (e) => {
    const attribute = e.target.value;
    for (const variable of props.variables) {
      if (attribute === variable.itemStats) {
        props.stockHandler(variable.inStock);
        props.priceHandler(variable.itemPriceUSD);
        props.statsHandler(attribute);
        setCurrentValue(attribute);
        props.skuHandler(variable.GGSKU);
      }
    }
  };
  return (
    <FormControl fullWidth>
      <InputLabel style={{ color: "#ba8" }}>Item Stats</InputLabel>
      <Select
        value={currentValue}
        label="Item Statsas"
        onChange={priceHandler}
        className={`${styles.select}`}
      >
        {props.variables.map((variable, i) => {
          return (
            <MenuItem
              value={variable.itemStats}
              key={variable.id + `${~~(Math.random() * 10000)}`}
              className={styles.option}
              style={{
                cursor: `url(${cursor}),auto`,
                textWrap: "balance",
                backgroundColor: "#333",
                color: "#ba8",
                marginTop: i === 0 ? "-8px" : 0,
                marginBottom: i === variablesLength - 1 ? "-8px" : 0,
                // color: '#a58546'
              }}
            >
              {variable.itemStats}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default AttributeSelect;
