import CartIcon from "./CartIcon";
import styles from "./Cart.module.css";
import React, { useContext } from "react";
import CartContext from "../context/cart-context";
import { Row, Col } from "react-bootstrap";
import CartProduct from "./CartProduct";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth-context";
const Cart = () => {
  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);

  return (
    <React.Fragment>
      {cartCtx.isVisible ? (
        <div className={styles.container}>
          <React.Fragment>
            <Row>
              <div className={styles.cartHeader}>
                <h1>Your Cart</h1>
              </div>
            </Row>
            <Row>
              <div className={styles.cart}>
                {cartCtx.items.map((item) => {
                  return <CartProduct item={item} key={item.product.id} />;
                })}
              </div>
            </Row>
            <Row className={styles.footer}>
              <Row className={styles.total}>
                Total Items:{" "}
                {cartCtx.totalItems ??
                  cartCtx.items.reduce((acc, item) => acc + item.quantity, 0)}
              </Row>
              <Row className={styles.total}>
                subTotal:{" "}
                {(
                  +cartCtx.totalAmount ??
                  cartCtx.items.reduce(
                    (acc, item) => acc + item.product.price * item.quantity,
                    0
                  )
                ).toFixed(2)}
                $
              </Row>
              {/* <Row className={styles.total}>Low order fee: </Row>
              <Row className={styles.total}>Total: </Row> */}

              <Row>
                <Col xs={2} style={{ padding: 0 }}>
                  <button onClick={cartCtx.toggle} className={styles.checkout}>
                    X
                  </button>
                </Col>
                <Col xs={10} style={{ padding: "0px 0px 0px 2px" }}>
                  <Link to={"/cart"} onClick={cartCtx.toggle}>
                    <button className={styles.checkout}>view cart</button>
                  </Link>
                </Col>
              </Row>
            </Row>
          </React.Fragment>
        </div>
      ) : (
        <CartIcon />
      )}
    </React.Fragment>
  );
};

export default Cart;
