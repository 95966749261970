import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./BuildPage.module.css";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import bowazon from "../WikiContent/amazon/bowazon.json";
import javazon from "../WikiContent/amazon/javazon.json";
import lightTrapper from "../WikiContent/assasin/light-trapper";
import wwAssasin from "../WikiContent/assasin/whirlwind.json";
import ww from "../WikiContent/barbarian/whrilwind.json";
import frenzy from "../WikiContent/barbarian/frenzy.json";
import berserker from "../WikiContent/barbarian/frenzy.json";
import beastmaster from "../WikiContent/druid/beastmaster.json";
import fury from "../WikiContent/druid/furyWerewolf.json";
import wind from "../WikiContent/druid/wind.json";
import bonemancer from "../WikiContent/necromancer/bonemancer.json";
import summonmancer from "../WikiContent/necromancer/summonmancer.json";
import auradin from "../WikiContent/paladin/auradin.json";
import foh from "../WikiContent/paladin/foh.json";
import hammerdin from "../WikiContent/paladin/hammerdin.json";
import smiter from "../WikiContent/paladin/smitter.json";
import blizzard from "../WikiContent/sorceress/blizzard.json";
import light from "../WikiContent/sorceress/lightning.json";
import fire from "../WikiContent/sorceress/fire.json";
import frozenOrb from "../WikiContent/sorceress/frozenOrb.json";
import coldFire from "../WikiContent/sorceress/cold-fire.json";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const BuildPage = () => {
  const params = useParams();
  const { build } = params;
  const [curBuild, setCurBuild] = useState({});
  useEffect(() => {
    switch (build) {
      case "bowazon":
        setCurBuild(bowazon);
        break;
      case "javazon":
        setCurBuild(javazon);
        break;
      case "light-trapper":
        setCurBuild(lightTrapper);
        break;
      case "whirlwind-assasin":
        setCurBuild(wwAssasin);
        break;
      case "whirlwind":
        setCurBuild(ww);
        break;
      case "frenzy":
        setCurBuild(frenzy);
        break;
      case "berserker":
        setCurBuild(berserker);
        break;
      case "beastmaster":
        setCurBuild(beastmaster);
        break;
      case "fury":
        setCurBuild(fury);
        break;
      case "wind":
        setCurBuild(wind);
        break;
      case "bonemancer":
        setCurBuild(bonemancer);
        break;
      case "summonmancer":
        setCurBuild(summonmancer);
        break;
      case "auradin":
        setCurBuild(auradin);
        break;
      case "foh":
        setCurBuild(foh);
        break;
      case "hammerdin":
        setCurBuild(hammerdin);
        break;
      case "smiter":
        setCurBuild(smiter);
        break;
      case "blizzard":
        setCurBuild(blizzard);
        break;
      case "fire":
        setCurBuild(fire);
        break;
      case "frozen-orb":
        setCurBuild(frozenOrb);
        break;
      case "cold-fire":
        setCurBuild(coldFire);
        break;
      case "light":
        setCurBuild(light);
        break;
      default:
        setCurBuild({});
        break;
    }
  }, [build]);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Builds | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={brandLogo}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>
      <section className={styles.background}>
        <Header></Header>
        <Body cart={true}>{curBuild}</Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default BuildPage;
