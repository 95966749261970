import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./DetailsPage.module.css";
import DetailedProduct from "../Components/DetailedProduct/DetailedProduct";
const DetailsPage = () => {
  return (
    <section className={styles.background}>
      <Header></Header>
      <Body selectBar={true} searchBar={true} cart={true}>
        <DetailedProduct />
      </Body>
      <Footer></Footer>
    </section>
  );
};

export default DetailsPage;
