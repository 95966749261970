import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../Components/context/auth-context";
import styles from "./WishlistsPage.module.css";
import { Col, Container, Row } from "react-bootstrap";

import ProductButton from "../Components/Products/ProductButton";
import CartContext from "../Components/context/cart-context";
import WishlistItem from "../Components/Wishlist/WishlistItem";
import Alert from "../Components/Alert/Alert";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const WishlistsPage = () => {
  const [alert, setAlert] = useState({ visible: false, message: "" });
  const [wishlist, setWishlist] = useState();

  const params = useParams();
  const { title } = params;
  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(CartContext);

  useEffect(() => {
    if (authCtx.user.wishlists === undefined) navigate("/user/dashboard");
    const selectedWishlistId = authCtx.user.wishlists.findIndex(
      (w) => w.title === title
      //   if (w.title === title) return w;
    );
    setWishlist(authCtx.user.wishlists[selectedWishlistId]);
  }, [title, authCtx, navigate]);

  const addItems = () => {
    if (cartCtx.realm !== "Select Game") {
      cartCtx.loadWishlist(wishlist.items);
      setAlert({
        visible: true,
        message: "Products successfully loaded to your cart!",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    } else {
      setAlert({
        visible: true,
        message: "Select Realm before adding products to your cart.",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };

  const replaceItems = () => {
    if (cartCtx.realm !== "Select Game") {
      cartCtx.replaceWishlist(wishlist.items);
      setAlert({
        visible: true,
        message: "Products in your cart have been successfully replaced!",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    } else {
      setAlert({
        visible: true,
        message: "Select Realm before adding products to your cart.",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };

  const removeWishlist = () => {
    authCtx.removeWishlsit(wishlist.title);
    navigate("/user/wishlist");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Wishlists | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap &amp;
          Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>
        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>

      <section className={styles.background}>
        <Header></Header>
        <Body cart={true}>
          <Container>
            {alert.visible ? <Alert message={alert.message} /> : null}
            <div className={styles.buttonContainer}>
              <Row>
                <Col>
                  <ProductButton
                    text={"Load all items to cart"}
                    onClick={addItems}
                  />
                </Col>
                <Col>
                  <ProductButton
                    text={"Replace all items in cart"}
                    onClick={replaceItems}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                {wishlist !== undefined ? (
                  <Row>
                    {wishlist.items.length > 0 ? (
                      <React.Fragment>
                        {wishlist.items.map((item) => {
                          return (
                            <Row
                              className={styles.productContainer}
                              key={item.id}
                            >
                              <WishlistItem
                                item={item}
                                title={wishlist.title}
                              />
                            </Row>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      <h1 className={styles.empty}>
                        Looks like your wishlist is empty
                      </h1>
                    )}
                  </Row>
                ) : null}
              </Col>
            </Row>
            <div className={styles.buttonContainer}>
              <ProductButton
                text={"delete wishlist"}
                onClick={removeWishlist}
              />
            </div>
          </Container>
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default WishlistsPage;
