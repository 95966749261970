import { useContext } from "react";
import styles from "./GiftButton.module.css";
import CartContext from "../context/cart-context";
const GiftButton = (props) => {
  const breakpointUSD = Number(props.breakpointUSD);
  const cartCtx = useContext(CartContext);

  const clickHandler = () => {
    props.onClick(props.gift);
  };

  return (
    <button
      className={styles.btn}
      onClick={clickHandler}
      style={
        breakpointUSD > cartCtx.totalAmount
          ? null
          : { backgroundColor: "#a58546", color: "#000", borderColor: "#000" }
      }
      dangerouslySetInnerHTML={{ __html: props.text }}
    ></button>
  );
};

export default GiftButton;
