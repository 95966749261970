import { useEffect, useReducer } from "react";
import AuthContext from "./auth-context";
import settings from "../../settings.json";

const defaultAuthState = {
  isLoggedIn: false,
  user: {},
  cookiesEnabled: false,
};

const authReducer = (state, action) => {
  if (action.type === "LOGIN") {
    return {
      isLoggedIn: true,
      user: action.user,
      cookiesEnabled: state.cookiesEnabled,
    };
  } else if (action.type === "REMOVE") {
    return {
      isLoggedIn: false,
      user: {},
      cookiesEnabled: state.cookiesEnabled,
    };
  } else if (action.type === "SET") {
    return {
      isLoggedIn: true,
      user: action.user,
      cookiesEnabled: state.cookiesEnabled,
    };
  } else if (action.type === "ENABLE-COOKIE") {
    return {
      isLoggedIn: state.isLoggedIn,
      user: state.user,
      cookiesEnabled: action.cookie,
    };
  } else if (action.type === "CREATE") {
    const exist = state.user.wishlists.some(
      (wishlist) => wishlist.title === action.name
    );
    if (!exist) {
      const wishlistsIds = state.user.wishlists.map((wishlist) => {
        return wishlist.id;
      });
      const highestId =
        wishlistsIds.length > 0 ? Math.max(...wishlistsIds) + 1 : 1;
      let stats;
      stats = action.item.variables.map((v) => {
        if (v.itemPriceUSD === action.price) return v.itemStats;
      });
      const newWishlist = {
        id: highestId,
        title: action.name,
        items: [
          {
            product: {
              id: action.item.id,
              collection: action.item.collection,
              image: action.item.image,
              price: action.price,
              stats: stats[0],
              title: action.item.title,
            },
            quantity: 1,
          },
        ],
      };
      let user = state.user;
      const wishlists = [...user.wishlists, newWishlist];
      user.wishlists = wishlists;
      updateWishlists(user);
      localStorage.setItem("user", JSON.stringify(user));
      return {
        isLoggedIn: state.isLoggedIn,
        user: user,
        cookiesEnabled: state.cookiesEnabled,
      };
    } else
      return {
        isLoggedIn: state.isLoggedIn,
        user: state.user,
        cookiesEnabled: state.cookiesEnabled,
      };
  } else if (action.type === "ADD") {
    const stats = action.item.variables.map(
      (v) => v.itemPriceUSD === action.price
    );
    const selectedWishlistId = state.user.wishlists.findIndex(
      (u) => u.id === action.wishlist.id
    );

    // if not found
    if (selectedWishlistId === -1) {
      return {
        isLoggedIn: state.isLoggedIn,
        user: state.user,
        cookiesEnabled: state.cookiesEnabled,
      };
    }

    let user = state.user;
    let wishlists = user.wishlists;

    if (wishlists[selectedWishlistId].items.length > 0) {
      if (
        wishlists[selectedWishlistId].items[
          wishlists[selectedWishlistId].items.length - 1
        ].product.id !== action.item.id
      )
        wishlists[selectedWishlistId].items.push({
          product: {
            id: action.item.id,
            collection: action.item.collection,
            image: action.item.image,
            price: action.price,
            stats: stats.itemStats,
            title: action.item.title,
          },
          quantity: 1,
        });
    } else if (wishlists[selectedWishlistId].items.length === 0) {
      wishlists[selectedWishlistId].items.push({
        product: {
          id: action.item.id,
          image: action.item.image,
          collection: action.item.collection,
          price: action.price,
          stats: stats.itemStats,
          title: action.item.title,
        },
        quantity: 1,
      });
    }

    user.wishlists = wishlists;
    updateWishlists(user);
    localStorage.setItem("user", JSON.stringify(user));
    return {
      isLoggedIn: state.isLoggedIn,
      user: user,
      cookiesEnabled: state.cookiesEnabled,
    };
  } else if (action.type === "DELETE") {
    let newWishlists = state.user.wishlists;
    newWishlists = newWishlists.filter(
      (wishlist) => wishlist.title !== action.title
    );
    let user = state.user;
    user.wishlists = newWishlists;
    updateWishlists(user);
    localStorage.setItem("user", JSON.stringify(user));
    return {
      isLoggedIn: state.isLoggedIn,
      user: user,
      cookiesEnabled: state.cookiesEnabled,
    };
  } else if (action.type === "DELETE-ITEM") {
    const selectedWishlistId = state.user.wishlists.findIndex(
      (wishlist) => wishlist.title === action.title
    );

    const selectedWishlist = state.user.wishlists[selectedWishlistId];

    let updatedWishlist = selectedWishlist;
    updatedWishlist.items = updatedWishlist.items.filter(
      (item) => item.id !== action.id
    );

    let newWishlists = state.user.wishlists;
    newWishlists[selectedWishlistId] = updatedWishlist;
    let user = state.user;
    user.wishlists = newWishlists;
    updateWishlists(user);
    localStorage.setItem("user", JSON.stringify(user));
    return {
      isLoggedIn: state.isLoggedIn,
      user: user,
      cookiesEnabled: state.cookiesEnabled,
    };
  } else if (action.type === "INCREASE") {
    const selectedWishlistId = state.user.wishlists.findIndex(
      (wishlist) => wishlist.title === action.title
    );

    const selectedWishlist = state.user.wishlists[selectedWishlistId];

    const index = selectedWishlist.items.findIndex(
      (item) => item.product.id === action.id
    );

    const prevItem = selectedWishlist.items[index];
    const newItem = { ...prevItem, quantity: (prevItem.quantity += 0.5) };
    // to prevent adding 2 that's why 0.5
    let updatedWishlist = selectedWishlist;
    updatedWishlist[index] = newItem;

    let newWishlists = state.user.wishlists;
    newWishlists[selectedWishlistId] = updatedWishlist;
    let user = state.user;
    user.wishlists = newWishlists;
    updateWishlists(user);
    localStorage.setItem("user", JSON.stringify(user));
    return {
      isLoggedIn: state.isLoggedIn,
      user: user,
      cookiesEnabled: state.cookiesEnabled,
    };
  } else if (action.type === "DECREASE") {
    const selectedWishlistId = state.user.wishlists.findIndex(
      (wishlist) => wishlist.title === action.title
    );

    const selectedWishlist = state.user.wishlists[selectedWishlistId];

    const index = selectedWishlist.items.findIndex(
      (item) => item.product.id === action.id
    );

    const prevItem = selectedWishlist.items[index];
    if (prevItem === undefined) {
      return {
        isLoggedIn: state.isLoggedIn,
        user: state.user,
        cookiesEnabled: state.cookiesEnabled,
      };
    }
    if (prevItem.quantity === 1) {
      let updatedWishlist = selectedWishlist.items.filter(
        (item) => item.product.id !== action.id
      );
      let newWishlists = state.user.wishlists;
      newWishlists[selectedWishlistId].items = updatedWishlist;
      let user = state.user;
      user.wishlists = newWishlists;
      updateWishlists(user);
      localStorage.setItem("user", JSON.stringify(user));
      return {
        isLoggedIn: state.isLoggedIn,
        user: user,
        cookiesEnabled: state.cookiesEnabled,
      };
    } else {
      const newItem = { ...prevItem, quantity: (prevItem.quantity -= 0.5) };
      // to prevent adding 2 that's why 0.5
      let updatedWishlist = selectedWishlist;
      updatedWishlist[index] = newItem;

      let newWishlists = state.user.wishlists;
      newWishlists[selectedWishlistId] = updatedWishlist;
      let user = state.user;
      user.wishlists = newWishlists;
      updateWishlists(user);
      localStorage.setItem("user", JSON.stringify(user));
      return {
        isLoggedIn: state.isLoggedIn,
        user: user,
        cookiesEnabled: state.cookiesEnabled,
      };
    }
  }
};

const updateWishlists = async (user) => {
  const serverAddress = settings.serverAddress;
  await fetch(`${serverAddress}/api/wishlist/new`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      user: user,
    }),
  });
};

const AuthProvider = (props) => {
  const [authState, dispatchAuthAction] = useReducer(
    authReducer,
    defaultAuthState
  );

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null) dispatchAuthAction({ type: "SET", user: user });
    const cookie = JSON.parse(localStorage.getItem("cookie"));
    if (cookie !== null)
      dispatchAuthAction({ type: "ENABLE-COOKIE", cookie: true });
  }, []);

  const setUserHandler = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    dispatchAuthAction({ type: "LOGIN", user: user });
  };

  const removeUserHandler = () => {
    localStorage.removeItem("user");
    dispatchAuthAction({ type: "REMOVE" });
  };

  const createWishlistHandler = (name, item, price) => {
    dispatchAuthAction({
      type: "CREATE",
      name: name,
      item: item,
      price: price,
    });
  };

  const addItemHandler = (wishlist, item, price) => {
    dispatchAuthAction({
      type: "ADD",
      wishlist: wishlist,
      item: item,
      price: price,
    });
  };

  const removeWishlistHandler = (title) => {
    dispatchAuthAction({
      type: "DELETE",
      title: title,
    });
  };

  const removeItemWishlistHandler = (title, id) => {
    dispatchAuthAction({
      type: "DELETE-ITEM",
      title: title,
      id: id,
    });
  };

  const increaseWishlistHandler = (title, id) => {
    dispatchAuthAction({
      type: "INCREASE",
      title: title,
      id: id,
    });
  };

  const decreaseWishlsitHandler = (title, id) => {
    dispatchAuthAction({
      type: "DECREASE",
      title: title,
      id: id,
    });
  };

  const enableCookiesHandler = () => {
    localStorage.setItem("cookie", JSON.stringify(true));
    dispatchAuthAction({ type: "ENABLE-COOKIE", cookie: true });
  };

  const authContext = {
    isLoggedIn: authState.isLoggedIn,
    user: authState.user,
    cookiesEnabled: authState.cookiesEnabled,
    setUser: setUserHandler,
    removeUser: removeUserHandler,
    createWishlist: createWishlistHandler,
    addItem: addItemHandler,
    removeWishlsit: removeWishlistHandler,
    removeItemWishlist: removeItemWishlistHandler,
    increaseWishlist: increaseWishlistHandler,
    decreaseWishlist: decreaseWishlsitHandler,
    enableCookies: enableCookiesHandler,
  };

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
