import { Container, Row } from "react-bootstrap";
import styles from "./Statistics.module.css";
import React, { useEffect, useState } from "react";
import settings from "../../settings.json";
const Statistics = () => {
  const [src, setSrc] = useState("");
  useEffect(() => {
    (async function () {
      console.log("querying");
      const src = await querySrc();
      const script = document.createElement("script");
      script.defer = true;
      script.async = true;
      script.src = src;
      document.querySelector("#statistics-container").appendChild(script);
      return () => {
        document.querySelector("#statistics-container").removeChild(script);
      };
    })();
  }, []);

  const querySrc = async () => {
    const serverAddress = settings.serverAddress;
    const response = await fetch(`${serverAddress}/api/trustpilot/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    setSrc(data.src);
    return data.src;
  };
  return (
    <React.Fragment>
      {src ? (
        <Container className={styles.statistics} fluid>
          <Container>
            <Row className={styles.container}>
              <Row id="statistics-container" className={styles.container}></Row>
            </Row>
          </Container>
        </Container>
      ) : null}
    </React.Fragment>
  );
};

export default Statistics;
