import Header from "../Components/Header/Header";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import styles from "./ProductPage.module.css";
import React from "react";
import PasswordRecovery from "../Components/userForm/PasswordRecovery";
import { Helmet } from "react-helmet";
import brandLogo from "../graphics/ggitems_logo.png";

const RecoveryPassword = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Recover Password | Buy D2R Items, Diablo 2 Resurrected - Legit, Cheap
          &amp; Instant Delivery
        </title>
        <meta property="og:image" content={`${brandLogo}`}></meta>

        <meta
          property="og:description"
          content="Buy D2 Resurrected Items. Cheapest Store To Buy Diablo 2 Resurrected Items. Best Prices For High Runes, Uniques, Runewords, Gear Packs."
        ></meta>
      </Helmet>

      <section className={styles.background}>
        <Header></Header>
        <Body selectBar={false} cart={true}>
          <PasswordRecovery />
        </Body>
        <Footer></Footer>
      </section>
    </React.Fragment>
  );
};

export default RecoveryPassword;
