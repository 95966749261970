import { useReducer } from "react";
import ScrollContext from "./scroll-context";
const defaultScrollState = {
  ref: null,
  component: null,
};

const scrollReducer = (state, action) => {
  if (action.type === "SET-REF") {
    return {
      ref: action.ref,
      component: state.component,
    };
  } else if (action.type === "SET-COMPONENT") {
    return {
      ref: state.ref,
      component: action.component,
    };
  } else if (action.type === "SCROLL") {
    // const offset = state.component.offsetTop;
    // const navbarHeight = 275;
    // window.scrollTo({ top: offset - navbarHeight, behavior: "smooth" });
    window.scrollTo(0, 0);
    return {
      ref: state.ref,
      component: state.component,
    };
  } else
    return {
      ref: state.ref,
      component: state.component,
    };
};

const SeoProvider = (props) => {
  const [scrollState, dispatchAuthAction] = useReducer(
    scrollReducer,
    defaultScrollState
  );

  const setRefHandler = (ref) => {
    dispatchAuthAction({
      type: "SET-REF",
    });
  };
  const setComponentHandler = (target) => {
    dispatchAuthAction({
      type: "SET-COMPONENT",
      component: target,
    });
  };
  const scrollHandler = () => {
    dispatchAuthAction({
      type: "SCROLL",
    });
  };

  const scrollContext = {
    ref: scrollState.ref,
    component: scrollState.component,
    setRef: setRefHandler,
    setComponent: setComponentHandler,
    scroll: scrollHandler,
  };

  return (
    <ScrollContext.Provider value={scrollContext}>
      {props.children}
    </ScrollContext.Provider>
  );
};

export default SeoProvider;
