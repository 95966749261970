import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CartContext from "../context/cart-context";
import AuthContext from "../context/auth-context";
import styles from "./Payment.module.css";
import settings from "../../settings.json";
import NewsletterCheckbox from "../NewsletterCheckbox/NewsletterCheckbox";
import { useLocation, useNavigate } from "react-router-dom";
import defaultCursor from "../../graphics/Cursor/cursor.png";
import Alert from "../Alert/Alert";
const europeanCountries = [
  "Germany",
  "United Kingdom",
  "France",
  "Italy",
  "Spain",
  "Ukraine",
  "Poland",
  "Romania",
  "Netherlands",
  "Belgium",
  "Czech Republic",
  "Czechia",
  "Greece",
  "Portugal",
  "Sweden",
  "Hungary",
  "Belarus",
  "Austria",
  "Serbia",
  "Switzerland",
  "Bulgaria",
  "Denmark",
  "Finland",
  "Slovakia",
  "Norway",
  "Ireland",
  "Croatia",
  "Moldova",
  "Bosnia and Herzegovina",
  "Albania",
  "Lithuania",
  "North Macedonia",
  "Slovenia",
  "Latvia",
  "Estonia",
  "Montenegro",
  "Luxembourg",
  "Malta",
  "Iceland",
  "Andorra",
  "Monaco",
  "Liechtenstein",
  "San Marino",
  "Holy See",
];

const Payment = () => {
  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [coupon, setCoupon] = useState(location.state.coupon);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "" });
  const [firstName, setFirstName] = useState(authCtx.user.firstName);
  const [lastName, setLastName] = useState(authCtx.user.lastName);
  const [battleNetTag, setBattleNetTag] = useState(authCtx.user.battlenet);
  const [discord, setDiscord] = useState(authCtx.user.discord);
  const [note, setNote] = useState("");
  const [total, setTotal] = useState(cartCtx.totalAmount);

  const [isNewsletterSubscribed, setIsNewsletterSubscribed] = useState(true);

  useEffect(() => {
    if (cartCtx.items.length === 0) {
      navigate("/");
    } else {
      const reducedTotal = (
        +cartCtx.totalAmount -
        +cartCtx.bonusDiscount -
        +coupon.amountOff +
        +cartCtx.orderFee
      ).toFixed(2);
      setTotal(reducedTotal);
    }
  }, []);

  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameHandler = (e) => {
    setLastName(e.target.value);
  };

  const battleNetTagHandler = (e) => {
    setBattleNetTag(e.target.value);
  };

  const discordHandler = (e) => {
    setDiscord(e.target.value);
  };
  const noteHandler = (e) => {
    setNote(e.target.value);
  };
  const newsletterHandler = () => {
    setIsNewsletterSubscribed(!isNewsletterSubscribed);
  };

  // useEffect(() => {
  //   const element = document.querySelector(`.${styles.container}`);
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);

  const getUserGeolocationDetails = async () => {
    const response = await fetch(`https://geolocation-db.com/json/`, {
      method: "GET",
    });
    const { IPv4, country_name } = await response.json();
    const isEuropean = europeanCountries.some((ec) => ec === country_name);

    return { ip: IPv4, isEuropean: isEuropean, country: country_name };
  };

  const placeOrderHandler = async () => {
    setAlert({
      visible: true,
      message: "Creating checkout session, please wait.",
    });
    const orderItems = cartCtx.items.map((item) => {
      return {
        id: item.product.id,
        collection: item.product.collection,
        title: item.product.title,
        stats: item.product.stats,
        details: item.product.details,
        realm: item.realm,
        quantity: item.quantity,
      };
    });

    const { ip, isEuropean, country } = await getUserGeolocationDetails();
    const customerData = {
      userId: authCtx.user.userId,
      email: authCtx.user.email,
      ip: ip,
      isEuropean: isEuropean,
      country: country,
    };

    const serverAddress = settings.serverAddress;
    const response = await fetch(
      `${serverAddress}/api/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          items: orderItems,
          orderNote: note,
          customerData: customerData,
          coupon: coupon.coupon,
        }),
      }
    );
    if (!response.ok) {
      // error
      const errorMessage = await response.json();

      setAlert({
        visible: true,
        message:
          "There were errors while creating your checkout session, contact support.",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 5000);
    }
    const { url } = await response.json();
    setAlert({
      visible: false,
      message: "",
    });
    window.open(url, "_blank");
  };
  return (
    <Container>
      {alert.visible ? <Alert message={alert.message} /> : null}

      {isLoading ? (
        <Row>
          <Col>
            <h1>Loading...</h1>
          </Col>
        </Row>
      ) : (
        <div className={styles.container}>
          <Row>
            <h1
              className={styles.header}
              style={{ cursor: `url(${defaultCursor}), auto !important` }}
            >
              Account Details:
            </h1>
            <label>email address:</label>
            <br />
            <input
              type="email"
              value={authCtx.user.email}
              disabled
              readOnly
              className={styles.inp}
            />
            <label>first name:</label> <br />
            <input
              type="text"
              value={firstName}
              onChange={firstNameHandler}
              className={styles.inp}
            />
            <label>last name:</label> <br />
            <input
              type="text"
              value={lastName}
              onChange={lastNameHandler}
              className={styles.inp}
            />
            <label>battle net tag:</label> <br />
            <input
              type="text"
              value={battleNetTag}
              onChange={battleNetTagHandler}
              className={styles.inp}
              placeholder={"Enter BattleTag ex: #00000"}
            />
            <label>discord:</label> <br />
            <input
              type="text"
              value={discord}
              onChange={discordHandler}
              className={styles.inp}
              placeholder={"GGItems#0001"}
            />
            <label>message:</label> <br />
            <textarea
              className={styles.textarea}
              placeholder={"order notes, e.g. special notes for delivery"}
              value={note}
              onChange={noteHandler}
            />{" "}
            <br />
            <div>
              <NewsletterCheckbox
                isNewsletterSubscribed={isNewsletterSubscribed}
                newsletterHandler={newsletterHandler}
              />{" "}
            </div>
          </Row>
          <Row>
            <h1
              className={styles.header}
              style={{ cursor: `url(${defaultCursor}), auto !important` }}
            >
              Your order:
            </h1>
            <div className={styles.tableCont}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr
                    className={`${styles.tableRow} ${styles.bottomSeparator}`}
                  >
                    <td className={styles.tableCell}>
                      <span className={styles.header}>Product</span>
                    </td>
                    <td className={styles.tableCell}>
                      <span className={styles.header}>Stats</span>
                    </td>
                    <td className={styles.tableCell}>Quantity</td>
                    <td className={styles.tableCell}>
                      <span className={styles.header}>Realm</span>
                    </td>

                    <td className={styles.tableCell}>Price</td>
                  </tr>
                  {cartCtx.items.map((item) => {
                    return (
                      <tr className={styles.tableRow} key={item.product.id}>
                        <td className={styles.tableCell}>
                          <span className={styles.header}>
                            {item.product.title}{" "}
                          </span>
                        </td>
                        <td className={styles.tableCell}>
                          <span className={styles.header}>
                            {item.product.stats}
                          </span>
                        </td>
                        <td className={styles.tableCell}>{item.quantity}</td>
                        <td className={styles.tableCell}>
                          <span className={styles.header}>{item.realm}</span>
                        </td>

                        <td className={styles.tableCell}>
                          {Number(+item.product.price * +item.quantity).toFixed(
                            2
                          )}
                          $
                        </td>
                      </tr>
                    );
                  })}
                  <tr className={`${styles.tableRow} ${styles.topSeparator}`}>
                    <td className={styles.tableCell} colSpan={2}>
                      <span className={styles.header}>Total Items:</span>
                    </td>
                    <td className={styles.tableCell}>{cartCtx.totalItems}</td>
                    <td className={styles.tableCell} colSpan={2}></td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.tableCell} colSpan={4}>
                      <span className={styles.header}>Sub total:</span>
                    </td>
                    <td className={styles.tableCell}>{cartCtx.totalAmount}$</td>
                  </tr>
                  <tr className={`${styles.tableRow} ${styles.topSeparator}`}>
                    <td className={styles.tableCell} colSpan={4}>
                      <span className={styles.header}>VAT:</span>
                    </td>
                    <td className={styles.tableCell}>{cartCtx.VAT}$</td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.tableCell} colSpan={4}>
                      <span className={styles.header}>Low order fee:</span>
                    </td>
                    <td className={styles.tableCell}>
                      {+cartCtx.orderFee > 0
                        ? `+${cartCtx.orderFee.toFixed(2)}$`
                        : "-"}
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.tableCell} colSpan={4}>
                      <span className={styles.header}>discount:</span>
                    </td>
                    <td className={styles.tableCell}>
                      -{+coupon.amountOff > 0 ? `${coupon.amountOff}$` : null}
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.tableCell} colSpan={4}>
                      <span className={styles.header}>bonus:</span>
                    </td>
                    <td className={styles.tableCell}>
                      {" "}
                      -
                      {+cartCtx.bonusDiscount > 0
                        ? `${cartCtx.bonusDiscount}$`
                        : null}
                    </td>
                  </tr>
                  <tr className={`${styles.tableRow} ${styles.topSeparator}`}>
                    <td className={styles.tableCell} colSpan={4}>
                      <span className={styles.header}>Total:</span>
                    </td>
                    <td className={styles.tableCell}>{(+total).toFixed(2)}$</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>

          <button className={styles.btn} onClick={placeOrderHandler}>
            Checkout
          </button>
        </div>
      )}
    </Container>
  );
};

export default Payment;
