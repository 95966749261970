import React, { useContext, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Services.module.css";
import { useState } from "react";
import ServiceOptions from "./ServiceOptions";
import ProductButton from "../Products/ProductButton";
import rushIcon from "../../graphics/services/rush.png";
import questIcon from "../../graphics/services/quests.png";
import waypointsIcon from "../../graphics/services/waypoint.png";
import settings from "../../settings.json";
import CartContext from "../context/cart-context";
import Alert from "../Alert/Alert";
import AuthContext from "../context/auth-context";
const Services = () => {
  const [alert, setAlert] = useState({
    message: "",
    visible: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [password, setPassword] = useState("");
  const [characterName, setCharacterName] = useState("");
  const [isLeveling, setIsLeveling] = useState(true);
  const [startLevel, setStartLevel] = useState(80);
  const [endLevel, setEndLevel] = useState(80);
  const [waypoints, setWaypoints] = useState([]);
  const [quests, setQuests] = useState([]);
  const [rushes, setRushes] = useState([]);

  const [price, setPrice] = useState(0.0);
  const [pricing, setPricing] = useState({});

  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const realm = cartCtx.realm;
      let database;
      if (realm === "D2R Softcore Ladder") {
        database = "ProductsD2RLadder";
      } else if (realm === "D2R Softcore Non-Ladder") {
        database = "ProductsD2RNLadder";
      } else if (realm === "D2R Hardcore Ladder") {
        database = "ProductsD2RHCLadder";
      } else if (realm === "D2R Hardcore Non-Ladder") {
        database = "ProductsD2RHCNLadder";
      } else return;
      const serverAddress = settings.serverAddress;
      const response = await fetch(`${serverAddress}/api/products/services`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          database: database,
        }),
      });
      const data = await response.json();
      setPricing(data);
      setIsLoading(false);
    })();
  }, [cartCtx.realm]);

  useEffect(() => {
    let preprice = 0;

    if (isLeveling && startLevel < endLevel) {
      const levelPricing = pricing.level.pricing;
      let currentLevel = startLevel;
      for (const section of levelPricing) {
        if (currentLevel < section.endLevel)
          while (currentLevel <= section.endLevel && currentLevel <= endLevel) {
            currentLevel++;
            preprice += Number(section.eachPriceUSD);
          }
      }
      // Calculate the price of level range
    }
    if (waypoints.length > 0) {
      const waypointsPricing = pricing.waypoints.pricing;
      for (const waypoint of waypoints) {
        const selectedVariable = waypointsPricing.find(
          (wp) => wp.itemStats === waypoint
        );
        preprice += +selectedVariable.itemPriceUSD;
      }
    }
    if (quests.length > 0) {
      const questPricing = pricing.quests.pricing;
      for (const quest of quests) {
        const selectedVariable = questPricing.find(
          (wp) => wp.itemStats === quest
        );
        preprice += +selectedVariable.itemPriceUSD;
      }
    }
    if (rushes.length > 0) {
      const rushPricing = pricing.rush.pricing;
      for (const rush of rushes) {
        const selectedVariable = rushPricing.find(
          (wp) => wp.itemStats === rush
        );
        preprice += +selectedVariable.itemPriceUSD;
      }
    }
    setPrice(preprice);
  }, [isLeveling, startLevel, endLevel, waypoints, quests, rushes]);

  const authHandler = (e) => {
    setAuth((previousState) => !previousState);
  };
  const accountNameHandler = (e) => {
    setAccountName(e.target.value);
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  const characterNameHandler = (e) => {
    setCharacterName(e.target.value);
  };
  const startLevelHandler = (e) => {
    setStartLevel(Number(e.target.value));
  };
  const endLevelHandler = (e) => {
    setEndLevel(Number(e.target.value));
  };
  const levelHandler = (e) => {
    setIsLeveling((previousState) => (previousState = !previousState));
  };
  const waypointsHandler = (e) => {
    if (
      e.target.value === "All 3 Difficulties" &&
      !waypoints.includes("All 3 Difficulties")
    )
      setWaypoints([e.target.value]);
    else if (waypoints.includes(e.target.value)) {
      setWaypoints([...waypoints.filter((w) => w !== e.target.value)]);
    } else if (!waypoints.includes("All 3 Difficulties"))
      setWaypoints([...waypoints, e.target.value]);
  };
  const questsHandler = (e) => {
    if (
      e.target.value === "All 3 Difficulties" &&
      !quests.includes("All 3 Difficulties")
    )
      setQuests([e.target.value]);
    else if (quests.includes(e.target.value)) {
      setQuests([...quests.filter((w) => w !== e.target.value)]);
    } else if (!quests.includes("All 3 Difficulties"))
      setQuests([...quests, e.target.value]);
  };
  const rushHandler = (e) => {
    if (
      e.target.value === "All 3 Difficulties" &&
      !rushes.includes("All 3 Difficulties")
    )
      setRushes([e.target.value]);
    else if (rushes.includes(e.target.value)) {
      setRushes([...rushes.filter((w) => w !== e.target.value)]);
    } else if (!rushes.includes("All 3 Difficulties"))
      setRushes([...rushes, e.target.value]);
  };

  const addHandler = () => {
    if (auth && price > 0) {
      const product = {
        startLevel: startLevel,
        endLevel: endLevel,
        quests: quests,
        waypoints: waypoints,
        rushes: rushes,
        account: {
          name: accountName,
          password: password,
          character: characterName,
        },
      };

      const item = {
        id: Date.now(),
        collection: "Services",
        image: [],
        price: price,
        stats: "",
        details: product,
        title: "GGItems Service",
      };
      cartCtx.addItem(item);

      setAlert({
        visible: true,
        message: "Product added to cart!",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 3000);
    } else if (price === 0) {
      setAlert({
        visible: true,
        message: "Service price is equal 0",
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: "",
        });
      }, 5000);
    } else
      setAlert({
        visible: true,
        message: "You didn't remvoed the authentication on your account",
      });
    setTimeout(() => {
      setAlert({
        visible: false,
        message: "",
      });
    }, 5000);
  };

  return (
    <React.Fragment>
      {alert.visible ? <Alert message={alert.message} /> : null}
      {isLoading ? null : (
        <div className={styles.container}>
          <Row>
            <Col>
              <h1>GGItems Service's</h1>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <form style={{ textAlign: "left" }}>
                <label className={styles.label}>Account name</label> <br />
                <input
                  type="text"
                  value={accountName}
                  onChange={accountNameHandler}
                  placeholder="account name"
                  autoComplete="off"
                ></input>
                <br />
                <label className={styles.label}>password</label>
                <br />
                <input
                  type="password"
                  value={password}
                  onChange={passwordHandler}
                  placeholder="password"
                  autoComplete="off"
                ></input>{" "}
                <br />
                <label className={styles.label}>character name</label> <br />
                <input
                  type="text"
                  value={characterName}
                  onChange={characterNameHandler}
                  placeholder="character name"
                  autoComplete="off"
                ></input>
                <br />
                <br />
                <div style={{ textAlign: "left", display: "flex" }}>
                  <input
                    type="checkbox"
                    onChange={authHandler}
                    className={styles.checkbox}
                  ></input>
                  <span className={styles.label}>
                    {" "}
                    I have removed the app/email auth.
                  </span>
                </div>
              </form>
            </Col>
            <Col md={8}>
              <div style={{ textAlign: "left", display: "flex" }}>
                <input
                  type="checkbox"
                  onChange={levelHandler}
                  className={styles.checkbox}
                  checked={isLeveling}
                ></input>{" "}
                <label className={styles.label}> Enable level service</label>
                <br />
              </div>
              <label className={styles.label}>
                starting level:{" "}
                <span className={styles.preview}>{startLevel}</span>
              </label>
              <br />
              <input
                type="range"
                min="1"
                max="99"
                step="1"
                value={startLevel}
                onChange={startLevelHandler}
                className={styles.range}
                disabled={!isLeveling}
              ></input>
              <br />
              <label className={styles.label}>
                desired level:{" "}
                <span className={styles.preview}>{endLevel}</span>
              </label>
              <br />
              <input
                type="range"
                min="1"
                max="99"
                step="1"
                value={endLevel}
                onChange={endLevelHandler}
                className={styles.range}
                disabled={!isLeveling}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Waypoints</h2>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <img
                src={waypointsIcon}
                alt="waypoints"
                className={styles.img}
              ></img>
              <p className={styles.description}>
                ALL WAYPOINTS IN THE SELECTED DIFFICULTY(S) WILL BE GIVEN TO
                YOUR CHARACTER{" "}
              </p>
            </Col>
            <Col md={8}>
              {pricing.waypoints ? (
                <ServiceOptions
                  onChangeHandler={waypointsHandler}
                  checked={waypoints}
                  pricing={pricing.waypoints}
                />
              ) : null}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <h2>Rush</h2>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <img src={rushIcon} alt="rush" className={styles.img}></img>
              <p className={styles.description}>
                Rush/Taxi to ACT 5 will be completed on your character for the
                selected difficulty(s).
              </p>
            </Col>
            <Col md={8}>
              {" "}
              {pricing.rush ? (
                <ServiceOptions
                  onChangeHandler={rushHandler}
                  checked={rushes}
                  pricing={pricing.rush}
                />
              ) : null}
            </Col>
          </Row>
          <br />

          <Row>
            <Col>
              <h2>Quests</h2>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <img src={questIcon} alt="quests" className={styles.img}></img>
              <p className={styles.description}>
                The 5 most important quests will be completed on your character
                for the selected difficulty(s).
                <br />
                <br />
                Completing these quests results in a total gain of per
                Difficulty:
                <br />3 Skills Points
                <br />5 Stats Points
                <br />
                10 All Resists
                <br />1 Socket possibility
              </p>
            </Col>
            <Col md={8}>
              {pricing.quests ? (
                <ServiceOptions
                  onChangeHandler={questsHandler}
                  checked={quests}
                  pricing={pricing.quests}
                />
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>Service Price: {Number(price).toFixed(2)}$</h1>
            </Col>
          </Row>
          <Row>
            <div className={styles.buttonContainer}>
              <ProductButton
                xs={11}
                onClick={addHandler}
                text={"Add to cart"}
              ></ProductButton>
            </div>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};
export default Services;
