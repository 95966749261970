import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./Downloads.module.css";
const Downloads = () => {
  return (
    <div className={styles.container}>
      <Row className={styles.row}>
        <h1 className={styles.title}>Downloads</h1>
      </Row>
      <Row className={styles.row}>
        <Col>
          <p> Looks like there is nothing to download yet.</p>
        </Col>
      </Row>
    </div>
  );
};

export default Downloads;
