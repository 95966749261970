import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./WishlistItem.module.css";
import ProductImage from "../Products/ProductImage";
import BinIcon from "../Cart/BinIcon";
import AuthContext from "../context/auth-context";

const WishlistItem = (props) => {
  const item = props.item;
  const title = props.title;
  const authCtx = useContext(AuthContext);
  const windowWidth = window.innerWidth;

  const increaseHandler = () => {
    authCtx.increaseWishlist(title, item.product.id);
  };

  const decreaseHandler = () => {
    authCtx.decreaseWishlist(title, item.product.id);
  };

  return (
    <React.Fragment>
      {windowWidth > 768 ? (
        <Col xs={2}>
          <ProductImage
            title={item.product.title}
            image={item.product.image}
            // className={styles.picture}
          />{" "}
        </Col>
      ) : null}
      <Col xs={windowWidth > 768 ? 10 : 8}>
        <Row>
          <Col xs={10}>{item.product.title}</Col>
          <Col xs={2}>
            <span>{item.product.price}$</span>
          </Col>
        </Row>
        <Row>
          <Col className={styles.lightgold} xs={10}>
            {item.product.stats}
          </Col>
          <Col xs={2}>
            <BinIcon id={item.id} title={title} cart={false} />
          </Col>
        </Row>
        <Row>
          <Col xs={10}></Col>
          <Col xs={2}>
            <span className={styles.pointer} onClick={decreaseHandler}>
              -
            </span>
            <span>{item.quantity}</span>
            <span className={styles.pointer} onClick={increaseHandler}>
              +
            </span>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default WishlistItem;
