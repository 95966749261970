import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./Body.module.css";
import Selects from "../SelectMenu/Selects";
import React, { useContext, useEffect } from "react";
import SearchBar from "../SearchBar/SearchBar";
import Cart from "../Cart/Cart";
import BackToTop from "../BackToTop/BackToTop";
import Statistics from "../SiteStatistics/Statistics";
import Cookies from "../Cookies/Cookies";
import ScrollContext from "../context/scroll-context";

const Body = (props) => {
  const scrollCtx = useContext(ScrollContext);

  useEffect(() => {
    const target = document.querySelector(".target");
    scrollCtx.setComponent(target);
  }, []);

  return (
    <React.Fragment>
      <Container className={styles.container} fluid>
        <Row>
          {props.selectBar ? (
            <React.Fragment>
              <Col
                md={3}
                style={{ width: window.innerWidth > 768 ? "20%" : "" }}
              >
                <Selects></Selects>
              </Col>

              <Col md={9}>
                {props.searchBar ? <SearchBar /> : null}
                <div className="target">{props.children}</div>
              </Col>
            </React.Fragment>
          ) : (
            <div className="target">{props.children}</div>
          )}
        </Row>
        {!props.cart ? null : <Cart></Cart>}
        <BackToTop />
      </Container>

      <Statistics />

      <Cookies />
    </React.Fragment>
  );
};

export default Body;
